<template>
  <section id="main-container">
    <div class="container-fluid">
      <div class="container-fluid">
        <ul class="nav nav-tabs">
          <template v-for="tab in tabs">
            <li
              :key="tab.tabname"
              v-if="tab.visible"
              :class="'nav-item' + (tab.isactive ? ' active' : '')"
            >
              <a
                @click.prevent="loadActiveTab(tab.tabname)"
                data-toggle="tab"
                :href="'#' + tab.tabname"
                :class="'tabgap nav-link' + (tab.isactive ? ' active' : '')"
                ><span>{{ tab.title }}</span></a
              >
            </li>
          </template>
        </ul>
        <div class="tab-content">
          <div
            v-for="tab in tabs"
            :key="tab.tabname"
            :id="tab.tabname"
            :class="'tab-pane fade' + (tab.isactive ? ' in active show' : '')"
          >
            <awaiting-users
              v-if="tab.tabname === 'awaitingusers'"
              :getAwaitingusersList="tab.isactive"
            ></awaiting-users>
            <active-users
              v-else-if="tab.tabname === 'activeusers'"
              :getActiveusersList="tab.isactive"
            ></active-users>
            <advanced-search
              v-else-if="tab.tabname === 'advancedsearch'"
              :getAdvancedSearch="tab.isactive"
            ></advanced-search>
            <archived-users
              v-else-if="tab.tabname === 'archivedusers'"
              :getArchivedUsers="tab.isactive"
            ></archived-users>
            <export-users
              v-else-if="tab.tabname === 'b2cexportusers'"
              :getExportUsers="tab.isactive"
            ></export-users>
            <b2c-groups
              v-else-if="tab.tabname === 'b2cgroups'"
              :getB2CGroups="tab.isactive"
            ></b2c-groups>
            <active-matrix
              v-else-if="tab.tabname === 'activematrix'"
              :getActiveMatrix="tab.isactive"
            >
            </active-matrix>
            <sponsor-validation
              v-show="false"
              v-else-if="tab.tabname === 'sponsorvalidation'"
              :getSponsorValidationUsers="tab.isactive"
            >
            </sponsor-validation>
          </div>
        </div>
      </div>
    </div>
    <user-details></user-details>
    <user-edit></user-edit>
    <user-membership></user-membership>
    <accessmatrix-save></accessmatrix-save>
    <sponsor-validator></sponsor-validator>
  </section>
</template>

<script>
import ActiveUsers from "@/components/tabelements/active-users";
import AwaitingUsers from "@/components/tabelements/awaiting-users";
import AdvancedSearch from "@/components/tabelements/advanced-search";
import ArchivedUsers from "@/components/tabelements/archived-users";
import ExportUsers from "../components/tabelements/export-users.vue";
import B2cGroups from "../components/tabelements/b2c-groups.vue";
import UserDetails from "../components/common/popups/user-details.vue";
import UserEdit from "../components/common/popups/user-edit.vue";
import UserMembership from "../components/common/popups/user-membership.vue";
import ActiveMatrix from "../components/tabelements/active-matrix.vue";
import AccessmatrixSave from "../components/common/popups/accessmatrix-save.vue";
import SponsorValidation from "../components/tabelements/sponsor-validation.vue";
import SponsorValidator from "../components/common/popups/sponsor-validator.vue";
export default {
  name: "Dashboard",
  components: {
    ActiveUsers,
    AwaitingUsers,
    AdvancedSearch,
    ArchivedUsers,
    ExportUsers,
    B2cGroups,
    UserDetails,
    UserEdit,
    UserMembership,
    ActiveMatrix,
    AccessmatrixSave,
    SponsorValidation,
    SponsorValidator,
  },
  data() {
    return {
      myid: null,
      isAdminAllowed: false,
      isAwaitingusersActive: true,
      isActiveusersActive: false,
      tabs: [
        /*{
          tabname: "sponsorvalidation",
          title: "Sponsor Validation",
          isactive: true,
          visible: true,
        },*/
        {
          tabname: "awaitingusers",
          title: "B2C Awaiting User(s)",
          isactive: true,
          visible: false,
        },
        {
          tabname: "activeusers",
          title: "B2C Active User(s)",
          isactive: false,
          visible: false,
        },
        {
          tabname: "advancedsearch",
          title: "Advanced Search",
          isactive: false,
          visible: false,
        },
        {
          tabname: "archivedusers",
          title: "B2C Archive User(s)",
          isactive: false,
          visible: false,
        },
        {
          tabname: "b2cexportusers",
          title: "Export B2C User(s)",
          isactive: false,
          visible: false,
        },
        {
          tabname: "b2cgroups",
          title: "B2C Groups",
          isactive: false,
          visible: false,
        },
        {
          tabname: "activematrix",
          title: "Access Matrix",
          isactive: false,
          visible: false,
        },
      ],
    };
  },
  created() {
    const _vm = this;
    _vm.getAccessToken();
  },
  methods: {
    getAccessToken: function () {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.getMyId();
        })
        .catch(function (error) {
          _vm.$func.tryClearSession(_vm);
          console.log(error);
        });
    },
    getMyId: function () {
      const _vm = this;
      _vm.$axios
        .get(_vm.$func.profileGraphApiBaseUrl + "me?$select=id", {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("LoginResponse")).accessToken
            }`,
          },
        })
        .then((response) => {
          _vm.$nextTick(() => {
            _vm.myid = response.data.id;
            _vm.getMyGroups(_vm.myid);
          });
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
    getMyGroups: function (uid) {
      const _vm = this;
      _vm.$axios
        .post(
          _vm.$func.profileGraphApiBaseUrl +
            "users/" +
            uid +
            "/getMemberGroups",
          {
            securityEnabledOnly: true,
          },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("LoginResponse")).accessToken
              }`,
            },
          }
        )
        .then((response) => {
          _vm.$nextTick(() => {
            try {
              if (
                response.data !== null &&
                response.data.value !== null &&
                response.data.value.length > 0
              ) {
                for (let i = 0; i < response.data.value.length; i++) {
                  for (
                    let j = 0;
                    j < _vm.$func.normalAdminGroupIds.length;
                    j++
                  ) {
                    if (
                      response.data.value[i] ===
                      _vm.$func.normalAdminGroupIds[j]
                    ) {
                      //console.log("~~~~");
                      _vm.isAdminAllowed = true;
                      for (let x = 0; x < _vm.tabs.length; x++) {
                        if (!_vm.tabs[x].visible) {
                          //console.log("****");
                          _vm.tabs[x].visible = true;
                        }
                      }
                      break;
                    }
                  }
                }
              }
            } catch (exc) {
              console.log(exc);
            }
          });
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
    loadActiveTab: function (tabid) {
      const _vm = this;
      for (let i = 0; i < _vm.tabs.length; i++) {
        _vm.tabs[i].isactive = tabid === _vm.tabs[i].tabname;
      }
    },
  },
};
</script>

<style scoped>
.btn-outline-primary:hover {
  color: #fff;
  background-color: #c3223d;
  border-color: #c3223d;
}
.nav-tabs {
  border-bottom: 1px solid #f6bcc3;
}
link,
.nav-tabs .nav-link.active {
  border-color: #f6bcc3 #f6bcc3 #ffffff;
}
.nav-link:hover,
.dropdown-item:hover {
  color: #9d1c31 !important;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #f6bcc3 #f6bcc3 #dee2e6;
}
</style>
