<template>
  <div>
    <div class="row tblrow">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-close">
            <div class="dropdown"></div>
          </div>
          <div class="card-header d-flex align-items-center">
            <div class="col-sm-6">
              <div class="input-group">
                <input
                  type="search"
                  maxlength="100"
                  class="form-control rounded"
                  style="height: 38px"
                  placeholder=""
                  aria-label="Search"
                  aria-describedby="search-addon"
                  v-model="searchText"
                  @keyup.enter.prevent="searchActiveUser"
                />
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click.prevent="searchActiveUser"
                >
                  Search
                </button>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="pull-right">
                <button
                  type="button"
                  :class="
                    previousUrl === null
                      ? 'btn btn-outline-secondary'
                      : 'btn btn-outline-primary'
                  "
                  :disabled="previousUrl === null"
                  @click.prevent="fetchActiveUsers(previousUrl, true)"
                >
                  <i class="fa fa-angle-double-left"></i>&nbsp;Previous
                </button>
                &nbsp;
                <button
                  type="button"
                  :class="
                    nextUrl === null
                      ? 'btn btn-outline-secondary'
                      : 'btn btn-outline-primary'
                  "
                  :disabled="nextUrl === null"
                  @click.prevent="fetchActiveUsers(nextUrl, false)"
                >
                  Next&nbsp;<i class="fa fa-angle-double-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              id="dvActiveUser"
              class="table-responsive"
              style="max-height: 450px; overflow: auto"
            >
              <table
                id="tblActiveUser"
                class="table table-striped table-bordered table-hover table-condensed table-fixed"
              >
                <thead>
                  <tr>
                    <th class="beam_tableheading">Action</th>
                    <th class="beam_tableheading">
                      Email Add.&nbsp;<i
                        :class="'fa fa-sort-amount-' + activeSortObj.mail.order"
                        @click.prevent="
                          activeSortByField(
                            activeSortObj.mail.field,
                            activeSortObj.mail.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Display name&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' + activeSortObj.displayName.order
                        "
                        @click.prevent="
                          activeSortByField(
                            activeSortObj.displayName.field,
                            activeSortObj.displayName.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Country&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' + activeSortObj.country.order
                        "
                        @click.prevent="
                          activeSortByField(
                            activeSortObj.country.field,
                            activeSortObj.country.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      State&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' + activeSortObj.state.order
                        "
                        @click.prevent="
                          activeSortByField(
                            activeSortObj.state.field,
                            activeSortObj.state.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Employer&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          activeSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Employer
                            .order
                        "
                        @click.prevent="
                          activeSortByField(
                            activeSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Employer
                              .field,
                            activeSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Employer
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      SF Role Id&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          activeSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                            .order
                        "
                        @click.prevent="
                          activeSortByField(
                            activeSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                              .field,
                            activeSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Com. Region&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          activeSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                            .order
                        "
                        @click.prevent="
                          activeSortByField(
                            activeSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                              .field,
                            activeSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Region&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          activeSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Region
                            .order
                        "
                        @click.prevent="
                          activeSortByField(
                            activeSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Region
                              .field,
                            activeSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Region
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <!--<th class="beam_tableheading">Com. area</th>-->
                    <th class="beam_tableheading">
                      Com. division&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          activeSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Division
                            .order
                        "
                        @click.prevent="
                          activeSortByField(
                            activeSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Division
                              .field,
                            activeSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Division
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Activation Date&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          activeSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                            .order
                        "
                        @click.prevent="
                          activeSortByField(
                            activeSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                              .field,
                            activeSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Created On&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          activeSortObj.createdDateTime.order
                        "
                        @click.prevent="
                          activeSortByField(
                            activeSortObj.createdDateTime.field,
                            activeSortObj.createdDateTime.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">Actions</th>
                  </tr>
                </thead>
                <tbody
                  v-if="activeusers.length > 0 && activeusers[0].id !== null"
                >
                  <tr
                    v-for="item in activeusers"
                    :data-id="item.id"
                    :key="item.id"
                  >
                    <th scope="row">
                      <a
                        style="padding-left: 12%"
                        href="javascript:void(0);"
                        data-toggle="tooltip"
                        title="Deactivate"
                        @click.prevent="deactivateUser(item, $event)"
                        ><i class="fa fa-lock fa-2x"></i
                      ></a>
                    </th>
                    <td>
                      <span
                        data-toggle="tooltip"
                        v-html="$func.apiBreakNewLine(item.mail, 35)"
                        :title="item.mail"
                      ></span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.displayName">{{
                        $func.apiShortenString(item.displayName)
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.country">{{
                        $func.apiShortenString(item.country)
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.state">{{
                        $func.apiShortenString(item.state)
                      }}</span>
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Employer
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Employer
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Region
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Region
                          )
                        }}</span
                      >
                    </td>
                    <!--<td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Area
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Area
                          )
                        }}</span
                      >
                    </td>-->
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Division
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Division
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="
                          item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate !==
                            undefined &&
                          item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate !==
                            null &&
                          item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate.trim() !==
                            'NA_NULL' &&
                          new Date(
                            item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                          ) !== undefined
                        "
                        data-toggle="tooltip"
                        :title="
                          $func.dateFormatter(
                            new Date(
                              item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                            )
                          )
                        "
                        >{{
                          $func.dateFormatter(
                            new Date(
                              item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                            )
                          )
                        }}</span
                      >
                      <span v-else>&nbsp;</span>
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          $func.dateFormatter(new Date(item.createdDateTime))
                        "
                        >{{
                          $func.dateFormatter(new Date(item.createdDateTime))
                        }}</span
                      >
                    </td>
                    <td>
                      <action-items
                        :activeActionItems="visibleActiveUsersItems"
                        :userobj="item"
                        :tblid="'tblActiveUser'"
                      ></action-items>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr style="font-size: bolder">
                    <td colspan="13">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionItems from "@/components/common/action-items";
import { EventBus } from "@/event-bus";
export default {
  name: "active-users",
  components: { ActionItems },
  props: ["getActiveusersList"],
  data() {
    return {
      previousUrl: null,
      nextUrl: null,
      urlarrayindex: 0,
      urlarray: [
        {
          previousUrl: null,
          nextUrl: null,
        },
      ],
      activeUserScrolled: false,
      activeUsernextLink: null,
      searchText: "",
      visibleActiveUsersItems: this.$func.itemsVisibility,
      selectedRecordsAtaTimeRange:
        this.$func.loadRecordsAtaTimeRanges[2].toString(),
      activeSortObj: this.$func.sortObject,
      activeusers: [
        {
          id: null,
          accountEnabled: false,
          displayName: null,
          givenName: null,
          jobTitle: null,
          mail: null,
          mobilePhone: null,
          streetAddress: null,
          state: null,
          country: null,
          officeLocation: null,
          city: null,
          postalCode: null,
          preferredLanguage: null,
          surname: null,
          companyName: null,
          createdDateTime: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
          extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
          extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
          extension_be6dc6c96b4c411780751b4231962926_Email: null,
          extension_be6dc6c96b4c411780751b4231962926_Employer: null,
          extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
          extension_be6dc6c96b4c411780751b4231962926_Region: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
          extension_be6dc6c96b4c411780751b4231962926_Division: null,
          extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
          extension_be6dc6c96b4c411780751b4231962926_Area: null,
          extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
          extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
        },
      ],
    };
  },
  created() {
    const _vm = this;
    EventBus.$on("activedata-changed", (data) => {
      try {
        _vm.activeusers.forEach((item, index, object) => {
          if (item.id === data.id) {
            if (
              item.accountEnabled !== undefined &&
              item.accountEnabled !== null &&
              !item.accountEnabled
            ) {
              object.splice(index, 1);
            } else if (data.isdeleted) {
              object.splice(index, 1);
            } else {
              item.givenName = data.data.givenName;
              item.displayName = data.data.displayName;
              item.country = data.data.country;
              item.state = data.data.state;
              item.extension_be6dc6c96b4c411780751b4231962926_Employer =
                data.data.extension_be6dc6c96b4c411780751b4231962926_Employer;
              item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID =
                data.data.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID;
              item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion =
                data.data.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion;
              item.extension_be6dc6c96b4c411780751b4231962926_Region =
                data.data.extension_be6dc6c96b4c411780751b4231962926_Region;
              item.extension_be6dc6c96b4c411780751b4231962926_Division =
                data.data.extension_be6dc6c96b4c411780751b4231962926_Division;
              _vm.$set(_vm.activeusers, index, item);
            }
          }
        });
      } catch (ex) {
        console.error(ex);
      } /*finally {
        EventBus.$off("activedata-changed");
      }*/
    });
  },
  watch: {
    getActiveusersList: function () {
      const _vm = this;
      if (_vm.getActiveusersList) {
        _vm.previousUrl = null;
        _vm.nextUrl = null;
        _vm.urlarrayindex = 0;
        _vm.urlarray = [
          {
            previousUrl: null,
            nextUrl: null,
          },
        ];
        _vm.activeUserScrolled = false;
        _vm.activeUsernextLink = null;
        _vm.searchText = "";
        _vm.activeSortObj = _vm.$func.sortObject;
        _vm.activeusers = [
          {
            id: null,
            accountEnabled: false,
            displayName: null,
            givenName: null,
            jobTitle: null,
            mail: null,
            mobilePhone: null,
            streetAddress: null,
            state: null,
            country: null,
            officeLocation: null,
            city: null,
            postalCode: null,
            preferredLanguage: null,
            surname: null,
            companyName: null,
            createdDateTime: null,
            extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID:
              null,
            extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
            extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
            extension_be6dc6c96b4c411780751b4231962926_Email: null,
            extension_be6dc6c96b4c411780751b4231962926_Employer: null,
            extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
            extension_be6dc6c96b4c411780751b4231962926_Region: null,
            extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
            extension_be6dc6c96b4c411780751b4231962926_Division: null,
            extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
            extension_be6dc6c96b4c411780751b4231962926_Area: null,
            extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
            extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
          },
        ];
        _vm.fetchActiveUsers();
      }
    },
  },
  methods: {
    activeSortByField: function (field, order, isscrolled) {
      const _vm = this;
      isscrolled !== undefined &&
      isscrolled !== null &&
      (isscrolled === false || isscrolled === true)
        ? isscrolled
        : false;
      Object.keys(_vm.activeSortObj).forEach(function (key) {
        if (key === field) {
          _vm.activeSortObj[key].order = isscrolled
            ? order
            : order === "asc"
            ? "desc"
            : "asc";
          if (!isscrolled) {
            _vm.activeSortObj[key].isselected = true;
          }
          _vm.$func.sortArrayModel(
            _vm.activeusers,
            _vm.activeSortObj[key].field,
            _vm.activeSortObj[key].order,
            _vm.activeSortObj[key].isdatefield
          );
        } else {
          _vm.activeSortObj[key].order = "asc";
          _vm.activeSortObj[key].isselected = false;
        }
      });
    },
    activeUsersContextMenu: function () {
      const _vm = this;
      _vm.visibleActiveUsersItems.edit = true;
      _vm.visibleActiveUsersItems.delete = true;
      _vm.visibleActiveUsersItems.resetpassword = true;
      _vm.visibleActiveUsersItems.membership = true;
      _vm.visibleActiveUsersItems.archive = true;
      _vm.visibleActiveUsersItems.details = true;
    },
    sendNotification: function (id, isuser, userstatus) {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm
            .$axios({
              url: process.env.VUE_APP_SERVER_API + "api/Notify",
              method: "POST",
              data: {
                id: id,
                isuser: isuser,
                status: userstatus,
              },
              headers: {
                token: JSON.parse(localStorage.getItem("LoginResponse"))
                  .accessToken,
              },
            })
            .then((response) => {
              console.log(response);
            });
        });
    },
    deactivateUser: function (userobj, evt) {
      const _vm = this;
      _vm.$func
        .sConfirm(
          "Are you sure you want to deactivate " + userobj.displayName + "?",
          _vm.$func.alerticon.question,
          "Select your option",
          "Yes",
          _vm.$swal
        )
        .then((result) => {
          if (result.isConfirmed) {
            _vm.$func
              .tryGetMsalTokenSilently(
                _vm.$func.msalObject,
                _vm.$func.loginRequest
              )
              .then((tokenresponse) => {
                _vm.$store.state.LoginResponse = tokenresponse;
                localStorage.setItem(
                  "LoginResponse",
                  JSON.stringify(tokenresponse)
                );
                _vm.$axios
                  .patch(
                    _vm.$func.profileGraphApiBaseUrl + "users/" + userobj.id,
                    {
                      accountEnabled: false,
                      extension_be6dc6c96b4c411780751b4231962926_ActivationDate:
                        _vm.$func.apiDateFormatter(new Date()),
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${
                          JSON.parse(localStorage.getItem("LoginResponse"))
                            .accessToken
                        }`,
                      },
                    }
                  )
                  .then((resp) => {
                    console.log(resp);
                    _vm.$func
                      .sAlert(
                        userobj.displayName +
                          "'s account has been deactivated successfully.",
                        "success",
                        "",
                        _vm.$swal
                      )
                      .then((ok) => {
                        if (ok) {
                          // eslint-disable-next-line no-undef
                          console.log($(evt.target));
                          userobj.accountEnabled = false;
                          EventBus.$emit("activedata-changed", {
                            id: userobj.id,
                            data: userobj,
                            isdeleted: false,
                          });
                          _vm.sendNotification(
                            userobj.id,
                            true,
                            _vm.$func.msUserStatus.Deactivation
                          );
                        }
                      });
                  })
                  .catch(function (error) {
                    _vm.$func.sAlert(
                      "An error occurred. Please try again after some time",
                      "error",
                      "If error persist, then please contact to administrator",
                      _vm.$swal
                    );
                    console.log(error);
                  });
              });
          }
        });
    },
    searchActiveUser: function () {
      const _vm = this;
      _vm.previousUrl = null;
      _vm.nextUrl = null;
      _vm.urlarrayindex = 0;
      _vm.urlarray = [
        {
          previousUrl: null,
          nextUrl: null,
        },
      ];
      _vm.activeUserScrolled = false;
      _vm.activeUsernextLink = null;
      _vm.activeusers = [
        {
          id: null,
          accountEnabled: false,
          displayName: null,
          givenName: null,
          jobTitle: null,
          mail: null,
          mobilePhone: null,
          streetAddress: null,
          state: null,
          country: null,
          officeLocation: null,
          city: null,
          postalCode: null,
          preferredLanguage: null,
          surname: null,
          companyName: null,
          createdDateTime: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
          extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
          extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
          extension_be6dc6c96b4c411780751b4231962926_Email: null,
          extension_be6dc6c96b4c411780751b4231962926_Employer: null,
          extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
          extension_be6dc6c96b4c411780751b4231962926_Region: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
          extension_be6dc6c96b4c411780751b4231962926_Division: null,
          extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
          extension_be6dc6c96b4c411780751b4231962926_Area: null,
          extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
          extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
        },
      ];
      if (_vm.searchText.trim().length <= 0) {
        _vm.fetchActiveUsers();
      } else {
        let searchUrlQry = "";
        _vm.searchText = _vm.searchText.trim().replace("&", "%26");
        _vm.searchText = _vm.searchText.trim().replace("'", "''");
        if (_vm.$func.validateEmail(_vm.searchText.trim())) {
          searchUrlQry =
            _vm.$func.profileGraphApiBaseUrl +
            "users" +
            "?" +
            "$select=id,accountEnabled,createdDateTime,companyName,givenName,displayName,mail,streetAddress,state,country,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID,extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,extension_be6dc6c96b4c411780751b4231962926_Birthdate,extension_be6dc6c96b4c411780751b4231962926_Email,extension_be6dc6c96b4c411780751b4231962926_Employer,extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,extension_be6dc6c96b4c411780751b4231962926_Region,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID,extension_be6dc6c96b4c411780751b4231962926_Division,extension_be6dc6c96b4c411780751b4231962926_CommercialRegion,extension_be6dc6c96b4c411780751b4231962926_Area,extension_be6dc6c96b4c411780751b4231962926_ActivationDate,extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated" +
            "&" +
            "$filter=(accountEnabled eq true) and (extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated eq true)" +
            " and (mail eq '" +
            _vm.searchText +
            "' " +
            "or extension_be6dc6c96b4c411780751b4231962926_Email eq '" +
            _vm.searchText +
            "')" +
            "&$top=" +
            _vm.$func.loadRecordsAtaTime;
        } else {
          searchUrlQry =
            _vm.$func.profileGraphApiBaseUrl +
            "users" +
            "?" +
            "$select=id,accountEnabled,createdDateTime,companyName,givenName,displayName,mail,streetAddress,state,country,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID,extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,extension_be6dc6c96b4c411780751b4231962926_Birthdate,extension_be6dc6c96b4c411780751b4231962926_Email,extension_be6dc6c96b4c411780751b4231962926_Employer,extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,extension_be6dc6c96b4c411780751b4231962926_Region,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID,extension_be6dc6c96b4c411780751b4231962926_Division,extension_be6dc6c96b4c411780751b4231962926_CommercialRegion,extension_be6dc6c96b4c411780751b4231962926_Area,extension_be6dc6c96b4c411780751b4231962926_ActivationDate,extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated" +
            "&" +
            "$filter=(accountEnabled eq true) and (extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated eq true)" +
            " and (startsWith(displayName, '" +
            _vm.searchText +
            "') " +
            "or startsWith(givenName, '" +
            _vm.searchText +
            "') " +
            "or startsWith(surname, '" +
            _vm.searchText +
            "') " +
            "or startsWith(state, '" +
            _vm.searchText +
            "') " +
            "or startsWith(country, '" +
            _vm.searchText +
            "'))" +
            "&$top=" +
            _vm.$func.loadRecordsAtaTime;
        }
        _vm.fetchActiveUsers(searchUrlQry);
      }
    },
    changeRange: function (evt) {
      const _vm = this;
      _vm.selectedRecordsAtaTimeRange = evt.target.value;
      _vm.searchText = "";
      _vm.activeSortObj = _vm.$func.sortObject;
      _vm.previousUrl = null;
      _vm.nextUrl = null;
      _vm.urlarrayindex = 0;
      _vm.activeUserScrolled = false;
      _vm.activeUsernextLink = null;
      _vm.activeusers = [
        {
          id: null,
          accountEnabled: false,
          displayName: null,
          givenName: null,
          jobTitle: null,
          mail: null,
          mobilePhone: null,
          streetAddress: null,
          state: null,
          country: null,
          officeLocation: null,
          city: null,
          postalCode: null,
          preferredLanguage: null,
          surname: null,
          companyName: null,
          createdDateTime: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
          extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
          extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
          extension_be6dc6c96b4c411780751b4231962926_Email: null,
          extension_be6dc6c96b4c411780751b4231962926_Employer: null,
          extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
          extension_be6dc6c96b4c411780751b4231962926_Region: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
          extension_be6dc6c96b4c411780751b4231962926_Division: null,
          extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
          extension_be6dc6c96b4c411780751b4231962926_Area: null,
          extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
          extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
        },
      ];
      // eslint-disable-next-line no-undef
      $("#dvActiveUser").scrollTop(0);
      _vm.fetchActiveUsers();
    },
    fetchActiveUsers: function (url, ispreviuos) {
      const _vm = this;
      if (_vm.urlarrayindex - 1 === undefined) {
        ispreviuos = false;
      }
      if (url === undefined || url === null) {
        url =
          _vm.$func.profileGraphApiBaseUrl +
          "users" +
          "?" +
          "$select=id,accountEnabled,createdDateTime,companyName,givenName,displayName,mail,streetAddress,state,country,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID,extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,extension_be6dc6c96b4c411780751b4231962926_Birthdate,extension_be6dc6c96b4c411780751b4231962926_Email,extension_be6dc6c96b4c411780751b4231962926_Employer,extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,extension_be6dc6c96b4c411780751b4231962926_Region,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID,extension_be6dc6c96b4c411780751b4231962926_Division,extension_be6dc6c96b4c411780751b4231962926_CommercialRegion,extension_be6dc6c96b4c411780751b4231962926_Area,extension_be6dc6c96b4c411780751b4231962926_ActivationDate,extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated" +
          "&" +
          "$filter=(accountEnabled eq true) and (extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated eq true)" +
          "&$top=" +
          _vm.$func.loadRecordsAtaTime;
        _vm.urlarray = [];
        _vm.urlarray.push({
          previousUrl: null,
          nextUrl: url,
        });
        _vm.urlarrayindex = _vm.urlarray?.length - 1;
      }
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.$axios
            .get(url, {
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(localStorage.getItem("LoginResponse")).accessToken
                }`,
              },
            })
            .then((response) => {
              if (
                response.data["@odata.nextLink"] !== undefined &&
                response.data["@odata.nextLink"] !== null
              ) {
                _vm.activeUsernextLink = response.data["@odata.nextLink"];
              } else {
                _vm.activeUsernextLink = null;
              }
              _vm.$nextTick(() => {
                _vm.activeusers = response.data.value;
                // eslint-disable-next-line no-undef
                $("div.table-responsive")
                  .removeAttr("style")
                  .attr(
                    "style",
                    "max-height: " +
                      // eslint-disable-next-line no-undef
                      ($(window).height() - ($("header").height() + 300)) +
                      "px; overflow: auto"
                  );
                _vm.activeusers = _vm.activeusers.filter(
                  (v, i, a) => a.findIndex((t) => t.id === v.id) === i
                );
                if (
                  _vm.activeusers.length > 0 &&
                  _vm.activeusers[0].id !== null
                ) {
                  for (let w = 0; w < _vm.activeusers.length; w++) {
                    _vm.activeusers[w].mail =
                      _vm.activeusers[w].mail !== undefined &&
                      _vm.activeusers[w].mail !== null &&
                      _vm.activeusers[w].mail !== ""
                        ? _vm.activeusers[w].mail
                        : _vm.activeusers[w]
                            .extension_be6dc6c96b4c411780751b4231962926_Email !==
                            undefined &&
                          _vm.activeusers[w]
                            .extension_be6dc6c96b4c411780751b4231962926_Email !==
                            null &&
                          _vm.activeusers[w]
                            .extension_be6dc6c96b4c411780751b4231962926_Email !==
                            ""
                        ? _vm.activeusers[w]
                            .extension_be6dc6c96b4c411780751b4231962926_Email
                        : _vm.$func.validateEmail(
                            _vm.activeusers[w].streetAddress
                          )
                        ? _vm.activeusers[w].streetAddress
                        : "";
                  }
                }
              });
            })
            .catch(function (error) {
              console.error(error);
            })
            .finally(function () {
              if (ispreviuos) {
                _vm.urlarray.pop();
              } else {
                _vm.urlarray.push({
                  previousUrl: url,
                  nextUrl: _vm.activeUsernextLink,
                });
                _vm.urlarray = _vm.urlarray.filter(
                  (v, i, a) =>
                    a.findIndex(
                      (t) =>
                        t.previousUrl === v.previousUrl &&
                        t.nextUrl === v.nextUrl
                    ) === i
                );
              }
              _vm.urlarrayindex = _vm.urlarray?.length - 1;
              _vm.nextUrl = _vm.urlarray[_vm.urlarrayindex].nextUrl;
              _vm.previousUrl =
                _vm.urlarray[
                  _vm.urlarrayindex - 1 < 0 ? 0 : _vm.urlarrayindex - 1
                ].previousUrl;
              // eslint-disable-next-line no-undef
              $('[data-toggle="tooltip"]').tooltip();
              _vm.activeUsersContextMenu();
              _vm.activeSortObj.createdDateTime.isselected = false;
              _vm.activeSortObj.createdDateTime.order = "asc";
              _vm.activeSortObj.extension_be6dc6c96b4c411780751b4231962926_ActivationDate.isselected = true;
              _vm.activeSortObj.extension_be6dc6c96b4c411780751b4231962926_ActivationDate.order =
                "desc";
              let selectedSortObj;
              Object.keys(_vm.activeSortObj).forEach(function (key) {
                if (_vm.activeSortObj[key].isselected) {
                  selectedSortObj = _vm.activeSortObj[key];
                }
              });
              _vm.activeSortByField(
                selectedSortObj.field,
                selectedSortObj.order,
                true
              );
            });
        });
    },
  },
};
</script>

<style scoped>
.table-fixed {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.table-fixed thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
}
.table-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
  border: 1px solid #f6bcc3;
}
</style>
