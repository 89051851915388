<template>
  <div
    id="userDetails"
    class="modal fade"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            User Details - {{ useritemdetails.displayName }}
          </h4>
          <button type="button" class="close" @click.prevent="closeModal()">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row" style="text-align: left">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="orderheading">User Principal Name</div>
                        <div>
                          <span
                            v-html="
                              $func.apiBreakNewLine(
                                useritemdetails.userPrincipalName,
                                80
                              )
                            "
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Created Date</div>
                        <div>
                          {{
                            useritemdetails.createdDateTime !== undefined &&
                            useritemdetails.createdDateTime !== null
                              ? $func.dateFormatter(
                                  new Date(useritemdetails.createdDateTime)
                                )
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Activation Date</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_ActivationDate !==
                              undefined &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_ActivationDate !==
                              null
                              ? $func.dateFormatter(
                                  new Date(
                                    useritemdetails.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                                  )
                                )
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Birth Date</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Birthdate !==
                              undefined &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Birthdate !==
                              null
                              ? $func.dateFormatter(
                                  new Date(
                                    useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Birthdate
                                  )
                                )
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Email Address</div>
                        <div
                          data-toggle="tooltip"
                          :title="
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Email !==
                              undefined &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Email !==
                              null
                              ? useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Email
                              : useritemdetails.mail !== null
                              ? useritemdetails.mail
                              : useritemdetails.streetAddress
                          "
                        >
                          <span
                            v-html="
                              $func.apiBreakNewLine(
                                useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Email !==
                                  undefined &&
                                  useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Email !==
                                    null
                                  ? useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Email
                                  : useritemdetails.mail !== null
                                  ? useritemdetails.mail
                                  : $func.validateEmail(
                                      useritemdetails.streetAddress
                                    )
                                  ? useritemdetails.streetAddress
                                  : '',
                                28
                              )
                            "
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Given Name</div>
                        <div>{{ useritemdetails.givenName }}</div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Surname</div>
                        <div>{{ useritemdetails.surname }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Country</div>
                        <div>{{ useritemdetails.country }}</div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">State</div>
                        <div>{{ useritemdetails.state }}</div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Commercial Area</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Area
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Success Factor ID</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Success Factor Role ID</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Employer</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Beam Suntory Sponsor</div>
                        <div
                          v-html="
                            $func.apiBreakNewLine(
                              useritemdetails.extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,
                              30
                            )
                          "
                        ></div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">On/Of Premises</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_OnOffPremise
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Region</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Region
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Commercial Region</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Commercial Division</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Division
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">BSI Brand</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-primary"
            @click.prevent="openEditModal(useritemdetails)"
          >
            Edit
          </button>
          <button
            class="btn btn-outline-secondary"
            @click.prevent="closeModal()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-details",
  data() {
    return {
      useritemdetails: {
        id: null,
        displayName: null,
        givenName: null,
        jobTitle: null,
        birthday: null,
        mail: null,
        mobilePhone: null,
        streetAddress: null,
        state: null,
        country: null,
        officeLocation: null,
        city: null,
        postalCode: null,
        preferredLanguage: null,
        surname: null,
        companyName: null,
        extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
        extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
        extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
        extension_be6dc6c96b4c411780751b4231962926_Email: null,
        extension_be6dc6c96b4c411780751b4231962926_Employer: null,
        extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
        extension_be6dc6c96b4c411780751b4231962926_Region: null,
        extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
        extension_be6dc6c96b4c411780751b4231962926_Division: null,
        extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
        extension_be6dc6c96b4c411780751b4231962926_Area: null,
        extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand: null,
        userPrincipalName: null,
        createdDateTime: null,
        extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
        extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
      },
    };
  },
  mounted() {
    const _vm = this;
    // eslint-disable-next-line no-undef
    $(document)
      .off("shown.bs.modal", "#userDetails")
      .on("shown.bs.modal", "#userDetails", function () {
        _vm.fetchUserById(_vm.$func.useritemid);
      });
  },
  methods: {
    closeModal: function () {
      const _vm = this;
      _vm.useritemdetails = {
        id: null,
        displayName: null,
        givenName: null,
        jobTitle: null,
        birthday: null,
        mail: null,
        mobilePhone: null,
        streetAddress: null,
        state: null,
        country: null,
        officeLocation: null,
        city: null,
        postalCode: null,
        preferredLanguage: null,
        surname: null,
        companyName: null,
        extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
        extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
        extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
        extension_be6dc6c96b4c411780751b4231962926_Email: null,
        extension_be6dc6c96b4c411780751b4231962926_Employer: null,
        extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
        extension_be6dc6c96b4c411780751b4231962926_Region: null,
        extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
        extension_be6dc6c96b4c411780751b4231962926_Division: null,
        extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
        extension_be6dc6c96b4c411780751b4231962926_Area: null,
        extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand: null,
        userPrincipalName: null,
        createdDateTime: null,
        extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
        extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
      };
      _vm.$func.useritemid = null;
      // eslint-disable-next-line no-undef
      $("#userDetails").modal("hide");
    },
    openEditModal: function (userobj) {
      const _vm = this;
      _vm.closeModal();
      _vm.$func.useritemid = userobj.id;
      // eslint-disable-next-line no-undef
      $("#userEdit").modal("show");
    },
    fetchUserById: function (id) {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.$axios
            .get(
              _vm.$func.profileGraphApiBaseUrl +
                "users/" +
                id +
                "?" +
                "$select=id,createdDateTime,givenName,surname,displayName,mail,mobilePhone,streetAddress,state,country,city,mobilePhone,preferredLanguage,userPrincipalName,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID,extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,extension_be6dc6c96b4c411780751b4231962926_Birthdate,extension_be6dc6c96b4c411780751b4231962926_Email,extension_be6dc6c96b4c411780751b4231962926_Employer,extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,extension_be6dc6c96b4c411780751b4231962926_Region,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID,extension_be6dc6c96b4c411780751b4231962926_Division,extension_be6dc6c96b4c411780751b4231962926_CommercialRegion,extension_be6dc6c96b4c411780751b4231962926_Area,extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand,extension_be6dc6c96b4c411780751b4231962926_ActivationDate,extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated",
              {
                headers: {
                  Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("LoginResponse"))
                      .accessToken
                  }`,
                },
              }
            )
            .then((response) => {
              _vm.$nextTick(() => {
                try {
                  _vm.useritemdetails = response.data;
                  _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Birthdate =
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Birthdate ===
                    undefined
                      ? null
                      : _vm.useritemdetails
                          .extension_be6dc6c96b4c411780751b4231962926_Birthdate;
                  _vm.useritemdetails
                    .extension_be6dc6c96b4c411780751b4231962926_ActivationDate ===
                  undefined
                    ? null
                    : _vm.useritemdetails
                        .extension_be6dc6c96b4c411780751b4231962926_ActivationDate;
                  _vm.useritemdetails
                    .extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated ===
                  undefined
                    ? null
                    : _vm.useritemdetails
                        .extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated;
                } catch (ex) {
                  console.error(ex);
                }
              });
            })
            .catch(function (error) {
              _vm.$func.sAlert(
                "An error occurred. Please try again after some time",
                "error",
                "If error persist, then please contact to administrator",
                _vm.$swal
              );
              console.error(error);
            })
            .finally(function () {
              // eslint-disable-next-line no-undef
              $('[data-toggle="tooltip"]').tooltip();
            });
        });
    },
  },
};
</script>

<style scoped>
.orderheading {
  font-weight: 600;
  color: #9d1c31;
}
</style>
