<template>
  <div>
    <div class="row tblrow">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-close">
            <div class="dropdown"></div>
          </div>
          <div class="card-header d-flex align-items-center">
            <div class="col-sm-6">
              <div class="input-group">
                <input
                  type="search"
                  maxlength="100"
                  class="form-control rounded"
                  style="height: 38px"
                  placeholder=""
                  aria-label="Search"
                  aria-describedby="search-addon"
                  v-model="searchText"
                  @keyup.enter.prevent="searchSponsorValidationUser"
                />
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click.prevent="searchSponsorValidationUser"
                >
                  Search
                </button>
              </div>
            </div>
            <div class="col-sm-6">
              <div
                v-if="
                  sponsorvalidationusers.length > 0 &&
                  sponsorvalidationusers[0].id !== null &&
                  sponsorvalidationusers.filter((s) => s.checked) !==
                    undefined &&
                  sponsorvalidationusers.filter((s) => s.checked).length > 0
                "
                class="pull-right"
              >
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  @click.prevent="rejectSelectedUsers"
                >
                  <i class="fa fa-thumbs-down"></i>&nbsp;Reject Selected
                </button>
                &nbsp;
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click.prevent="approveSelectedUsers"
                >
                  <i class="fa fa-thumbs-up"></i>&nbsp;Approve Selected
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              id="dvSponsorValidationUser"
              class="table-responsive"
              style="max-height: 450px; overflow: auto"
            >
              <table
                id="tblSponsorValidationUser"
                class="table table-striped table-bordered table-hover table-condensed table-fixed"
              >
                <thead>
                  <tr>
                    <th class="beam_tableheading">Action</th>
                    <th class="beam_tableheading">
                      Email Add.&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          sponsorValidationSortObj.mail.order
                        "
                        @click.prevent="
                          sponsorValidationSortByField(
                            sponsorValidationSortObj.mail.field,
                            sponsorValidationSortObj.mail.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Display name&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          sponsorValidationSortObj.displayName.order
                        "
                        @click.prevent="
                          sponsorValidationSortByField(
                            sponsorValidationSortObj.displayName.field,
                            sponsorValidationSortObj.displayName.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Country&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          sponsorValidationSortObj.country.order
                        "
                        @click.prevent="
                          sponsorValidationSortByField(
                            sponsorValidationSortObj.country.field,
                            sponsorValidationSortObj.country.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      State&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          sponsorValidationSortObj.state.order
                        "
                        @click.prevent="
                          sponsorValidationSortByField(
                            sponsorValidationSortObj.state.field,
                            sponsorValidationSortObj.state.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Employer&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          sponsorValidationSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Employer
                            .order
                        "
                        @click.prevent="
                          sponsorValidationSortByField(
                            sponsorValidationSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Employer
                              .field,
                            sponsorValidationSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Employer
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      SF Role Id&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          sponsorValidationSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                            .order
                        "
                        @click.prevent="
                          sponsorValidationSortByField(
                            sponsorValidationSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                              .field,
                            sponsorValidationSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Com. Region&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          sponsorValidationSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                            .order
                        "
                        @click.prevent="
                          sponsorValidationSortByField(
                            sponsorValidationSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                              .field,
                            sponsorValidationSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Region&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          sponsorValidationSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Region
                            .order
                        "
                        @click.prevent="
                          sponsorValidationSortByField(
                            sponsorValidationSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Region
                              .field,
                            sponsorValidationSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Region
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Com. division&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          sponsorValidationSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Division
                            .order
                        "
                        @click.prevent="
                          sponsorValidationSortByField(
                            sponsorValidationSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Division
                              .field,
                            sponsorValidationSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Division
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Created On&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          sponsorValidationSortObj.createdDateTime.order
                        "
                        @click.prevent="
                          sponsorValidationSortByField(
                            sponsorValidationSortObj.createdDateTime.field,
                            sponsorValidationSortObj.createdDateTime.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      <input
                        type="checkbox"
                        @click="selectAll"
                        v-model="allSelected"
                        :disabled="
                          sponsorvalidationusers.length > 0 &&
                          sponsorvalidationusers[0].id === null
                        "
                      />&nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    sponsorvalidationusers.length > 0 &&
                    sponsorvalidationusers[0].id !== null
                  "
                >
                  <tr
                    v-for="item in sponsorvalidationusers"
                    :data-id="item.id"
                    :key="item.id"
                  >
                    <th scope="row">
                      <a
                        v-if="
                          isAdminAllowed ||
                          (myemail !== undefined &&
                            myemail !== null &&
                            item.extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor !==
                              undefined &&
                            item.extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor !==
                              null &&
                            myemail.toLowerCase().trim() ===
                              item.extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor
                                .toLowerCase()
                                .trim())
                        "
                        style="padding-left: 12%"
                        href="javascript:void(0);"
                        data-toggle="tooltip"
                        title="View Details"
                        @click.prevent="
                          viewDetailsSponsorValidationUser(item, $event)
                        "
                        ><i class="fa fa-id-card-o fa-2x"></i
                      ></a>
                      <span v-else>&nbsp;</span>
                    </th>
                    <td>
                      <span
                        data-toggle="tooltip"
                        v-html="$func.apiBreakNewLine(item.mail, 35)"
                        :title="item.mail"
                      ></span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.displayName">{{
                        $func.apiShortenString(item.displayName)
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.country">{{
                        $func.apiShortenString(item.country)
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.state">{{
                        $func.apiShortenString(item.state)
                      }}</span>
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Employer
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Employer
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Region
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Region
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Division
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Division
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="
                          item.createdDateTime !== undefined &&
                          item.createdDateTime !== null &&
                          item.createdDateTime.trim() !== 'NA_NULL' &&
                          new Date(item.createdDateTime) !== undefined
                        "
                        data-toggle="tooltip"
                        :title="
                          $func.dateFormatter(new Date(item.createdDateTime))
                        "
                        >{{
                          $func.dateFormatter(new Date(item.createdDateTime))
                        }}</span
                      >
                      <span v-else>&nbsp;</span>
                    </td>
                    <th scope="row" class="beam_tableheading">
                      <input
                        type="checkbox"
                        @click="selectOne(item)"
                        v-model="item.checked"
                      />&nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr style="font-size: bolder">
                    <td colspan="12">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
export default {
  name: "sponsor-validation",
  props: ["getSponsorValidationUsers"],
  data() {
    return {
      allSelected: false,
      myid: null,
      myemail: null,
      isAdminAllowed: false,
      sponsorValidationUserScrolled: false,
      sponsorValidationnextLink: null,
      searchText: "",
      visibleSponsorValidationUsersItems: this.$func.itemsVisibility,
      selectedRecordsAtaTimeRange:
        this.$func.loadRecordsAtaTimeRanges[2].toString(),
      sponsorValidationSortObj: this.$func.sortObject,
      sponsorvalidationusers: [
        {
          checked: false,
          id: null,
          displayName: null,
          givenName: null,
          jobTitle: null,
          mail: null,
          mobilePhone: null,
          streetAddress: null,
          state: null,
          country: null,
          officeLocation: null,
          city: null,
          postalCode: null,
          preferredLanguage: null,
          surname: null,
          companyName: null,
          createdDateTime: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
          extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
          extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
          extension_be6dc6c96b4c411780751b4231962926_Email: null,
          extension_be6dc6c96b4c411780751b4231962926_Employer: null,
          extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
          extension_be6dc6c96b4c411780751b4231962926_Region: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
          extension_be6dc6c96b4c411780751b4231962926_Division: null,
          extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
          extension_be6dc6c96b4c411780751b4231962926_Area: null,
          extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
          extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
        },
      ],
    };
  },
  created() {
    const _vm = this;
    EventBus.$on("sponsordata-changed", (data) => {
      try {
        _vm.sponsorvalidationusers.forEach((item, index, object) => {
          if (item.id === data.id) {
            object.splice(index, 1);
          }
        });
      } catch (ex) {
        console.error(ex);
      }
    });
    if (_vm.getSponsorValidationUsers) {
      _vm.sponsorValidationUserScrolled = false;
      _vm.sponsorValidationnextLink = null;
      _vm.searchText = "";
      _vm.sponsorValidationSortObj = _vm.$func.sortObject;
      _vm.sponsorvalidationusers = [
        {
          checked: false,
          id: null,
          displayName: null,
          givenName: null,
          jobTitle: null,
          mail: null,
          mobilePhone: null,
          streetAddress: null,
          state: null,
          country: null,
          officeLocation: null,
          city: null,
          postalCode: null,
          preferredLanguage: null,
          surname: null,
          companyName: null,
          createdDateTime: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
          extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
          extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
          extension_be6dc6c96b4c411780751b4231962926_Email: null,
          extension_be6dc6c96b4c411780751b4231962926_Employer: null,
          extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
          extension_be6dc6c96b4c411780751b4231962926_Region: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
          extension_be6dc6c96b4c411780751b4231962926_Division: null,
          extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
          extension_be6dc6c96b4c411780751b4231962926_Area: null,
          extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
          extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
        },
      ];
      _vm.getMyIdAndEmailFollowedByUserList();
    }
  },
  watch: {
    getSponsorValidationUsers: function () {
      const _vm = this;
      if (_vm.getSponsorValidationUsers) {
        _vm.sponsorValidationUserScrolled = false;
        _vm.sponsorValidationnextLink = null;
        _vm.searchText = "";
        _vm.sponsorValidationSortObj = _vm.$func.sortObject;
        _vm.sponsorvalidationusers = [
          {
            checked: false,
            id: null,
            displayName: null,
            givenName: null,
            jobTitle: null,
            mail: null,
            mobilePhone: null,
            streetAddress: null,
            state: null,
            country: null,
            officeLocation: null,
            city: null,
            postalCode: null,
            preferredLanguage: null,
            surname: null,
            companyName: null,
            createdDateTime: null,
            extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID:
              null,
            extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
            extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
            extension_be6dc6c96b4c411780751b4231962926_Email: null,
            extension_be6dc6c96b4c411780751b4231962926_Employer: null,
            extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
            extension_be6dc6c96b4c411780751b4231962926_Region: null,
            extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
            extension_be6dc6c96b4c411780751b4231962926_Division: null,
            extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
            extension_be6dc6c96b4c411780751b4231962926_Area: null,
            extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
            extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
          },
        ];
        _vm.getMyIdAndEmailFollowedByUserList();
      }
    },
  },
  methods: {
    selectAll: function () {
      const _vm = this;
      _vm.allSelected = !_vm.allSelected;
      for (let user in _vm.sponsorvalidationusers) {
        _vm.sponsorvalidationusers[user].checked = _vm.allSelected;
      }
    },
    selectOne: function (item) {
      const _vm = this;
      item.checked = !item.checked;
      _vm.allSelected = true;
      for (let user in _vm.sponsorvalidationusers) {
        if (!_vm.sponsorvalidationusers[user].checked) {
          _vm.allSelected = false;
          break;
        }
      }
    },
    setChecked: function () {
      const _vm = this;
      try {
        _vm.allSelected = false;
        for (let i in _vm.sponsorvalidationusers) {
          _vm.sponsorvalidationusers[i].checked = false;
        }
      } catch (ex) {
        console.warn(ex);
      }
    },
    removeNonSponsorUsersIfNotAdmin: function (myemail) {
      const _vm = this;
      if (!_vm.isAdminAllowed) {
        _vm.sponsorvalidationusers = _vm.sponsorvalidationusers.filter(
          (item) =>
            item.extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor
              ?.trim()
              ?.toLowerCase() === myemail?.trim()?.toLowerCase()
        );
      }
    },
    approveSelectedUsers: function () {
      const _vm = this;
      _vm.$func
        .sConfirm(
          "Are you sure you want to approve selected user(s)?",
          _vm.$func.alerticon.question,
          "Select your option",
          "Yes",
          _vm.$swal
        )
        .then((result) => {
          if (result.isConfirmed) {
            const selectedIds = [];
            for (let i in _vm.sponsorvalidationusers) {
              if (_vm.sponsorvalidationusers[i].checked) {
                selectedIds.push(_vm.sponsorvalidationusers[i].id);
              }
            }
            _vm.doApproveRejectUsers(selectedIds, true);
          }
        });
    },
    rejectSelectedUsers: function () {
      const _vm = this;
      _vm.$func
        .sConfirm(
          "Are you sure you want to reject selected user(s)?",
          _vm.$func.alerticon.question,
          "Select your option",
          "Yes",
          _vm.$swal
        )
        .then((result) => {
          if (result.isConfirmed) {
            const selectedIds = [];
            for (let i in _vm.sponsorvalidationusers) {
              if (_vm.sponsorvalidationusers[i].checked) {
                selectedIds.push(_vm.sponsorvalidationusers[i].id);
              }
            }
            _vm.doApproveRejectUsers(selectedIds, false);
          }
        });
    },
    doApproveRejectUsers: function (selectedUserIds, forapprove) {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm
            .$axios({
              url: process.env.VUE_APP_SERVER_API + "api/BulkSponsorValidator",
              method: "POST",
              data: {
                approve: forapprove,
                ids: selectedUserIds,
              },
              headers: {
                token: JSON.parse(localStorage.getItem("LoginResponse"))
                  .accessToken,
              },
            })
            .then((response) => {
              if (response.data.success) {
                _vm.$func
                  .sAlert(response.data.message, "success", "", _vm.$swal)
                  .then((ok) => {
                    if (ok) {
                      selectedUserIds.forEach((id) => {
                        try {
                          _vm.sponsorvalidationusers.forEach(
                            (item, index, object) => {
                              if (item.id === id) {
                                object.splice(index, 1);
                              }
                            }
                          );
                        } catch (ex) {
                          console.error(ex);
                        }
                      });
                      _vm.allSelected = false;
                    }
                  });
              }
            });
        });
    },
    viewDetailsSponsorValidationUser: function (userobj, evt) {
      const _vm = this;
      // eslint-disable-next-line no-undef
      console.log($(evt.target));
      _vm.$func.useritemid = userobj.id;
      // eslint-disable-next-line no-undef
      $("#sponsorValidatorUserDetails").modal("show");
    },
    sponsorValidationSortByField: function (field, order, isscrolled) {
      const _vm = this;
      isscrolled !== undefined &&
      isscrolled !== null &&
      (isscrolled === false || isscrolled === true)
        ? isscrolled
        : false;
      Object.keys(_vm.sponsorValidationSortObj).forEach(function (key) {
        if (key === field) {
          _vm.sponsorValidationSortObj[key].order = isscrolled
            ? order
            : order === "asc"
            ? "desc"
            : "asc";
          if (!isscrolled) {
            _vm.sponsorValidationSortObj[key].isselected = true;
          }
          _vm.$func.sortArrayModel(
            _vm.sponsorvalidationusers,
            _vm.sponsorValidationSortObj[key].field,
            _vm.sponsorValidationSortObj[key].order,
            _vm.sponsorValidationSortObj[key].isdatefield
          );
        } else {
          _vm.sponsorValidationSortObj[key].order = "asc";
          _vm.sponsorValidationSortObj[key].isselected = false;
        }
      });
    },
    getMyOwnGroups: function (uid) {
      const _vm = this;
      _vm.$axios
        .post(
          _vm.$func.profileGraphApiBaseUrl +
            "users/" +
            uid +
            "/getMemberGroups",
          {
            securityEnabledOnly: true,
          },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("LoginResponse")).accessToken
              }`,
            },
          }
        )
        .then((response) => {
          _vm.$nextTick(() => {
            try {
              if (
                response.data !== null &&
                response.data.value !== null &&
                response.data.value.length > 0
              ) {
                for (let i = 0; i < response.data.value.length; i++) {
                  for (let j = 0; j < _vm.$func.adminGroupIds.length; j++) {
                    if (response.data.value[i] === _vm.$func.adminGroupIds[j]) {
                      _vm.isAdminAllowed = true;
                      break;
                    }
                  }
                }
              }
            } catch (exc) {
              console.log(exc);
            }
          });
        })
        .catch(function (error) {
          console.log(error.response);
        })
        .finally(function () {
          _vm.fetchSponsorValidationUsers();
        });
    },
    getMyIdAndEmailFollowedByUserList: function () {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.$axios
            .get(
              _vm.$func.profileGraphApiBaseUrl +
                "me?$select=id,mail,extension_be6dc6c96b4c411780751b4231962926_Email,streetAddress",
              {
                headers: {
                  Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("LoginResponse"))
                      .accessToken
                  }`,
                },
              }
            )
            .then((response) => {
              _vm.$nextTick(() => {
                _vm.myid = response.data.id;
                _vm.myemail =
                  response.data
                    .extension_be6dc6c96b4c411780751b4231962926_Email ===
                    undefined ||
                  response.data
                    .extension_be6dc6c96b4c411780751b4231962926_Email === null
                    ? response.data.mail === undefined ||
                      response.data.mail === null
                      ? response.data.streetAddress
                      : response.data.mail
                    : response.data
                        .extension_be6dc6c96b4c411780751b4231962926_Email;
                _vm.getMyOwnGroups(_vm.myid);
              });
            })
            .catch(function (error) {
              console.error(error.response);
            });
        });
    },
    searchSponsorValidationUser: function () {
      const _vm = this;
      _vm.sponsorValidationUserScrolled = false;
      _vm.sponsorValidationnextLink = null;
      _vm.sponsorvalidationusers = [
        {
          checked: false,
          id: null,
          displayName: null,
          givenName: null,
          jobTitle: null,
          mail: null,
          mobilePhone: null,
          streetAddress: null,
          state: null,
          country: null,
          officeLocation: null,
          city: null,
          postalCode: null,
          preferredLanguage: null,
          surname: null,
          companyName: null,
          createdDateTime: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
          extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
          extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
          extension_be6dc6c96b4c411780751b4231962926_Email: null,
          extension_be6dc6c96b4c411780751b4231962926_Employer: null,
          extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
          extension_be6dc6c96b4c411780751b4231962926_Region: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
          extension_be6dc6c96b4c411780751b4231962926_Division: null,
          extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
          extension_be6dc6c96b4c411780751b4231962926_Area: null,
          extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
          extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
        },
      ];
      if (_vm.searchText.trim().length <= 0) {
        _vm.fetchSponsorValidationUsers();
      } else {
        let searchUrlQry = "";
        _vm.searchText = _vm.searchText.trim().replace("&", "%26");
        _vm.searchText = _vm.searchText.trim().replace("'", "''");
        searchUrlQry =
          _vm.$func.profileGraphApiBaseUrl +
          "users" +
          "?" +
          "$select=id,createdDateTime,companyName,givenName,displayName,mail,streetAddress,state,country,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID,extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,extension_be6dc6c96b4c411780751b4231962926_Birthdate,extension_be6dc6c96b4c411780751b4231962926_Email,extension_be6dc6c96b4c411780751b4231962926_Employer,extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,extension_be6dc6c96b4c411780751b4231962926_Region,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID,extension_be6dc6c96b4c411780751b4231962926_Division,extension_be6dc6c96b4c411780751b4231962926_CommercialRegion,extension_be6dc6c96b4c411780751b4231962926_Area,extension_be6dc6c96b4c411780751b4231962926_ActivationDate,extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated" +
          "&" +
          "$filter=(accountEnabled eq false) and (extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated eq false)" +
          " and (startsWith(displayName, '" +
          _vm.searchText +
          "') " +
          "or startsWith(givenName, '" +
          _vm.searchText +
          "') " +
          "or startsWith(surname, '" +
          _vm.searchText +
          "') " +
          "or startsWith(state, '" +
          _vm.searchText +
          "') " +
          "or startsWith(country, '" +
          _vm.searchText +
          "'))" +
          "&$top=" +
          _vm.$func.loadRecordsAtaTime;
        _vm.fetchSponsorValidationUsers(searchUrlQry);
      }
    },
    fetchSponsorValidationUsers: function (url) {
      const _vm = this;
      if (url === undefined) {
        url =
          _vm.$func.profileGraphApiBaseUrl +
          "users" +
          "?" +
          "$select=id,createdDateTime,companyName,givenName,displayName,mail,streetAddress,state,country,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID,extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,extension_be6dc6c96b4c411780751b4231962926_Birthdate,extension_be6dc6c96b4c411780751b4231962926_Email,extension_be6dc6c96b4c411780751b4231962926_Employer,extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,extension_be6dc6c96b4c411780751b4231962926_Region,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID,extension_be6dc6c96b4c411780751b4231962926_Division,extension_be6dc6c96b4c411780751b4231962926_CommercialRegion,extension_be6dc6c96b4c411780751b4231962926_Area,extension_be6dc6c96b4c411780751b4231962926_ActivationDate,extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated" +
          "&" +
          "$filter=(accountEnabled eq false) and (extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated eq false)" +
          "&$top=" +
          _vm.$func.loadRecordsAtaTime;
      }
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.$axios
            .get(url, {
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(localStorage.getItem("LoginResponse")).accessToken
                }`,
              },
            })
            .then((response) => {
              if (
                response.data["@odata.nextLink"] !== undefined &&
                response.data["@odata.nextLink"] !== null
              ) {
                _vm.sponsorValidationnextLink =
                  response.data["@odata.nextLink"];
              } else {
                _vm.sponsorValidationnextLink = null;
              }
              _vm.$nextTick(() => {
                if (
                  _vm.sponsorvalidationusers.length > 0 &&
                  _vm.sponsorvalidationusers[0].id !== null
                ) {
                  _vm.sponsorvalidationusers.push.apply(
                    _vm.sponsorvalidationusers,
                    response.data.value
                  );
                  _vm.sponsorvalidationusers =
                    _vm.sponsorvalidationusers.filter(
                      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
                    );
                } else {
                  _vm.sponsorvalidationusers = response.data.value;
                  // eslint-disable-next-line no-undef
                  $("div.table-responsive")
                    .removeAttr("style")
                    .attr(
                      "style",
                      "max-height: " +
                        // eslint-disable-next-line no-undef
                        ($(window).height() - ($("header").height() + 300)) +
                        "px; overflow: auto"
                    );
                }
                if (
                  _vm.sponsorvalidationusers.length > 0 &&
                  _vm.sponsorvalidationusers[0].id !== null
                ) {
                  for (let r = 0; r < _vm.sponsorvalidationusers.length; r++) {
                    _vm.sponsorvalidationusers[r].mail =
                      _vm.sponsorvalidationusers[r].mail !== undefined &&
                      _vm.sponsorvalidationusers[r].mail !== null &&
                      _vm.sponsorvalidationusers[r].mail !== ""
                        ? _vm.sponsorvalidationusers[r].mail
                        : _vm.sponsorvalidationusers[r]
                            .extension_be6dc6c96b4c411780751b4231962926_Email !==
                            undefined &&
                          _vm.sponsorvalidationusers[r]
                            .extension_be6dc6c96b4c411780751b4231962926_Email !==
                            null &&
                          _vm.sponsorvalidationusers[r]
                            .extension_be6dc6c96b4c411780751b4231962926_Email !==
                            ""
                        ? _vm.sponsorvalidationusers[r]
                            .extension_be6dc6c96b4c411780751b4231962926_Email
                        : _vm.$func.validateEmail(
                            _vm.sponsorvalidationusers[r].streetAddress
                          )
                        ? _vm.sponsorvalidationusers[r].streetAddress
                        : "";
                  }
                }
              });
            })
            .catch(function (error) {
              console.error(error);
            })
            .finally(function () {
              if (_vm.sponsorValidationnextLink !== null) {
                _vm.fetchSponsorValidationUsers(_vm.sponsorValidationnextLink);
              } else {
                _vm.setChecked();
                _vm.removeNonSponsorUsersIfNotAdmin(_vm.myemail);
              }
              // eslint-disable-next-line no-undef
              $('[data-toggle="tooltip"]').tooltip();
              _vm.sponsorValidationSortObj.extension_be6dc6c96b4c411780751b4231962926_ActivationDate.isselected = false;
              _vm.sponsorValidationSortObj.extension_be6dc6c96b4c411780751b4231962926_ActivationDate.order =
                "asc";
              _vm.sponsorValidationSortObj.createdDateTime.isselected = true;
              _vm.sponsorValidationSortObj.createdDateTime.order = "desc";
              let selectedSortObj;
              Object.keys(_vm.sponsorValidationSortObj).forEach(function (key) {
                if (_vm.sponsorValidationSortObj[key].isselected) {
                  selectedSortObj = _vm.sponsorValidationSortObj[key];
                }
              });
              _vm.sponsorValidationSortByField(
                selectedSortObj.field,
                selectedSortObj.order,
                true
              );
            });
        });
    },
  },
};
</script>

<style scoped>
.table-fixed {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.table-fixed thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
}
.table-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
  border: 1px solid #f6bcc3;
}
</style>
