<template>
  <div>
    <div class="row tblrow">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-close">
            <div class="dropdown"></div>
          </div>
          <div class="card-header d-flex align-items-center">
            <div class="col-sm-6">
              <div class="input-group">
                <input
                  type="search"
                  maxlength="100"
                  class="form-control rounded"
                  style="height: 38px"
                  placeholder=""
                  aria-label="Search"
                  aria-describedby="search-addon"
                  v-model="searchText"
                  @keyup.enter.prevent="searchArchivedUser"
                />
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click.prevent="searchArchivedUser"
                >
                  Search
                </button>
              </div>
            </div>
            <div class="col-sm-4">
              <span
                style="
                  font-size: 0.9em;
                  color: #777777;
                  text-align: left;
                  font-style: italic;
                "
                >Note: In order to re-activate an archived user, please edit the
                user and remove the "ZZDel" prefix from the GivenName/FirstName.
                Once done, then those user will moved the "B2C Awaiting Users"
                list, from there activate the user.</span
              >
            </div>
            <div class="col-sm-2">
              <div class="pull-right">
                <button
                  type="button"
                  :class="
                    previousUrl === null
                      ? 'btn btn-outline-secondary'
                      : 'btn btn-outline-primary'
                  "
                  :disabled="previousUrl === null"
                  @click.prevent="fetchArchivedUsers(previousUrl, true)"
                >
                  <i class="fa fa-angle-double-left"></i>&nbsp;Previous
                </button>
                &nbsp;
                <button
                  type="button"
                  :class="
                    nextUrl === null
                      ? 'btn btn-outline-secondary'
                      : 'btn btn-outline-primary'
                  "
                  :disabled="nextUrl === null"
                  @click.prevent="fetchArchivedUsers(nextUrl, false)"
                >
                  Next&nbsp;<i class="fa fa-angle-double-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              id="dvArchivedUser"
              class="table-responsive"
              style="max-height: 450px; overflow: auto"
            >
              <table
                id="tblArchivedUser"
                class="table table-striped table-bordered table-hover table-condensed table-fixed"
              >
                <thead>
                  <tr>
                    <th class="beam_tableheading">
                      Email Add.&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' + archivedSortObj.mail.order
                        "
                        @click.prevent="
                          archivedSortByField(
                            archivedSortObj.mail.field,
                            archivedSortObj.mail.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Display name&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          archivedSortObj.displayName.order
                        "
                        @click.prevent="
                          archivedSortByField(
                            archivedSortObj.displayName.field,
                            archivedSortObj.displayName.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Country&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' + archivedSortObj.country.order
                        "
                        @click.prevent="
                          archivedSortByField(
                            archivedSortObj.country.field,
                            archivedSortObj.country.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      State&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' + archivedSortObj.state.order
                        "
                        @click.prevent="
                          archivedSortByField(
                            archivedSortObj.state.field,
                            archivedSortObj.state.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Employer&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Employer
                            .order
                        "
                        @click.prevent="
                          archivedSortByField(
                            archivedSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Employer
                              .field,
                            archivedSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Employer
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      SF Role Id&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                            .order
                        "
                        @click.prevent="
                          archivedSortByField(
                            archivedSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                              .field,
                            archivedSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Com. Region&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                            .order
                        "
                        @click.prevent="
                          archivedSortByField(
                            archivedSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                              .field,
                            archivedSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Region&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Region
                            .order
                        "
                        @click.prevent="
                          archivedSortByField(
                            archivedSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Region
                              .field,
                            archivedSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Region
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <!--<th class="beam_tableheading">Com. area</th>-->
                    <th class="beam_tableheading">
                      Com. division&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Division
                            .order
                        "
                        @click.prevent="
                          archivedSortByField(
                            archivedSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Division
                              .field,
                            archivedSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Division
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Activation Date&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                            .order
                        "
                        @click.prevent="
                          archivedSortByField(
                            archivedSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                              .field,
                            archivedSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">Actions</th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    archivedusers.length > 0 && archivedusers[0].id !== null
                  "
                >
                  <tr
                    v-for="item in archivedusers"
                    :data-id="item.id"
                    :key="item.id"
                  >
                    <td>
                      <span
                        data-toggle="tooltip"
                        v-html="$func.apiBreakNewLine(item.mail, 35)"
                        :title="item.mail"
                      ></span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.displayName">{{
                        $func.apiShortenString(item.displayName)
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.country">{{
                        $func.apiShortenString(item.country)
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.state">{{
                        $func.apiShortenString(item.state)
                      }}</span>
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Employer
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Employer
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Region
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Region
                          )
                        }}</span
                      >
                    </td>
                    <!--<td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Area
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Area
                          )
                        }}</span
                      >
                    </td>-->
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Division
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Division
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="
                          item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate !==
                            undefined &&
                          item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate !==
                            null &&
                          item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate.trim() !==
                            'NA_NULL' &&
                          new Date(
                            item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                          ) !== undefined
                        "
                        data-toggle="tooltip"
                        :title="
                          $func.dateFormatter(
                            new Date(
                              item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                            )
                          )
                        "
                        >{{
                          $func.dateFormatter(
                            new Date(
                              item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                            )
                          )
                        }}</span
                      >
                      <span v-else>&nbsp;</span>
                    </td>
                    <td>
                      <action-items
                        :activeActionItems="visibleArchivedUsersItems"
                        :userobj="item"
                        :tblid="'tblArchivedUser'"
                      ></action-items>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr style="font-size: bolder">
                    <td colspan="12">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionItems from "@/components/common/action-items";
import { EventBus } from "@/event-bus";
export default {
  name: "archived-users",
  components: { ActionItems },
  props: ["getArchivedUsers"],
  data() {
    return {
      previousUrl: null,
      nextUrl: null,
      urlarrayindex: 0,
      urlarray: [
        {
          previousUrl: null,
          nextUrl: null,
        },
      ],
      archivedUserScrolled: false,
      archivedUsernextLink: null,
      searchText: "",
      visibleArchivedUsersItems: this.$func.itemsVisibility,
      selectedRecordsAtaTimeRange:
        this.$func.loadRecordsAtaTimeRanges[2].toString(),
      archivedSortObj: this.$func.sortObject,
      archivedusers: [
        {
          id: null,
          displayName: null,
          givenName: null,
          jobTitle: null,
          mail: null,
          mobilePhone: null,
          streetAddress: null,
          state: null,
          country: null,
          officeLocation: null,
          city: null,
          postalCode: null,
          preferredLanguage: null,
          surname: null,
          companyName: null,
          createdDateTime: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
          extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
          extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
          extension_be6dc6c96b4c411780751b4231962926_Email: null,
          extension_be6dc6c96b4c411780751b4231962926_Employer: null,
          extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
          extension_be6dc6c96b4c411780751b4231962926_Region: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
          extension_be6dc6c96b4c411780751b4231962926_Division: null,
          extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
          extension_be6dc6c96b4c411780751b4231962926_Area: null,
          extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
          extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
          extension_be6dc6c96b4c411780751b4231962926_IsArchive: null,
        },
      ],
    };
  },
  created() {
    const _vm = this;
    EventBus.$on("archiveddata-changed", (data) => {
      try {
        _vm.archivedusers.forEach((item, index, object) => {
          if (item.id === data.id) {
            if (
              !data.data.givenName.toLowerCase().startsWith("zz") ||
              !data.data.displayName.toLowerCase().startsWith("zz")
            ) {
              object.splice(index, 1);
            } else {
              item.givenName = data.data.givenName;
              item.displayName = data.data.displayName;
              item.country = data.data.country;
              item.state = data.data.state;
              item.extension_be6dc6c96b4c411780751b4231962926_Employer =
                data.data.extension_be6dc6c96b4c411780751b4231962926_Employer;
              item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID =
                data.data.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID;
              item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion =
                data.data.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion;
              item.extension_be6dc6c96b4c411780751b4231962926_Region =
                data.data.extension_be6dc6c96b4c411780751b4231962926_Region;
              item.extension_be6dc6c96b4c411780751b4231962926_Division =
                data.data.extension_be6dc6c96b4c411780751b4231962926_Division;
              _vm.$set(_vm.archivedusers, index, item);
            }
          }
        });
      } catch (ex) {
        console.error(ex);
      }
    });
  },
  watch: {
    getArchivedUsers: function () {
      const _vm = this;
      if (_vm.getArchivedUsers) {
        _vm.previousUrl = null;
        _vm.nextUrl = null;
        _vm.urlarrayindex = 0;
        _vm.urlarray = [
          {
            previousUrl: null,
            nextUrl: null,
          },
        ];
        _vm.archivedUserScrolled = false;
        _vm.archivedUsernextLink = null;
        _vm.searchText = "";
        _vm.archivedSortObj = _vm.$func.sortObject;
        _vm.archivedusers = [
          {
            id: null,
            displayName: null,
            givenName: null,
            jobTitle: null,
            mail: null,
            mobilePhone: null,
            streetAddress: null,
            state: null,
            country: null,
            officeLocation: null,
            city: null,
            postalCode: null,
            preferredLanguage: null,
            surname: null,
            companyName: null,
            createdDateTime: null,
            extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID:
              null,
            extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
            extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
            extension_be6dc6c96b4c411780751b4231962926_Email: null,
            extension_be6dc6c96b4c411780751b4231962926_Employer: null,
            extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
            extension_be6dc6c96b4c411780751b4231962926_Region: null,
            extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
            extension_be6dc6c96b4c411780751b4231962926_Division: null,
            extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
            extension_be6dc6c96b4c411780751b4231962926_Area: null,
            extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
            extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
            extension_be6dc6c96b4c411780751b4231962926_IsArchive: null,
          },
        ];
        _vm.fetchArchivedUsers();
      }
    },
  },
  methods: {
    archivedSortByField: function (field, order, isscrolled) {
      const _vm = this;
      isscrolled !== undefined &&
      isscrolled !== null &&
      (isscrolled === false || isscrolled === true)
        ? isscrolled
        : false;
      Object.keys(_vm.archivedSortObj).forEach(function (key) {
        if (key === field) {
          _vm.archivedSortObj[key].order = isscrolled
            ? order
            : order === "asc"
            ? "desc"
            : "asc";
          if (!isscrolled) {
            _vm.archivedSortObj[key].isselected = true;
          }
          _vm.$func.sortArrayModel(
            _vm.archivedusers,
            _vm.archivedSortObj[key].field,
            _vm.archivedSortObj[key].order,
            _vm.archivedSortObj[key].isdatefield
          );
        } else {
          _vm.archivedSortObj[key].order = "asc";
          _vm.archivedSortObj[key].isselected = false;
        }
      });
    },
    archivedUsersContextMenu: function () {
      const _vm = this;
      _vm.visibleArchivedUsersItems.edit = true;
      _vm.visibleArchivedUsersItems.delete = false;
      _vm.visibleArchivedUsersItems.resetpassword = false;
      _vm.visibleArchivedUsersItems.membership = false;
      _vm.visibleArchivedUsersItems.archive = false;
      _vm.visibleArchivedUsersItems.details = true;
    },
    searchArchivedUser: function () {
      const _vm = this;
      _vm.previousUrl = null;
      _vm.nextUrl = null;
      _vm.urlarrayindex = 0;
      _vm.urlarray = [
        {
          previousUrl: null,
          nextUrl: null,
        },
      ];
      _vm.archivedUserScrolled = false;
      _vm.archivedUsernextLink = null;
      _vm.archivedusers = [
        {
          id: null,
          displayName: null,
          givenName: null,
          jobTitle: null,
          mail: null,
          mobilePhone: null,
          streetAddress: null,
          state: null,
          country: null,
          officeLocation: null,
          city: null,
          postalCode: null,
          preferredLanguage: null,
          surname: null,
          companyName: null,
          createdDateTime: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
          extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
          extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
          extension_be6dc6c96b4c411780751b4231962926_Email: null,
          extension_be6dc6c96b4c411780751b4231962926_Employer: null,
          extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
          extension_be6dc6c96b4c411780751b4231962926_Region: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
          extension_be6dc6c96b4c411780751b4231962926_Division: null,
          extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
          extension_be6dc6c96b4c411780751b4231962926_Area: null,
          extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
          extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
          extension_be6dc6c96b4c411780751b4231962926_IsArchive: null,
        },
      ];
      if (_vm.searchText.trim().length <= 0) {
        _vm.fetchArchivedUsers();
      } else {
        let searchUrlQry = "";
        _vm.searchText = _vm.searchText.trim().replace("&", "%26");
        _vm.searchText = _vm.searchText.trim().replace("'", "''");
        if (_vm.$func.validateEmail(_vm.searchText.trim())) {
          searchUrlQry =
            _vm.$func.profileGraphApiBaseUrl +
            "users" +
            "?" +
            "$select=id,createdDateTime,companyName,givenName,displayName,mail,streetAddress,state,country,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID,extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,extension_be6dc6c96b4c411780751b4231962926_Birthdate,extension_be6dc6c96b4c411780751b4231962926_Email,extension_be6dc6c96b4c411780751b4231962926_Employer,extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,extension_be6dc6c96b4c411780751b4231962926_Region,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID,extension_be6dc6c96b4c411780751b4231962926_Division,extension_be6dc6c96b4c411780751b4231962926_CommercialRegion,extension_be6dc6c96b4c411780751b4231962926_Area,extension_be6dc6c96b4c411780751b4231962926_ActivationDate,extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated,extension_be6dc6c96b4c411780751b4231962926_IsArchive" +
            "&" +
            "$filter=(accountEnabled eq false) and (extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated eq true) and (extension_be6dc6c96b4c411780751b4231962926_IsArchive eq true) and (startswith(displayName,'ZZ') or startswith(displayName,'zz') or startswith(displayName,'Zz') or startswith(displayName,'zZ'))" +
            " and (mail eq '" +
            _vm.searchText +
            "' " +
            "or extension_be6dc6c96b4c411780751b4231962926_Email eq '" +
            _vm.searchText +
            "')" +
            "&$top=" +
            _vm.$func.loadRecordsAtaTime;
        } else {
          searchUrlQry =
            _vm.$func.profileGraphApiBaseUrl +
            "users" +
            "?" +
            "$select=id,createdDateTime,companyName,givenName,displayName,mail,streetAddress,state,country,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID,extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,extension_be6dc6c96b4c411780751b4231962926_Birthdate,extension_be6dc6c96b4c411780751b4231962926_Email,extension_be6dc6c96b4c411780751b4231962926_Employer,extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,extension_be6dc6c96b4c411780751b4231962926_Region,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID,extension_be6dc6c96b4c411780751b4231962926_Division,extension_be6dc6c96b4c411780751b4231962926_CommercialRegion,extension_be6dc6c96b4c411780751b4231962926_Area,extension_be6dc6c96b4c411780751b4231962926_ActivationDate,extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated,extension_be6dc6c96b4c411780751b4231962926_IsArchive" +
            "&" +
            "$filter=(accountEnabled eq false) and (extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated eq true) and (extension_be6dc6c96b4c411780751b4231962926_IsArchive eq true) and (startswith(displayName,'ZZ') or startswith(displayName,'zz') or startswith(displayName,'Zz') or startswith(displayName,'zZ'))" +
            " and (startsWith(displayName, '" +
            _vm.searchText +
            "') " +
            "or startsWith(givenName, '" +
            _vm.searchText +
            "') " +
            "or startsWith(surname, '" +
            _vm.searchText +
            "') " +
            "or startsWith(state, '" +
            _vm.searchText +
            "') " +
            "or startsWith(country, '" +
            _vm.searchText +
            "'))" +
            "&$top=" +
            _vm.$func.loadRecordsAtaTime;
        }
        _vm.fetchArchivedUsers(searchUrlQry);
      }
    },
    changeRange: function (evt) {
      const _vm = this;
      _vm.selectedRecordsAtaTimeRange = evt.target.value;
      _vm.searchText = "";
      _vm.previousUrl = null;
      _vm.nextUrl = null;
      _vm.archivedUserScrolled = false;
      _vm.archivedUsernextLink = null;
      _vm.archivedusers = [
        {
          id: null,
          displayName: null,
          givenName: null,
          jobTitle: null,
          mail: null,
          mobilePhone: null,
          streetAddress: null,
          state: null,
          country: null,
          officeLocation: null,
          city: null,
          postalCode: null,
          preferredLanguage: null,
          surname: null,
          companyName: null,
          createdDateTime: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
          extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
          extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
          extension_be6dc6c96b4c411780751b4231962926_Email: null,
          extension_be6dc6c96b4c411780751b4231962926_Employer: null,
          extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
          extension_be6dc6c96b4c411780751b4231962926_Region: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
          extension_be6dc6c96b4c411780751b4231962926_Division: null,
          extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
          extension_be6dc6c96b4c411780751b4231962926_Area: null,
          extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
          extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
          extension_be6dc6c96b4c411780751b4231962926_IsArchive: null,
        },
      ];
      // eslint-disable-next-line no-undef
      $("#dvArchivedUser").scrollTop(0);
      _vm.fetchArchivedUsers();
    },
    fetchArchivedUsers: function (url, ispreviuos) {
      const _vm = this;
      if (_vm.urlarrayindex - 1 === undefined) {
        ispreviuos = false;
      }
      if (url === undefined) {
        url =
          _vm.$func.profileGraphApiBaseUrl +
          "users" +
          "?" +
          "$select=id,createdDateTime,companyName,givenName,displayName,mail,streetAddress,state,country,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID,extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,extension_be6dc6c96b4c411780751b4231962926_Birthdate,extension_be6dc6c96b4c411780751b4231962926_Email,extension_be6dc6c96b4c411780751b4231962926_Employer,extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,extension_be6dc6c96b4c411780751b4231962926_Region,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID,extension_be6dc6c96b4c411780751b4231962926_Division,extension_be6dc6c96b4c411780751b4231962926_CommercialRegion,extension_be6dc6c96b4c411780751b4231962926_Area,extension_be6dc6c96b4c411780751b4231962926_ActivationDate,extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated,extension_be6dc6c96b4c411780751b4231962926_IsArchive" +
          "&" +
          "$filter=(accountEnabled eq false) and (extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated eq true) and (extension_be6dc6c96b4c411780751b4231962926_IsArchive eq true) and (startswith(displayName,'ZZ') or startswith(displayName,'zz') or startswith(displayName,'Zz') or startswith(displayName,'zZ'))" +
          "&$top=" +
          _vm.$func.loadRecordsAtaTime;
        _vm.urlarray = [];
        _vm.urlarray.push({
          previousUrl: null,
          nextUrl: url,
        });
        _vm.urlarrayindex = _vm.urlarray?.length - 1;
      }
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.$axios
            .get(url, {
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(localStorage.getItem("LoginResponse")).accessToken
                }`,
              },
            })
            .then((response) => {
              if (
                response.data["@odata.nextLink"] !== undefined &&
                response.data["@odata.nextLink"] !== null
              ) {
                _vm.archivedUsernextLink = response.data["@odata.nextLink"];
              } else {
                _vm.archivedUsernextLink = null;
              }
              _vm.$nextTick(() => {
                _vm.archivedusers = response.data.value;
                // eslint-disable-next-line no-undef
                $("div.table-responsive")
                  .removeAttr("style")
                  .attr(
                    "style",
                    "max-height: " +
                      // eslint-disable-next-line no-undef
                      ($(window).height() - ($("header").height() + 300)) +
                      "px; overflow: auto"
                  );
                if (
                  _vm.archivedusers.length > 0 &&
                  _vm.archivedusers[0].id !== null
                ) {
                  for (let r = 0; r < _vm.archivedusers.length; r++) {
                    _vm.archivedusers[r].mail =
                      _vm.archivedusers[r].mail !== undefined &&
                      _vm.archivedusers[r].mail !== null &&
                      _vm.archivedusers[r].mail !== ""
                        ? _vm.archivedusers[r].mail
                        : _vm.archivedusers[r]
                            .extension_be6dc6c96b4c411780751b4231962926_Email !==
                            undefined &&
                          _vm.archivedusers[r]
                            .extension_be6dc6c96b4c411780751b4231962926_Email !==
                            null &&
                          _vm.archivedusers[r]
                            .extension_be6dc6c96b4c411780751b4231962926_Email !==
                            ""
                        ? _vm.archivedusers[r]
                            .extension_be6dc6c96b4c411780751b4231962926_Email
                        : _vm.$func.validateEmail(
                            _vm.archivedusers[r].streetAddress
                          )
                        ? _vm.archivedusers[r].streetAddress
                        : "";
                  }
                }
              });
            })
            .catch(function (error) {
              console.log(error);
            })
            .finally(function () {
              if (ispreviuos) {
                _vm.urlarray.pop();
              } else {
                _vm.urlarray.push({
                  previousUrl: url,
                  nextUrl: _vm.archivedUsernextLink,
                });
                _vm.urlarray = _vm.urlarray.filter(
                  (v, i, a) =>
                    a.findIndex(
                      (t) =>
                        t.previousUrl === v.previousUrl &&
                        t.nextUrl === v.nextUrl
                    ) === i
                );
              }
              _vm.urlarrayindex = _vm.urlarray?.length - 1;
              _vm.nextUrl = _vm.urlarray[_vm.urlarrayindex].nextUrl;
              _vm.previousUrl =
                _vm.urlarray[
                  _vm.urlarrayindex - 1 < 0 ? 0 : _vm.urlarrayindex - 1
                ].previousUrl;
              // eslint-disable-next-line no-undef
              $('[data-toggle="tooltip"]').tooltip();
              _vm.archivedUsersContextMenu();
              _vm.archivedSortObj.createdDateTime.isselected = false;
              _vm.archivedSortObj.createdDateTime.order = "asc";
              _vm.archivedSortObj.extension_be6dc6c96b4c411780751b4231962926_ActivationDate.isselected = true;
              _vm.archivedSortObj.extension_be6dc6c96b4c411780751b4231962926_ActivationDate.order =
                "desc";
              let selectedSortObj;
              Object.keys(_vm.archivedSortObj).forEach(function (key) {
                if (_vm.archivedSortObj[key].isselected) {
                  selectedSortObj = _vm.archivedSortObj[key];
                }
              });
              _vm.archivedSortByField(
                selectedSortObj.field,
                selectedSortObj.order,
                true
              );
            });
        });
    },
  },
};
</script>

<style scoped>
.table-fixed {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.table-fixed thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
}
.table-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
  border: 1px solid #f6bcc3;
}
</style>
