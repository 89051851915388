<template>
  <div>
    <div class="row tblrow">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-close">
            <div class="dropdown"></div>
          </div>
          <div class="card-header d-flex align-items-center">
            <div class="col-sm-6">
              <div class="input-group" v-if="1 === 2">
                <input
                  type="search"
                  maxlength="100"
                  class="form-control rounded"
                  style="height: 38px"
                  placeholder=""
                  aria-label="Search"
                  aria-describedby="search-addon"
                  v-model="searchText"
                  @keyup.enter.prevent="searchAccessMatrixItem"
                />
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click.prevent="searchAccessMatrixItem"
                >
                  Search
                </button>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="pull-right">
                <a
                  href="javascript:void(0);"
                  @click.prevent="openAccessMatrixModal(null)"
                >
                  <span class="add-span">+</span></a
                >
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              id="dvAccessMatrix"
              class="table-responsive"
              style="max-height: 450px; overflow: auto"
            >
              <table
                id="tblAccessMatrix"
                class="table table-striped table-bordered table-hover table-condensed table-fixed"
              >
                <thead>
                  <tr>
                    <th class="beam_tableheading">Action</th>
                    <th class="beam_tableheading">User Type</th>
                    <th class="beam_tableheading">Region</th>
                    <th class="beam_tableheading">Country</th>
                    <th class="beam_tableheading">State</th>
                    <th class="beam_tableheading">Employer</th>
                  </tr>
                </thead>
                <tbody v-if="recordlist.length > 0 && recordlist[0].ID !== 0">
                  <tr
                    v-for="item in recordlist"
                    :data-id="item.guid"
                    :key="item.guid"
                  >
                    <th scope="row">
                      <a
                        style="padding-left: 12%"
                        href="javascript:void(0);"
                        data-toggle="tooltip"
                        title="Edit"
                        @click.prevent="openAccessMatrixModal(item.guid)"
                        ><i
                          class="fa fa-pencil-square-o fa-2x text-secondary"
                        ></i
                      ></a>
                      <a
                        style="padding-left: 12%"
                        href="javascript:void(0);"
                        data-toggle="tooltip"
                        title="Delete"
                        @click.prevent="deleteItem(item)"
                        ><i class="fa fa-trash-o fa-2x"></i
                      ></a>
                    </th>
                    <td>
                      <span data-toggle="tooltip" :title="item.title">{{
                        $func.apiShortenString(item.title)
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.region">{{
                        $func.apiShortenString(item.region)
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.country">{{
                        $func.apiShortenString(item.country)
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.state">{{
                        $func.apiShortenString(item.state)
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.employer">{{
                        $func.apiShortenString(item.employer)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr style="font-size: bolder">
                    <td colspan="6">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
export default {
  name: "active-matrix",
  props: ["getActiveMatrix"],
  data() {
    return {
      searchText: "",
      recordlist: [
        {
          metadatatype: null,
          ID: 0,
          title: null,
          modified: new Date(),
          created: new Date(),
          authorId: 0,
          editorId: 0,
          attachments: false,
          guid: null,
          complianceAssetId: null,
          region: null,
          country: null,
          state: null,
          employer: null,
          isDel: "No",
        },
      ],
    };
  },
  created() {
    const _vm = this;
    EventBus.$on("accessmatrix-changed", (data) => {
      try {
        if (data.isupdate) {
          _vm.recordlist.forEach((item, index) => {
            if (item.guid === data.data.guid) {
              item.title = data.data.title;
              item.region = data.data.region;
              item.country = data.data.country;
              item.state = data.data.state;
              item.employer = data.data.employer;
              _vm.$set(_vm.recordlist, index, item);
            }
          });
        } else {
          _vm.recordlist.push(data.data);
          _vm.$func.sortArrayModel(_vm.recordlist, "created", "desc", true);
        }
      } catch (ex) {
        console.error(ex);
      }
    });
  },
  watch: {
    getActiveMatrix: function () {
      const _vm = this;
      if (_vm.getActiveMatrix) {
        _vm.searchText = "";
        _vm.recordlist = [
          {
            metadatatype: null,
            ID: 0,
            title: null,
            modified: new Date(),
            created: new Date(),
            authorId: 0,
            editorId: 0,
            attachments: false,
            guid: null,
            complianceAssetId: null,
            region: null,
            country: null,
            state: null,
            employer: null,
            isDel: "No",
          },
        ];
        _vm.fetchData();
      }
    },
  },
  methods: {
    searchAccessMatrixItem: function () {
      const _vm = this;
      _vm.$func.sAlert(
        "Not yet implemented",
        "warning",
        "Search functionality is not yet implemented. It is under development stage.",
        _vm.$swal
      );
    },
    deleteItem: function (itm) {
      const _vm = this;
      _vm.$func
        .sConfirm(
          "Are you sure you want to delete the record?",
          _vm.$func.alerticon.question,
          "Select your option",
          "Yes",
          _vm.$swal
        )
        .then((result) => {
          if (result.isConfirmed) {
            _vm.$func
              .tryGetMsalTokenSilently(
                _vm.$func.msalObject,
                _vm.$func.loginRequest
              )
              .then((tokenresponse) => {
                _vm.$store.state.LoginResponse = tokenresponse;
                localStorage.setItem(
                  "LoginResponse",
                  JSON.stringify(tokenresponse)
                );
                _vm
                  .$axios({
                    url:
                      process.env.VUE_APP_SERVER_API +
                      "api/SpointListProvider?id=" +
                      itm.guid,
                    method: "DELETE",
                    headers: {
                      token: JSON.parse(localStorage.getItem("LoginResponse"))
                        .accessToken,
                    },
                    data: {
                      metadatatype: itm.metadatatype,
                    },
                  })
                  .then((response) => {
                    if (response.data.success) {
                      _vm.$func
                        .sAlert(
                          "Record (" +
                            itm.ID +
                            ") has been deleted successfully.",
                          "success",
                          "",
                          _vm.$swal
                        )
                        .then((ok) => {
                          if (ok) {
                            _vm.recordlist.forEach((item, index, object) => {
                              if (item.ID === itm.ID) {
                                object.splice(index, 1);
                              }
                            });
                          }
                        });
                    }
                  })
                  .catch(function (error) {
                    console.log(error.response);
                    _vm.$func.sAlert(
                      error.response.data.message,
                      "error",
                      "",
                      _vm.$swal
                    );
                  });
              });
          }
        });
    },
    openAccessMatrixModal: function (guid) {
      const _vm = this;
      _vm.$func.spguid = guid;
      // eslint-disable-next-line no-undef
      $("#activeMatrixPopup").modal("show");
    },
    fetchData: function () {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm
            .$axios({
              url: process.env.VUE_APP_SERVER_API + "api/SpointListProvider",
              method: "GET",
              headers: {
                token: JSON.parse(localStorage.getItem("LoginResponse"))
                  .accessToken,
              },
            })
            .then((response) => {
              _vm.$nextTick(() => {
                if (_vm.recordlist.length > 0 && _vm.recordlist[0].ID !== 0) {
                  _vm.recordlist.push.apply(
                    _vm.recordlist,
                    response.data.message
                  );
                  _vm.recordlist = _vm.recordlist.filter(
                    (v, i, a) => a.findIndex((t) => t.guid === v.guid) === i
                  );
                } else {
                  _vm.recordlist = response.data.message;
                  // eslint-disable-next-line no-undef
                  $("div.table-responsive")
                    .removeAttr("style")
                    .attr(
                      "style",
                      "max-height: " +
                        // eslint-disable-next-line no-undef
                        ($(window).height() - ($("header").height() + 300)) +
                        "px; overflow: auto"
                    );
                }
              });
            })
            .catch(function (error) {
              console.error(error);
            })
            .finally(function () {
              // eslint-disable-next-line no-undef
              $('[data-toggle="tooltip"]').tooltip();
            });
        });
    },
  },
};
</script>

<style scoped>
.table-fixed {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.table-fixed thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
}
.table-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
  border: 1px solid #f6bcc3;
}
.add-span {
  color: #ffff;
  font-weight: 300;
  font-size: 35px;
  background: #9d1c31;
  padding: 2px 6px 6px 6px;
  border: 1px solid #9d1c31;
  border-radius: 3px;
}
</style>
