<template>
  <ul class="nav-menu list-unstyled action-ul">
    <li class="nav-item dropdown">
      <a
        id="messages"
        rel="nofollow"
        data-target="#"
        href="#"
        data-toggle="dropdown"
        data-boundary="window"
        aria-haspopup="true"
        aria-expanded="false"
        class="nav-link"
      >
        <img src="@/assets/img/Icon assets/action.png" style="z-index: 1" />
      </a>
      <ul aria-labelledby="notifications" class="dropdown-menu">
        <li v-if="activeActionItems.edit">
          <a
            rel="nofollow"
            href="javascript:void(0);"
            class="dropdown-item d-flex"
            @click.prevent="editUser(userobj, tblid)"
          >
            <div class="msg-body">
              <div style="padding-top: 5px; font-size: 14px">
                <span style="padding-right: 10px"
                  ><img src="@/assets/img/Icon assets/edit.png" /></span
                >Edit
              </div>
            </div></a
          >
        </li>
        <li v-if="activeActionItems.details">
          <a
            rel="nofollow"
            href="javascript:void(0);"
            class="dropdown-item d-flex"
            @click.prevent="viewDetails(userobj, tblid)"
          >
            <div class="msg-body">
              <div style="padding-top: 5px; font-size: 14px">
                <span style="padding-right: 10px"
                  ><img src="@/assets/img/Icon assets/details.png" /></span
                >View Details
              </div>
            </div></a
          >
        </li>
        <li v-if="activeActionItems.resetpassword && false">
          <a
            rel="nofollow"
            href="javascript:void(0);"
            class="dropdown-item d-flex"
            @click.prevent="passwordReset(userobj, tblid)"
          >
            <div class="msg-body">
              <div style="padding-top: 5px; font-size: 14px">
                <span style="padding-right: 10px"
                  ><img src="@/assets/img/Icon assets/password.png" /></span
                >Reset Password
              </div>
            </div></a
          >
        </li>
        <li v-if="activeActionItems.delete">
          <a
            rel="nofollow"
            href="javascript:void(0);"
            class="dropdown-item d-flex"
            @click.prevent="deleteUser(userobj, tblid)"
          >
            <div class="msg-body">
              <div style="padding-top: 5px; font-size: 14px">
                <span style="padding-right: 10px"
                  ><img src="@/assets/img/Icon assets/delete.png" /></span
                >Delete
              </div>
            </div></a
          >
        </li>
        <li v-if="activeActionItems.archive">
          <a
            rel="nofollow"
            href="javascript:void(0);"
            class="dropdown-item d-flex"
            @click.prevent="archiveUser(userobj, tblid)"
          >
            <div class="msg-body">
              <div style="padding-top: 5px; font-size: 14px">
                <span style="padding-right: 10px"
                  ><img src="@/assets/img/Icon assets/archive.png" /></span
                >Archive
              </div>
            </div></a
          >
        </li>
        <li v-if="activeActionItems.membership">
          <a
            rel="nofollow"
            href="javascript:void(0);"
            class="dropdown-item d-flex"
            @click.prevent="userMembership(userobj, tblid)"
          >
            <div class="msg-body">
              <div style="padding-top: 5px; font-size: 14px">
                <span style="padding-right: 10px"
                  ><img src="@/assets/img/Icon assets/membership.png" /></span
                >Membership
              </div>
            </div></a
          >
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { EventBus } from "@/event-bus";
export default {
  name: "action-items",
  props: ["activeActionItems", "userobj", "tblid"],
  methods: {
    sendSabaNotification: function (id, isuser, userstatus, mail) {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm
            .$axios({
              url: process.env.VUE_APP_SERVER_API + "api/SabaNotify",
              method: "POST",
              data: {
                id: id,
                isuser: isuser,
                status: userstatus,
                mail: mail,
                forupdate: false,
              },
              headers: {
                token: JSON.parse(localStorage.getItem("LoginResponse"))
                  .accessToken,
              },
            })
            .then((response) => {
              console.log(response);
            });
        });
    },
    editUser: function (userobj, tblid) {
      const _vm = this;
      // eslint-disable-next-line no-undef
      console.log($("#" + tblid).find("tr[data-id='" + userobj.id + "']"));
      _vm.$func.useritemid = userobj.id;
      // eslint-disable-next-line no-undef
      $("#userEdit").modal("show");
    },
    passwordReset: function (userobj, tblid) {
      const _vm = this;
      // eslint-disable-next-line no-undef
      console.log($("#" + tblid).find("tr[data-id='" + userobj.id + "']"));
      _vm.$func
        .sConfirm(
          "Are you sure you want to reset password for " +
            userobj.displayName +
            "?",
          _vm.$func.alerticon.question,
          "Select your option",
          "Yes",
          _vm.$swal
        )
        .then((result) => {
          if (result.isConfirmed) {
            _vm.$func
              .tryGetMsalTokenSilently(
                _vm.$func.msalObject,
                _vm.$func.loginRequest
              )
              .then((tokenresponse) => {
                _vm.$store.state.LoginResponse = tokenresponse;
                localStorage.setItem(
                  "LoginResponse",
                  JSON.stringify(tokenresponse)
                );
                _vm.$axios
                  .patch(
                    _vm.$func.profileGraphApiBaseUrl + "users/" + userobj.id,
                    {
                      passwordProfile: {
                        forceChangePasswordNextSignIn: false,
                        password: _vm.$func.defaultPassword,
                      },
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${
                          JSON.parse(localStorage.getItem("LoginResponse"))
                            .accessToken
                        }`,
                      },
                    }
                  )
                  .then((resp) => {
                    console.log(resp);
                    _vm.$func.sAlert(
                      "Password has been reset successfully for " +
                        userobj.displayName +
                        ".",
                      "success",
                      "",
                      _vm.$swal
                    );
                  })
                  .catch(function (error) {
                    _vm.$func.sAlert(
                      "An error occurred. Please try again after some time",
                      "error",
                      "If error persist, then please contact to administrator",
                      _vm.$swal
                    );
                    console.log(error.response);
                  });
              });
          }
        });
    },
    viewDetails: function (userobj, tblid) {
      const _vm = this;
      // eslint-disable-next-line no-undef
      console.log($("#" + tblid).find("tr[data-id='" + userobj.id + "']"));
      _vm.$func.useritemid = userobj.id;
      // eslint-disable-next-line no-undef
      $("#userDetails").modal("show");
    },
    archiveUser: function (userobj, tblid) {
      const _vm = this;
      // eslint-disable-next-line no-undef
      console.log($("#" + tblid).find("tr[data-id='" + userobj.id + "']"));
      _vm.$func
        .sConfirm(
          "Are you sure want to archive " + userobj.displayName + "?",
          _vm.$func.alerticon.question,
          "Select your option",
          "Yes",
          _vm.$swal
        )
        .then((result) => {
          if (result.isConfirmed) {
            _vm.$func
              .tryGetMsalTokenSilently(
                _vm.$func.msalObject,
                _vm.$func.loginRequest
              )
              .then((tokenresponse) => {
                _vm.$store.state.LoginResponse = tokenresponse;
                localStorage.setItem(
                  "LoginResponse",
                  JSON.stringify(tokenresponse)
                );
                _vm.$axios
                  .patch(
                    _vm.$func.profileGraphApiBaseUrl + "users/" + userobj.id,
                    {
                      displayName: "ZZDel" + userobj.displayName,
                      givenName: "ZZDel" + userobj.givenName,
                      accountEnabled: false,
                      extension_be6dc6c96b4c411780751b4231962926_IsArchive: true,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${
                          JSON.parse(localStorage.getItem("LoginResponse"))
                            .accessToken
                        }`,
                      },
                    }
                  )
                  .then((resp) => {
                    console.log(resp);
                    _vm.$func
                      .sAlert(
                        userobj.displayName +
                          "'s account archived successfully.",
                        "success",
                        "",
                        _vm.$swal
                      )
                      .then((ok) => {
                        if (ok) {
                          userobj.accountEnabled = false;
                          userobj.displayName = "ZZDel" + userobj.displayName;
                          userobj.givenName = "ZZDel" + userobj.givenName;
                          try {
                            _vm.sendSabaNotification(
                              userobj.id,
                              true,
                              _vm.$func.msUserStatus.Deactivation,
                              userobj.mail !== undefined &&
                                userobj.mail !== null
                                ? userobj.mail
                                : userobj.extension_be6dc6c96b4c411780751b4231962926_Email !==
                                    undefined &&
                                  userobj.extension_be6dc6c96b4c411780751b4231962926_Email !==
                                    null
                                ? userobj.extension_be6dc6c96b4c411780751b4231962926_Email
                                : userobj.streetAddress
                            );
                            if (
                              // eslint-disable-next-line no-undef
                              $("#tblAllUsers").find(
                                "tr[data-id='" + userobj.id + "']"
                              ).length > 0
                            ) {
                              EventBus.$emit("advancedsearch-changed", {
                                id: userobj.id,
                                data: userobj,
                                isdeleted: false,
                              });
                            }
                            if (
                              // eslint-disable-next-line no-undef
                              $("#tblActiveUser").find(
                                "tr[data-id='" + userobj.id + "']"
                              ).length > 0
                            ) {
                              EventBus.$emit("activedata-changed", {
                                id: userobj.id,
                                data: userobj,
                                isdeleted: false,
                              });
                            }
                          } catch (ex) {
                            console.error(ex);
                          }
                        }
                      });
                  })
                  .catch(function (error) {
                    _vm.$func.sAlert(
                      "An error occurred. Please try again after some time",
                      "error",
                      "If error persist, then please contact to administrator",
                      _vm.$swal
                    );
                    console.log(error.response);
                  });
              });
          }
        });
    },
    userMembership: function (userobj, tblid) {
      const _vm = this;
      // eslint-disable-next-line no-undef
      console.log($("#" + tblid).find("tr[data-id='" + userobj.id + "']"));
      _vm.$func.useritemid = userobj.id;
      // eslint-disable-next-line no-undef
      $("#userMembership").modal("show");
    },
    deleteUser: function (userobj, tblid) {
      const _vm = this;
      // eslint-disable-next-line no-undef
      console.log($("#" + tblid).find("tr[data-id='" + userobj.id + "']"));
      _vm.$func
        .sConfirm(
          "Are you sure you want to delete " + userobj.displayName + "?",
          _vm.$func.alerticon.question,
          "Select your option",
          "Yes",
          _vm.$swal
        )
        .then((result) => {
          if (result.isConfirmed) {
            _vm.$func
              .tryGetMsalTokenSilently(
                _vm.$func.msalObject,
                _vm.$func.loginRequest
              )
              .then((tokenresponse) => {
                _vm.$store.state.LoginResponse = tokenresponse;
                localStorage.setItem(
                  "LoginResponse",
                  JSON.stringify(tokenresponse)
                );
                _vm.$axios
                  .delete(
                    _vm.$func.profileGraphApiBaseUrl + "users/" + userobj.id,
                    {
                      headers: {
                        Authorization: `Bearer ${
                          JSON.parse(localStorage.getItem("LoginResponse"))
                            .accessToken
                        }`,
                      },
                    }
                  )
                  .then((resp) => {
                    console.log(resp);
                    _vm.$func
                      .sAlert(
                        userobj.displayName + " has been deleted successfully.",
                        "success",
                        "",
                        _vm.$swal
                      )
                      .then((ok) => {
                        if (ok) {
                          try {
                            _vm.sendSabaNotification(
                              userobj.id,
                              true,
                              _vm.$func.msUserStatus.Deactivation,
                              userobj.mail !== undefined &&
                                userobj.mail !== null
                                ? userobj.mail
                                : userobj.extension_be6dc6c96b4c411780751b4231962926_Email !==
                                    undefined &&
                                  userobj.extension_be6dc6c96b4c411780751b4231962926_Email !==
                                    null
                                ? userobj.extension_be6dc6c96b4c411780751b4231962926_Email
                                : userobj.streetAddress
                            );
                            if (
                              // eslint-disable-next-line no-undef
                              $("#tblAllUsers").find(
                                "tr[data-id='" + userobj.id + "']"
                              ).length > 0
                            ) {
                              EventBus.$emit("advancedsearch-changed", {
                                id: userobj.id,
                                data: userobj,
                                isdeleted: true,
                              });
                            }
                            if (
                              // eslint-disable-next-line no-undef
                              $("#tblActiveUser").find(
                                "tr[data-id='" + userobj.id + "']"
                              ).length > 0
                            ) {
                              EventBus.$emit("activedata-changed", {
                                id: userobj.id,
                                data: userobj,
                                isdeleted: true,
                              });
                            }
                            if (
                              // eslint-disable-next-line no-undef
                              $("#tblAwaitingUser").find(
                                "tr[data-id='" + userobj.id + "']"
                              ).length > 0
                            ) {
                              EventBus.$emit("awaitingdata-changed", {
                                id: userobj.id,
                                data: userobj,
                                isdeleted: true,
                              });
                            }
                          } catch (ex) {
                            console.error(ex);
                          }
                        }
                      });
                  })
                  .catch(function (error) {
                    _vm.$func.sAlert(
                      "An error occurred. Please try again after some time",
                      "error",
                      "If error persist, then please contact to administrator",
                      _vm.$swal
                    );
                    console.log(error.response);
                  });
              });
          }
        });
    },
  },
};
</script>

<style scoped>
.action-ul {
  margin: -0.1rem 0 -0.1rem 0 !important;
}
</style>
