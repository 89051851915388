<template>
  <div
    id="userEdit"
    class="modal fade"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Edit User - {{ useritemdetails.displayName }}
          </h4>
          <button type="button" class="close" @click.prevent="closeModal()">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row" style="text-align: left">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="orderheading">User Principal Name</div>
                        <div>
                          <span
                            v-html="
                              $func.apiBreakNewLine(
                                useritemdetails.userPrincipalName,
                                80
                              )
                            "
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Created Date</div>
                        <div>
                          {{
                            useritemdetails.createdDateTime !== undefined &&
                            useritemdetails.createdDateTime !== null
                              ? $func.dateFormatter(
                                  new Date(useritemdetails.createdDateTime)
                                )
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Activation Date</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_ActivationDate !==
                              undefined &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_ActivationDate !==
                              null
                              ? $func.dateFormatter(
                                  new Date(
                                    useritemdetails.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                                  )
                                )
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Birth Date</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Birthdate !==
                              undefined &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Birthdate !==
                              null
                              ? $func.dateFormatter(
                                  new Date(
                                    useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Birthdate
                                  )
                                )
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Email Address</div>
                        <div
                          data-toggle="tooltip"
                          :title="
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Email !==
                              undefined &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Email !==
                              null
                              ? useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Email
                              : useritemdetails.mail !== null
                              ? useritemdetails.mail
                              : $func.validateEmail(
                                  useritemdetails.streetAddress
                                )
                              ? useritemdetails.streetAddress
                              : ''
                          "
                        >
                          <span
                            v-html="
                              $func.apiBreakNewLine(
                                useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Email !==
                                  undefined &&
                                  useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Email !==
                                    null
                                  ? useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Email
                                  : useritemdetails.mail !== null
                                  ? useritemdetails.mail
                                  : $func.validateEmail(
                                      useritemdetails.streetAddress
                                    )
                                  ? useritemdetails.streetAddress
                                  : '',
                                28
                              )
                            "
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Given Name*</div>
                        <input
                          :class="
                            'form-control' +
                            (useritemdetails.givenName !== undefined &&
                            useritemdetails.givenName !== null &&
                            useritemdetails.givenName.trim() !== ''
                              ? ''
                              : ' is-invalid')
                          "
                          type="text"
                          v-model="useritemdetails.givenName"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Surname*</div>
                        <input
                          :class="
                            'form-control' +
                            (useritemdetails.surname !== undefined &&
                            useritemdetails.surname !== null &&
                            useritemdetails.surname.trim() !== ''
                              ? ''
                              : ' is-invalid')
                          "
                          type="text"
                          v-model="useritemdetails.surname"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Country*</div>
                        <select
                          :class="
                            'form-control' +
                            (selectedCountry !== undefined &&
                            selectedCountry !== null &&
                            selectedCountry.trim() !== '' &&
                            selectedCountry !== '0' &&
                            selectedCountry.trim() !== 'NA_NULL'
                              ? ''
                              : ' is-invalid')
                          "
                          v-model="selectedCountry"
                        >
                          <option value="0">Select</option>
                          <template v-for="countryitem in this.$func.country">
                            <option
                              :key="countryitem"
                              :value="countryitem"
                              v-if="countryitem === useritemdetails.country"
                              selected="selected"
                            >
                              {{ countryitem }}
                            </option>
                            <option
                              :key="countryitem"
                              :value="countryitem"
                              v-else
                            >
                              {{ countryitem }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">State</div>
                        <select class="form-control" v-model="selectedState">
                          <option value="0">Select</option>
                          <template v-for="stateitemitem in stateList">
                            <option
                              :key="stateitemitem"
                              :value="stateitemitem"
                              v-if="stateitemitem === useritemdetails.state"
                              selected="selected"
                            >
                              {{ stateitemitem }}
                            </option>
                            <option
                              :key="stateitemitem"
                              :value="stateitemitem"
                              v-else
                            >
                              {{ stateitemitem }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Commercial Area</div>
                        <select
                          class="form-control"
                          v-model="
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Area
                          "
                        >
                          <option value="0">Select</option>
                          <template v-for="commareaitem in commAreaList">
                            <option
                              :key="commareaitem"
                              :value="commareaitem"
                              v-if="
                                commareaitem ===
                                useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Area
                              "
                              selected="selected"
                            >
                              {{ commareaitem }}
                            </option>
                            <option
                              :key="commareaitem"
                              :value="commareaitem"
                              v-else
                            >
                              {{ commareaitem }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Success Factor ID</div>
                        <div>
                          {{
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Success Factor Role ID*</div>
                        <select
                          :class="
                            'form-control' +
                            (selectedSuccessFactorRoleId !== undefined &&
                            selectedSuccessFactorRoleId !== null &&
                            selectedSuccessFactorRoleId.trim() !== '' &&
                            selectedSuccessFactorRoleId !== '0' &&
                            selectedSuccessFactorRoleId.trim() !== 'NA_NULL'
                              ? ''
                              : ' is-invalid')
                          "
                          v-model="selectedSuccessFactorRoleId"
                        >
                          <option value="0">Select</option>
                          <template
                            v-for="successfactorroleiditem in Object.keys(
                              this.$store.state.employers
                            )"
                          >
                            <option
                              :key="successfactorroleiditem"
                              :value="successfactorroleiditem"
                              v-if="
                                successfactorroleiditem ===
                                useritemdetails.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                              "
                              selected="selected"
                            >
                              {{ successfactorroleiditem }}
                            </option>
                            <option
                              :key="successfactorroleiditem"
                              :value="successfactorroleiditem"
                              v-else
                            >
                              {{ successfactorroleiditem }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Employer*</div>
                        <input
                          :class="
                            'form-control' +
                            (useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer !==
                              undefined &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer !==
                              null &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer.trim() !==
                              '' &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer !==
                              '0' &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer.trim() !==
                              'NA_NULL'
                              ? ''
                              : ' is-invalid')
                          "
                          v-if="
                            selectedSuccessFactorRoleId ===
                              Object.getOwnPropertyNames(
                                $store.state.employers
                              )[2] ||
                            selectedCountry
                              .trim()
                              .toLowerCase()
                              .startsWith('germany')
                          "
                          type="text"
                          v-model="computedEmployer"
                        />
                        <select
                          v-else
                          :class="
                            'form-control' +
                            (useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer !==
                              undefined &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer !==
                              null &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer.trim() !==
                              '' &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer !==
                              '0' &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer.trim() !==
                              'NA_NULL'
                              ? ''
                              : ' is-invalid')
                          "
                          v-model="
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer
                          "
                        >
                          <option value="0">Select</option>
                          <template v-for="employeritem in employerList">
                            <option
                              :key="employeritem"
                              :value="employeritem"
                              v-if="
                                employeritem ===
                                useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer
                              "
                              selected="selected"
                            >
                              {{ employeritem }}
                            </option>
                            <option
                              :key="employeritem"
                              :value="employeritem"
                              v-else
                            >
                              {{ employeritem }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Beam Suntory Sponsor</div>
                        <input
                          class="form-control"
                          type="text"
                          v-model="
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor
                          "
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">On/Of Premises*</div>
                        <select
                          v-model="
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_OnOffPremise
                          "
                          :class="
                            'form-control' +
                            (useritemdetails.extension_be6dc6c96b4c411780751b4231962926_OnOffPremise !==
                              undefined &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_OnOffPremise !==
                              null &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_OnOffPremise.trim() !==
                              '' &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_OnOffPremise !==
                              '0' &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_OnOffPremise.trim() !==
                              'NA_NULL'
                              ? ''
                              : ' is-invalid')
                          "
                        >
                          <option value="0">Select</option>
                          <template
                            v-for="permiseitem in this.$func.onOffPermise"
                          >
                            <option
                              :key="permiseitem"
                              :value="permiseitem"
                              v-if="
                                permiseitem ===
                                useritemdetails.extension_be6dc6c96b4c411780751b4231962926_OnOffPremise
                              "
                              selected="selected"
                            >
                              {{ permiseitem }}
                            </option>
                            <option
                              :key="permiseitem"
                              :value="permiseitem"
                              v-else
                            >
                              {{ permiseitem }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Region*</div>
                        <select
                          :class="
                            'form-control' +
                            (useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Region !==
                              undefined &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Region !==
                              null &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Region.trim() !==
                              '' &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Region !==
                              '0' &&
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Region.trim() !==
                              'NA_NULL'
                              ? ''
                              : ' is-invalid')
                          "
                          v-model="
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Region
                          "
                        >
                          <option value="0">Select</option>
                          <template v-for="regionitem in this.$func.region">
                            <option
                              :key="regionitem"
                              :value="regionitem"
                              v-if="
                                regionitem ===
                                useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Region
                              "
                              selected="selected"
                            >
                              {{ regionitem }}
                            </option>
                            <option
                              :key="regionitem"
                              :value="regionitem"
                              v-else
                            >
                              {{ regionitem }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Commercial Region</div>
                        <div>
                          <select
                            class="form-control"
                            v-model="selectedCommRegion"
                          >
                            <option value="0">Select</option>
                            <template
                              v-for="commregionitem in this.$func.commRegion"
                            >
                              <option
                                :key="commregionitem"
                                :value="commregionitem"
                                v-if="
                                  commregionitem ===
                                  useritemdetails.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                                "
                                selected="selected"
                              >
                                {{ commregionitem }}
                              </option>
                              <option
                                :key="commregionitem"
                                :value="commregionitem"
                                v-else
                              >
                                {{ commregionitem }}
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">Commercial Division</div>
                        <select
                          class="form-control"
                          v-model="
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Division
                          "
                        >
                          <option value="0">Select</option>
                          <template
                            v-for="commdivisionitem in commDivisionList"
                          >
                            <option
                              :key="commdivisionitem"
                              :value="commdivisionitem"
                              v-if="
                                commdivisionitem ===
                                useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Division
                              "
                              selected="selected"
                            >
                              {{ commdivisionitem }}
                            </option>
                            <option
                              :key="commdivisionitem"
                              :value="commdivisionitem"
                              v-else
                            >
                              {{ commdivisionitem }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <div class="orderheading">BSI Brand</div>
                        <input
                          class="form-control"
                          type="text"
                          v-model="
                            useritemdetails.extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-primary"
            @click.prevent="validateFields"
          >
            Save
          </button>
          <button
            class="btn btn-outline-secondary"
            @click.prevent="closeModal()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
export default {
  name: "user-edit",
  data() {
    return {
      selectedSuccessFactorRoleId: "0",
      selectedCountry: "0",
      selectedState: "0",
      selectedCommRegion: "0",
      employerList: [],
      stateList: [],
      commAreaList: [],
      commDivisionList: [],
      useritemdetails: {
        id: null,
        accountEnabled: false,
        displayName: null,
        givenName: null,
        jobTitle: null,
        birthday: null,
        mail: null,
        mobilePhone: null,
        streetAddress: null,
        state: null,
        country: null,
        officeLocation: null,
        city: null,
        postalCode: null,
        preferredLanguage: null,
        surname: null,
        companyName: null,
        extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
        extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
        extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
        extension_be6dc6c96b4c411780751b4231962926_Email: null,
        extension_be6dc6c96b4c411780751b4231962926_Employer: null,
        extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
        extension_be6dc6c96b4c411780751b4231962926_Region: null,
        extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
        extension_be6dc6c96b4c411780751b4231962926_Division: null,
        extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
        extension_be6dc6c96b4c411780751b4231962926_Area: null,
        extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand: null,
        userPrincipalName: null,
        createdDateTime: null,
        extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
        extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
        extension_be6dc6c96b4c411780751b4231962926_IsArchive: null,
      },
    };
  },
  computed: {
    computedEmployer: {
      get: function () {
        if (
          this.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_Employer === "0"
        ) {
          return null;
        } else {
          return this.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_Employer;
        }
      },
      set: function (newValue) {
        if (
          newValue !== undefined &&
          newValue !== null &&
          newValue.trim() !== "" &&
          newValue.trim() !== "0"
        ) {
          this.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer =
            newValue;
        } else {
          this.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer =
            "0";
        }
      },
    },
  },
  mounted() {
    const _vm = this;
    // eslint-disable-next-line no-undef
    $(document)
      .off("shown.bs.modal", "#userEdit")
      .on("shown.bs.modal", "#userEdit", function () {
        _vm.fetchUserById(_vm.$func.useritemid);
      });
  },
  watch: {
    selectedSuccessFactorRoleId: function () {
      const _vm = this;
      _vm.employerList = [];
      if (_vm.selectedSuccessFactorRoleId !== "0") {
        _vm.employerList =
          _vm.$store.state.employers[_vm.selectedSuccessFactorRoleId];
      }
      if (_vm.employerList === undefined || _vm.employerList === null) {
        _vm.employerList = [];
      }
      if (
        _vm.selectedSuccessFactorRoleId ===
        Object.getOwnPropertyNames(_vm.$store.state.employers)[2]
      ) {
        _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer =
          _vm.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_Employer !==
            undefined &&
          _vm.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_Employer !== null &&
          _vm.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_Employer !== "0"
            ? _vm.useritemdetails
                .extension_be6dc6c96b4c411780751b4231962926_Employer
            : null;
      } else {
        _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer =
          _vm.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_Employer !==
            undefined &&
          _vm.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_Employer !== null &&
          _vm.$func.itemExistsInArray(
            _vm.employerList,
            _vm.useritemdetails
              .extension_be6dc6c96b4c411780751b4231962926_Employer
          )
            ? _vm.useritemdetails
                .extension_be6dc6c96b4c411780751b4231962926_Employer
            : "0";
      }
    },
    selectedCountry: function () {
      const _vm = this;
      _vm.stateList = [];
      if (_vm.selectedCountry !== "0") {
        _vm.stateList = _vm.$func.states[_vm.selectedCountry];
      }
      if (_vm.stateList === undefined || _vm.stateList === null) {
        _vm.stateList = [];
      }
      _vm.selectedState =
        _vm.useritemdetails.state !== null &&
        _vm.$func.itemExistsInArray(_vm.stateList, _vm.useritemdetails.state)
          ? _vm.useritemdetails.state
          : "0";
    },
    selectedState: function () {
      const _vm = this;
      _vm.commAreaList = [];
      if (_vm.selectedState !== "0") {
        _vm.commAreaList = _vm.$func.areas[_vm.selectedState];
      }
      if (_vm.commAreaList === undefined || _vm.commAreaList === null) {
        _vm.commAreaList = [];
      }
      _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Area =
        _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Area !==
          null &&
        _vm.$func.itemExistsInArray(
          _vm.commAreaList,
          _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Area
        )
          ? _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Area
          : "0";
    },
    selectedCommRegion: function () {
      const _vm = this;
      _vm.commDivisionList = [];
      if (_vm.selectedCommRegion !== "0") {
        _vm.commDivisionList = _vm.$func.divisions[_vm.selectedCommRegion];
      }
      if (_vm.commDivisionList === undefined || _vm.commDivisionList === null) {
        _vm.commDivisionList = [];
      }
      _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Division =
        _vm.useritemdetails
          .extension_be6dc6c96b4c411780751b4231962926_Division !== null &&
        _vm.$func.itemExistsInArray(
          _vm.commDivisionList,
          _vm.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_Division
        )
          ? _vm.useritemdetails
              .extension_be6dc6c96b4c411780751b4231962926_Division
          : "0";
    },
  },
  methods: {
    closeModal: function () {
      const _vm = this;
      _vm.selectedSuccessFactorRoleId = "0";
      _vm.selectedCountry = "0";
      _vm.selectedState = "0";
      _vm.selectedCommRegion = "0";
      _vm.employerList = [];
      _vm.stateList = [];
      _vm.commAreaList = [];
      _vm.commDivisionList = [];
      _vm.useritemdetails = {
        id: null,
        accountEnabled: false,
        displayName: null,
        givenName: null,
        jobTitle: null,
        birthday: null,
        mail: null,
        mobilePhone: null,
        streetAddress: null,
        state: null,
        country: null,
        officeLocation: null,
        city: null,
        postalCode: null,
        preferredLanguage: null,
        surname: null,
        companyName: null,
        extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
        extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
        extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
        extension_be6dc6c96b4c411780751b4231962926_Email: null,
        extension_be6dc6c96b4c411780751b4231962926_Employer: null,
        extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
        extension_be6dc6c96b4c411780751b4231962926_Region: null,
        extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
        extension_be6dc6c96b4c411780751b4231962926_Division: null,
        extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
        extension_be6dc6c96b4c411780751b4231962926_Area: null,
        extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand: null,
        userPrincipalName: null,
        createdDateTime: null,
        extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
        extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
        extension_be6dc6c96b4c411780751b4231962926_IsArchive: null,
      };
      _vm.$func.useritemid = null;
      // eslint-disable-next-line no-undef
      $("#userEdit").modal("hide");
    },
    validateFields: function () {
      const _vm = this;
      if (
        _vm.useritemdetails.givenName === undefined ||
        _vm.useritemdetails.givenName === null ||
        _vm.useritemdetails.givenName.trim() === "" ||
        _vm.useritemdetails.surname === undefined ||
        _vm.useritemdetails.surname === null ||
        _vm.useritemdetails.surname.trim() === "" ||
        _vm.selectedCountry === undefined ||
        _vm.selectedCountry === null ||
        _vm.selectedCountry.trim() === "" ||
        _vm.selectedCountry === "0" ||
        _vm.selectedCountry.trim() === "NA_NULL" ||
        _vm.selectedSuccessFactorRoleId === undefined ||
        _vm.selectedSuccessFactorRoleId === null ||
        _vm.selectedSuccessFactorRoleId.trim() === "" ||
        _vm.selectedSuccessFactorRoleId === "0" ||
        _vm.selectedSuccessFactorRoleId.trim() === "NA_NULL" ||
        _vm.useritemdetails
          .extension_be6dc6c96b4c411780751b4231962926_Employer === undefined ||
        _vm.useritemdetails
          .extension_be6dc6c96b4c411780751b4231962926_Employer === null ||
        _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer.trim() ===
          "" ||
        _vm.useritemdetails
          .extension_be6dc6c96b4c411780751b4231962926_Employer === "0" ||
        _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer.trim() ===
          "NA_NULL" ||
        _vm.useritemdetails
          .extension_be6dc6c96b4c411780751b4231962926_OnOffPremise ===
          undefined ||
        _vm.useritemdetails
          .extension_be6dc6c96b4c411780751b4231962926_OnOffPremise === null ||
        _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_OnOffPremise.trim() ===
          "" ||
        _vm.useritemdetails
          .extension_be6dc6c96b4c411780751b4231962926_OnOffPremise === "0" ||
        _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_OnOffPremise.trim() ===
          "NA_NULL" ||
        _vm.useritemdetails
          .extension_be6dc6c96b4c411780751b4231962926_Region === undefined ||
        _vm.useritemdetails
          .extension_be6dc6c96b4c411780751b4231962926_Region === null ||
        _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Region.trim() ===
          "" ||
        _vm.useritemdetails
          .extension_be6dc6c96b4c411780751b4231962926_Region === "0" ||
        _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Region.trim() ===
          "NA_NULL"
      ) {
        _vm.$func.sAlert(
          "Please complete all mandatory field(s) before saving",
          "error",
          "Star (*) marked field(S) is/are mandatory field(s). Please check with the red marked field(s), and please complete it before saving.",
          _vm.$swal
        );
      } else {
        _vm.saveUserDetails();
      }
    },
    saveUserDetails: function () {
      const _vm = this;
      let userdata = {
        givenName: _vm.useritemdetails.givenName,
        surname: _vm.useritemdetails.surname,
        displayName:
          _vm.useritemdetails.givenName + " " + _vm.useritemdetails.surname,
        accountEnabled: _vm.useritemdetails.accountEnabled,
        country: _vm.selectedCountry === "0" ? null : _vm.selectedCountry,
        state: _vm.selectedState === "0" ? null : _vm.selectedState,
        extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID:
          _vm.selectedSuccessFactorRoleId === "0"
            ? null
            : _vm.selectedSuccessFactorRoleId,
        extension_be6dc6c96b4c411780751b4231962926_OnOffPremise:
          _vm.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_OnOffPremise === "0"
            ? null
            : _vm.useritemdetails
                .extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,
        extension_be6dc6c96b4c411780751b4231962926_Employer:
          _vm.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_Employer === "0" &&
          _vm.selectedSuccessFactorRoleId ===
            Object.getOwnPropertyNames(_vm.$store.state.employers)[2]
            ? null
            : _vm.useritemdetails
                .extension_be6dc6c96b4c411780751b4231962926_Employer,
        extension_be6dc6c96b4c411780751b4231962926_Region:
          _vm.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_Region === "0"
            ? null
            : _vm.useritemdetails
                .extension_be6dc6c96b4c411780751b4231962926_Region,
        extension_be6dc6c96b4c411780751b4231962926_CommercialRegion:
          _vm.selectedCommRegion === "0" ? null : _vm.selectedCommRegion,
        extension_be6dc6c96b4c411780751b4231962926_Division:
          _vm.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_Division === "0"
            ? null
            : _vm.useritemdetails
                .extension_be6dc6c96b4c411780751b4231962926_Division,
        extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand:
          _vm.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand ===
          undefined
            ? null
            : _vm.useritemdetails
                .extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand,
        extension_be6dc6c96b4c411780751b4231962926_Area:
          _vm.useritemdetails
            .extension_be6dc6c96b4c411780751b4231962926_Area === "0"
            ? null
            : _vm.useritemdetails
                .extension_be6dc6c96b4c411780751b4231962926_Area,
        extension_be6dc6c96b4c411780751b4231962926_IsArchive:
          _vm.useritemdetails.givenName.trim().toLowerCase().startsWith("zz")
            ? true
            : false,
      };
      _vm.$func
        .sConfirm(
          "Are you sure you want to save " +
            _vm.useritemdetails.displayName +
            " details?",
          _vm.$func.alerticon.question,
          "Select your option",
          "Yes",
          _vm.$swal
        )
        .then((result) => {
          if (result.isConfirmed) {
            _vm.$func
              .tryGetMsalTokenSilently(
                _vm.$func.msalObject,
                _vm.$func.loginRequest
              )
              .then((tokenresponse) => {
                _vm.$store.state.LoginResponse = tokenresponse;
                localStorage.setItem(
                  "LoginResponse",
                  JSON.stringify(tokenresponse)
                );
                _vm.$axios
                  .patch(
                    _vm.$func.profileGraphApiBaseUrl +
                      "users/" +
                      _vm.useritemdetails.id,
                    userdata,
                    {
                      headers: {
                        Authorization: `Bearer ${
                          JSON.parse(localStorage.getItem("LoginResponse"))
                            .accessToken
                        }`,
                      },
                    }
                  )
                  .then((resp) => {
                    console.log(resp);
                    _vm.$func
                      .sAlert(
                        _vm.useritemdetails.displayName +
                          "'s details have been saved successfully.",
                        "success",
                        "",
                        _vm.$swal
                      )
                      .then((ok) => {
                        if (ok) {
                          _vm.domUpdateGridAfterSave(
                            userdata,
                            _vm.useritemdetails.id
                          );
                          _vm.closeModal();
                        }
                      });
                  })
                  .catch(function (error) {
                    _vm.$func.sAlert(
                      "An error occurred. Please try again after some time",
                      "error",
                      "If error persist, then please contact to administrator",
                      _vm.$swal
                    );
                    console.log(error.response);
                  });
              });
          }
        });
    },
    sendSabaNotificationOnUpdate: function (id, userstatus, mail, forupdate) {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm
            .$axios({
              url: process.env.VUE_APP_SERVER_API + "api/SabaNotify",
              method: "POST",
              data: {
                id: id,
                isuser: true,
                status: userstatus,
                mail: mail,
                forupdate: forupdate,
              },
              headers: {
                token: JSON.parse(localStorage.getItem("LoginResponse"))
                  .accessToken,
              },
            })
            .then((response) => {
              console.log(response);
            });
        });
    },
    domUpdateGridAfterSave: function (userdata, id) {
      const _vm = this;
      try {
        _vm.sendSabaNotificationOnUpdate(
          id,
          userdata.givenName.trim().toLowerCase().startsWith("zz")
            ? _vm.$func.msUserStatus.Deactivation
            : _vm.$func.msUserStatus.Activation,
          _vm.useritemdetails.mail !== undefined &&
            _vm.useritemdetails.mail !== null
            ? _vm.useritemdetails.mail
            : _vm.useritemdetails
                .extension_be6dc6c96b4c411780751b4231962926_Email !==
                undefined &&
              _vm.useritemdetails
                .extension_be6dc6c96b4c411780751b4231962926_Email !== null
            ? _vm.useritemdetails
                .extension_be6dc6c96b4c411780751b4231962926_Email
            : _vm.useritemdetails.streetAddress,
          userdata.givenName.trim().toLowerCase().startsWith("zz")
            ? false
            : true
        );
      } catch (excp) {
        console.error(excp);
      }
      try {
        if (
          // eslint-disable-next-line no-undef
          $("#tblAllUsers").find("tr[data-id='" + id + "']").length > 0
        ) {
          EventBus.$emit("advancedsearch-changed", {
            id: id,
            data: userdata,
            isdeleted: false,
          });
        }
        if (userdata.accountEnabled) {
          // eslint-disable-next-line no-undef
          if ($("#tblActiveUser").find("tr[data-id='" + id + "']").length > 0) {
            EventBus.$emit("activedata-changed", {
              id: id,
              data: userdata,
              isdeleted: false,
            });
          }
        } else {
          if (
            // eslint-disable-next-line no-undef
            $("#tblAwaitingUser").find("tr[data-id='" + id + "']").length > 0
          ) {
            EventBus.$emit("awaitingdata-changed", {
              id: id,
              data: userdata,
              isdeleted: false,
            });
          }
          if (
            // eslint-disable-next-line no-undef
            $("#tblArchivedUser").find("tr[data-id='" + id + "']").length > 0
          ) {
            EventBus.$emit("archiveddata-changed", {
              id: id,
              data: userdata,
              isdeleted: false,
            });
          }
        }
      } catch (exc) {
        console.error(exc);
      }
    },
    fetchUserById: function (id) {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.$axios
            .get(
              _vm.$func.profileGraphApiBaseUrl +
                "users/" +
                id +
                "?" +
                "$select=id,accountEnabled,createdDateTime,givenName,surname,displayName,mail,mobilePhone,streetAddress,state,country,city,mobilePhone,preferredLanguage,userPrincipalName,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID,extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,extension_be6dc6c96b4c411780751b4231962926_Birthdate,extension_be6dc6c96b4c411780751b4231962926_Email,extension_be6dc6c96b4c411780751b4231962926_Employer,extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,extension_be6dc6c96b4c411780751b4231962926_Region,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID,extension_be6dc6c96b4c411780751b4231962926_Division,extension_be6dc6c96b4c411780751b4231962926_CommercialRegion,extension_be6dc6c96b4c411780751b4231962926_Area,extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand,extension_be6dc6c96b4c411780751b4231962926_ActivationDate,extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated,extension_be6dc6c96b4c411780751b4231962926_IsArchive",
              {
                headers: {
                  Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("LoginResponse"))
                      .accessToken
                  }`,
                },
              }
            )
            .then((response) => {
              if (response.data !== undefined && response.data !== null) {
                for (var key of Object.keys(response.data)) {
                  if (response.data[key] === "NA_NULL") {
                    response.data[key] = null;
                  }
                }
              }
              _vm.$nextTick(() => {
                try {
                  _vm.useritemdetails = response.data;
                  _vm.selectedCountry =
                    _vm.useritemdetails.country !== undefined &&
                    _vm.useritemdetails.country !== null &&
                    _vm.useritemdetails.country.length > 0 &&
                    _vm.$func.itemExistsInArray(
                      _vm.$func.country,
                      _vm.useritemdetails.country
                    )
                      ? _vm.useritemdetails.country
                      : "0";
                  _vm.selectedState =
                    _vm.useritemdetails.state !== undefined &&
                    _vm.useritemdetails.state !== null &&
                    _vm.useritemdetails.state.length > 0 &&
                    _vm.$func.itemExistsInArray(
                      _vm.stateList,
                      _vm.useritemdetails.state
                    )
                      ? _vm.useritemdetails.state
                      : "0";
                  _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Area =
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Area !==
                      undefined &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Area !==
                      null &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Area.length >
                      0
                      ? _vm.useritemdetails
                          .extension_be6dc6c96b4c411780751b4231962926_Area
                      : "0";
                  _vm.selectedSuccessFactorRoleId =
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID !==
                      undefined &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID !==
                      null &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                      .length > 0
                      ? _vm.useritemdetails
                          .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                      : "0";
                  _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Employer =
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Employer !==
                      undefined &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Employer !==
                      null &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Employer
                      .length > 0
                      ? _vm.useritemdetails
                          .extension_be6dc6c96b4c411780751b4231962926_Employer
                      : _vm.selectedSuccessFactorRoleId ===
                        Object.getOwnPropertyNames(
                          _vm.$store.state.employers
                        )[2]
                      ? null
                      : "0";
                  _vm.selectedCommRegion =
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion !==
                      undefined &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion !==
                      null &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                      .length > 0
                      ? _vm.useritemdetails
                          .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                      : "0";
                  _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Birthdate =
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Birthdate ===
                    undefined
                      ? null
                      : _vm.useritemdetails
                          .extension_be6dc6c96b4c411780751b4231962926_Birthdate;
                  _vm.useritemdetails
                    .extension_be6dc6c96b4c411780751b4231962926_ActivationDate ===
                  undefined
                    ? null
                    : _vm.useritemdetails
                        .extension_be6dc6c96b4c411780751b4231962926_ActivationDate;
                  _vm.useritemdetails
                    .extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated ===
                  undefined
                    ? null
                    : _vm.useritemdetails
                        .extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated;
                  _vm.useritemdetails
                    .extension_be6dc6c96b4c411780751b4231962926_IsArchive ===
                  undefined
                    ? null
                    : _vm.useritemdetails
                        .extension_be6dc6c96b4c411780751b4231962926_IsArchive;
                  _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_OnOffPremise =
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_OnOffPremise !==
                      undefined &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_OnOffPremise !==
                      null
                      ? _vm.useritemdetails
                          .extension_be6dc6c96b4c411780751b4231962926_OnOffPremise
                      : "0";
                  _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Region =
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Region !==
                      undefined &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Region !==
                      null
                      ? _vm.useritemdetails
                          .extension_be6dc6c96b4c411780751b4231962926_Region
                      : "0";
                  _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_Division =
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Division !==
                      undefined &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Division !==
                      null &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_Division
                      .length > 0
                      ? _vm.useritemdetails
                          .extension_be6dc6c96b4c411780751b4231962926_Division
                      : "0";
                  _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand =
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand !==
                      undefined &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand !==
                      null &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand
                      .length > 0
                      ? _vm.useritemdetails
                          .extension_be6dc6c96b4c411780751b4231962926_BeamSuntoryBrand
                      : null;
                  _vm.useritemdetails.extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor =
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor !==
                      undefined &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor !==
                      null &&
                    _vm.useritemdetails
                      .extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor
                      .length > 0
                      ? _vm.useritemdetails
                          .extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor
                      : null;
                } catch (ex) {
                  console.error(ex);
                }
              });
            })
            .catch(function (error) {
              _vm.$func.sAlert(
                "An error occurred. Please try again after some time",
                "error",
                "If error persist, then please contact to administrator",
                _vm.$swal
              );
              console.log(error.response);
            })
            .finally(function () {
              // eslint-disable-next-line no-undef
              $('[data-toggle="tooltip"]').tooltip();
            });
        });
    },
  },
};
</script>

<style scoped>
.orderheading {
  font-weight: 600;
  color: #9d1c31;
}
</style>
