var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row tblrow"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-header d-flex align-items-center"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],staticClass:"form-control rounded",staticStyle:{"height":"38px"},attrs:{"type":"search","maxlength":"100","placeholder":"","aria-label":"Search","aria-describedby":"search-addon"},domProps:{"value":(_vm.searchText)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.searchArchivedUser.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.searchText=$event.target.value}}}),_c('button',{staticClass:"btn btn-outline-primary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.searchArchivedUser.apply(null, arguments)}}},[_vm._v(" Search ")])])]),_vm._m(1),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"pull-right"},[_c('button',{class:_vm.previousUrl === null
                    ? 'btn btn-outline-secondary'
                    : 'btn btn-outline-primary',attrs:{"type":"button","disabled":_vm.previousUrl === null},on:{"click":function($event){$event.preventDefault();return _vm.fetchArchivedUsers(_vm.previousUrl, true)}}},[_c('i',{staticClass:"fa fa-angle-double-left"}),_vm._v(" Previous ")]),_vm._v("   "),_c('button',{class:_vm.nextUrl === null
                    ? 'btn btn-outline-secondary'
                    : 'btn btn-outline-primary',attrs:{"type":"button","disabled":_vm.nextUrl === null},on:{"click":function($event){$event.preventDefault();return _vm.fetchArchivedUsers(_vm.nextUrl, false)}}},[_vm._v(" Next "),_c('i',{staticClass:"fa fa-angle-double-right"})])])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive",staticStyle:{"max-height":"450px","overflow":"auto"},attrs:{"id":"dvArchivedUser"}},[_c('table',{staticClass:"table table-striped table-bordered table-hover table-condensed table-fixed",attrs:{"id":"tblArchivedUser"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"beam_tableheading"},[_vm._v(" Email Add. "),_c('i',{class:'fa fa-sort-amount-' + _vm.archivedSortObj.mail.order,staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.archivedSortByField(
                          _vm.archivedSortObj.mail.field,
                          _vm.archivedSortObj.mail.order
                        )}}})]),_c('th',{staticClass:"beam_tableheading"},[_vm._v(" Display name "),_c('i',{class:'fa fa-sort-amount-' +
                        _vm.archivedSortObj.displayName.order,staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.archivedSortByField(
                          _vm.archivedSortObj.displayName.field,
                          _vm.archivedSortObj.displayName.order
                        )}}})]),_c('th',{staticClass:"beam_tableheading"},[_vm._v(" Country "),_c('i',{class:'fa fa-sort-amount-' + _vm.archivedSortObj.country.order,staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.archivedSortByField(
                          _vm.archivedSortObj.country.field,
                          _vm.archivedSortObj.country.order
                        )}}})]),_c('th',{staticClass:"beam_tableheading"},[_vm._v(" State "),_c('i',{class:'fa fa-sort-amount-' + _vm.archivedSortObj.state.order,staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.archivedSortByField(
                          _vm.archivedSortObj.state.field,
                          _vm.archivedSortObj.state.order
                        )}}})]),_c('th',{staticClass:"beam_tableheading"},[_vm._v(" Employer "),_c('i',{class:'fa fa-sort-amount-' +
                        _vm.archivedSortObj
                          .extension_be6dc6c96b4c411780751b4231962926_Employer
                          .order,staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.archivedSortByField(
                          _vm.archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Employer
                            .field,
                          _vm.archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Employer
                            .order
                        )}}})]),_c('th',{staticClass:"beam_tableheading"},[_vm._v(" SF Role Id "),_c('i',{class:'fa fa-sort-amount-' +
                        _vm.archivedSortObj
                          .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                          .order,staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.archivedSortByField(
                          _vm.archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                            .field,
                          _vm.archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                            .order
                        )}}})]),_c('th',{staticClass:"beam_tableheading"},[_vm._v(" Com. Region "),_c('i',{class:'fa fa-sort-amount-' +
                        _vm.archivedSortObj
                          .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                          .order,staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.archivedSortByField(
                          _vm.archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                            .field,
                          _vm.archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                            .order
                        )}}})]),_c('th',{staticClass:"beam_tableheading"},[_vm._v(" Region "),_c('i',{class:'fa fa-sort-amount-' +
                        _vm.archivedSortObj
                          .extension_be6dc6c96b4c411780751b4231962926_Region
                          .order,staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.archivedSortByField(
                          _vm.archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Region
                            .field,
                          _vm.archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Region
                            .order
                        )}}})]),_c('th',{staticClass:"beam_tableheading"},[_vm._v(" Com. division "),_c('i',{class:'fa fa-sort-amount-' +
                        _vm.archivedSortObj
                          .extension_be6dc6c96b4c411780751b4231962926_Division
                          .order,staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.archivedSortByField(
                          _vm.archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Division
                            .field,
                          _vm.archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Division
                            .order
                        )}}})]),_c('th',{staticClass:"beam_tableheading"},[_vm._v(" Activation Date "),_c('i',{class:'fa fa-sort-amount-' +
                        _vm.archivedSortObj
                          .extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                          .order,staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.archivedSortByField(
                          _vm.archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                            .field,
                          _vm.archivedSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                            .order
                        )}}})]),_c('th',{staticClass:"beam_tableheading"},[_vm._v("Actions")])])]),(
                  _vm.archivedusers.length > 0 && _vm.archivedusers[0].id !== null
                )?_c('tbody',_vm._l((_vm.archivedusers),function(item){return _c('tr',{key:item.id,attrs:{"data-id":item.id}},[_c('td',[_c('span',{attrs:{"data-toggle":"tooltip","title":item.mail},domProps:{"innerHTML":_vm._s(_vm.$func.apiBreakNewLine(item.mail, 35))}})]),_c('td',[_c('span',{attrs:{"data-toggle":"tooltip","title":item.displayName}},[_vm._v(_vm._s(_vm.$func.apiShortenString(item.displayName)))])]),_c('td',[_c('span',{attrs:{"data-toggle":"tooltip","title":item.country}},[_vm._v(_vm._s(_vm.$func.apiShortenString(item.country)))])]),_c('td',[_c('span',{attrs:{"data-toggle":"tooltip","title":item.state}},[_vm._v(_vm._s(_vm.$func.apiShortenString(item.state)))])]),_c('td',[_c('span',{attrs:{"data-toggle":"tooltip","title":item.extension_be6dc6c96b4c411780751b4231962926_Employer}},[_vm._v(_vm._s(_vm.$func.apiShortenString( item.extension_be6dc6c96b4c411780751b4231962926_Employer )))])]),_c('td',[_c('span',{attrs:{"data-toggle":"tooltip","title":item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID}},[_vm._v(_vm._s(_vm.$func.apiShortenString( item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID )))])]),_c('td',[_c('span',{attrs:{"data-toggle":"tooltip","title":item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion}},[_vm._v(_vm._s(_vm.$func.apiShortenString( item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion )))])]),_c('td',[_c('span',{attrs:{"data-toggle":"tooltip","title":item.extension_be6dc6c96b4c411780751b4231962926_Region}},[_vm._v(_vm._s(_vm.$func.apiShortenString( item.extension_be6dc6c96b4c411780751b4231962926_Region )))])]),_c('td',[_c('span',{attrs:{"data-toggle":"tooltip","title":item.extension_be6dc6c96b4c411780751b4231962926_Division}},[_vm._v(_vm._s(_vm.$func.apiShortenString( item.extension_be6dc6c96b4c411780751b4231962926_Division )))])]),_c('td',[(
                        item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate !==
                          undefined &&
                        item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate !==
                          null &&
                        item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate.trim() !==
                          'NA_NULL' &&
                        new Date(
                          item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                        ) !== undefined
                      )?_c('span',{attrs:{"data-toggle":"tooltip","title":_vm.$func.dateFormatter(
                          new Date(
                            item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                          )
                        )}},[_vm._v(_vm._s(_vm.$func.dateFormatter( new Date( item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate ) )))]):_c('span',[_vm._v(" ")])]),_c('td',[_c('action-items',{attrs:{"activeActionItems":_vm.visibleArchivedUsersItems,"userobj":item,"tblid":'tblArchivedUser'}})],1)])}),0):_c('tbody',[_vm._m(2)])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-close"},[_c('div',{staticClass:"dropdown"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-4"},[_c('span',{staticStyle:{"font-size":"0.9em","color":"#777777","text-align":"left","font-style":"italic"}},[_vm._v("Note: In order to re-activate an archived user, please edit the user and remove the \"ZZDel\" prefix from the GivenName/FirstName. Once done, then those user will moved the \"B2C Awaiting Users\" list, from there activate the user.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticStyle:{"font-size":"bolder"}},[_c('td',{attrs:{"colspan":"12"}},[_vm._v("No data found")])])}]

export { render, staticRenderFns }