<template>
  <div>
    <div class="row tblrow">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-close">
            <div class="dropdown"></div>
          </div>
          <div class="card-header d-flex align-items-center">
            <div class="col-sm-8">
              <div class="input-group">
                <select
                  class="form-control"
                  style="height: 38px"
                  v-model="selectedField"
                >
                  <option value="0">Select</option>
                  <option
                    v-for="item in searchFields"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <select
                  class="form-control"
                  style="height: 38px"
                  v-model="selectedSearchMethod"
                >
                  <option value="0">Select</option>
                  <option
                    v-for="item in searchMethods"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <select
                  class="form-control"
                  style="height: 38px"
                  v-model="selectedUserStatus"
                >
                  <template v-for="item in $func.userStatus">
                    <option
                      v-if="item.value === $func.userStatus[0].value"
                      :key="item.value"
                      :value="item.text"
                      selected="selected"
                    >
                      {{ item.text }}
                    </option>
                    <option v-else :key="item.value" :value="item.text">
                      {{ item.text }}
                    </option>
                  </template>
                </select>
                <input
                  type="search"
                  maxlength="100"
                  class="form-control rounded"
                  style="height: 38px"
                  placeholder=""
                  aria-label="Search"
                  aria-describedby="search-addon"
                  v-model="searchText"
                  @keyup.enter.prevent="searchUser"
                />
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click.prevent="searchUser"
                >
                  Search
                </button>
              </div>
            </div>
            <div class="col-sm-4">
              <span
                style="
                  font-size: 0.9em;
                  color: #777777;
                  text-align: left;
                  font-style: italic;
                "
                >Note: Unsupported search conditions for
                <strong
                  >{{ searchFields[5].name }} -
                  {{ searchMethods[1].name }}</strong
                >
                &amp;
                <strong
                  >{{ searchFields[6].name }} -
                  {{ searchMethods[1].name }}</strong
                ><br /><i class="fa fa-check-circle text-success fa-2x"></i
                >&nbsp;Active,&nbsp;<i
                  class="fa fa-exclamation-circle text-warning fa-2x"
                ></i
                >&nbsp;Inactive,&nbsp;<i
                  class="fa fa-circle text-secondary fa-2x"
                ></i
                >&nbsp;Archived Users</span
              >
            </div>
          </div>
          <div class="card-body">
            <div
              id="dvAllUsers"
              class="table-responsive"
              style="max-height: 450px; overflow: auto"
            >
              <table
                id="tblAllUsers"
                class="table table-striped table-bordered table-hover table-condensed table-fixed"
              >
                <thead>
                  <tr>
                    <th class="beam_tableheading">Status</th>
                    <th class="beam_tableheading">Action</th>
                    <th class="beam_tableheading">
                      Email Add.&nbsp;<i
                        :class="'fa fa-sort-amount-' + userSortObj.mail.order"
                        @click.prevent="
                          userSortByField(
                            userSortObj.mail.field,
                            userSortObj.mail.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Display name&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' + userSortObj.displayName.order
                        "
                        @click.prevent="
                          userSortByField(
                            userSortObj.displayName.field,
                            userSortObj.displayName.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Country&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' + userSortObj.country.order
                        "
                        @click.prevent="
                          userSortByField(
                            userSortObj.country.field,
                            userSortObj.country.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      State&nbsp;<i
                        :class="'fa fa-sort-amount-' + userSortObj.state.order"
                        @click.prevent="
                          userSortByField(
                            userSortObj.state.field,
                            userSortObj.state.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Employer&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          userSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Employer
                            .order
                        "
                        @click.prevent="
                          userSortByField(
                            userSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Employer
                              .field,
                            userSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Employer
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      SF Role Id&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          userSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                            .order
                        "
                        @click.prevent="
                          userSortByField(
                            userSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                              .field,
                            userSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Com. Region&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          userSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                            .order
                        "
                        @click.prevent="
                          userSortByField(
                            userSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                              .field,
                            userSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Region&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          userSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Region
                            .order
                        "
                        @click.prevent="
                          userSortByField(
                            userSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Region
                              .field,
                            userSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Region
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <!--<th class="beam_tableheading">Com. area</th>-->
                    <th class="beam_tableheading">
                      Com. division&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          userSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_Division
                            .order
                        "
                        @click.prevent="
                          userSortByField(
                            userSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Division
                              .field,
                            userSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_Division
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Activation Date&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          userSortObj
                            .extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                            .order
                        "
                        @click.prevent="
                          userSortByField(
                            userSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                              .field,
                            userSortObj
                              .extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                              .order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">
                      Created On&nbsp;<i
                        :class="
                          'fa fa-sort-amount-' +
                          userSortObj.createdDateTime.order
                        "
                        @click.prevent="
                          userSortByField(
                            userSortObj.createdDateTime.field,
                            userSortObj.createdDateTime.order
                          )
                        "
                        style="cursor: pointer"
                      ></i>
                    </th>
                    <th class="beam_tableheading">Actions</th>
                  </tr>
                </thead>
                <tbody v-if="users.length > 0 && users[0].id !== null">
                  <tr v-for="item in users" :data-id="item.id" :key="item.id">
                    <th scope="row">
                      <i
                        :data-id="item.id"
                        data-toggle="tooltip"
                        :title="
                          (item.accountEnabled
                            ? 'Active'
                            : item.displayName.toLowerCase().startsWith('zz')
                            ? 'Archived'
                            : 'Inactive') + ' user'
                        "
                        :class="
                          'fa fa-' +
                          (item.accountEnabled
                            ? 'check-circle text-success'
                            : item.displayName.toLowerCase().startsWith('zz')
                            ? 'circle text-secondary'
                            : 'exclamation-circle text-warning') +
                          ' fa-2x'
                        "
                      ></i>
                    </th>
                    <th scope="row">
                      <template
                        v-if="item.displayName.toLowerCase().startsWith('zz')"
                      >
                        &nbsp;
                      </template>
                      <template v-else>
                        <a
                          :data-id="item.id"
                          style="padding-left: 12%"
                          href="javascript:void(0);"
                          data-toggle="tooltip"
                          :title="
                            item.accountEnabled ? 'Deactivate' : 'Activate'
                          "
                          @click.prevent="activateDeactivateUser(item, $event)"
                          ><i
                            :class="
                              'fa fa-' +
                              (item.accountEnabled ? 'lock' : 'unlock') +
                              ' fa-2x'
                            "
                          ></i
                        ></a>
                      </template>
                    </th>
                    <td>
                      <span
                        data-toggle="tooltip"
                        v-html="$func.apiBreakNewLine(item.mail, 35)"
                        :title="item.mail"
                      ></span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.displayName">{{
                        $func.apiShortenString(item.displayName)
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.country">{{
                        $func.apiShortenString(item.country)
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.state">{{
                        $func.apiShortenString(item.state)
                      }}</span>
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Employer
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Employer
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Region
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Region
                          )
                        }}</span
                      >
                    </td>
                    <!--<td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Area
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Area
                          )
                        }}</span
                      >
                    </td>-->
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          item.extension_be6dc6c96b4c411780751b4231962926_Division
                        "
                        >{{
                          $func.apiShortenString(
                            item.extension_be6dc6c96b4c411780751b4231962926_Division
                          )
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="
                          item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate !==
                            undefined &&
                          item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate !==
                            null &&
                          item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate.trim() !==
                            'NA_NULL' &&
                          new Date(
                            item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                          ) !== undefined
                        "
                        data-toggle="tooltip"
                        :title="
                          $func.dateFormatter(
                            new Date(
                              item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                            )
                          )
                        "
                        >{{
                          $func.dateFormatter(
                            new Date(
                              item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate
                            )
                          )
                        }}</span
                      >
                      <span v-else>&nbsp;</span>
                    </td>
                    <td>
                      <span
                        data-toggle="tooltip"
                        :title="
                          $func.dateFormatter(new Date(item.createdDateTime))
                        "
                        >{{
                          $func.dateFormatter(new Date(item.createdDateTime))
                        }}</span
                      >
                    </td>
                    <td>
                      <action-items
                        :activeActionItems="item.visibleActiveUsersItems"
                        :userobj="item"
                        :tblid="'tblAllUsers'"
                      ></action-items>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr style="font-size: bolder">
                    <td colspan="14">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionItems from "@/components/common/action-items";
import { EventBus } from "@/event-bus";
export default {
  name: "advanced-search",
  components: { ActionItems },
  props: ["getAdvancedSearch"],
  data() {
    return {
      searchFields: [
        {
          name: "Display Name",
          value: "displayName",
        },
        {
          name: "Given Name",
          value: "givenName",
        },
        {
          name: "Surname",
          value: "surname",
        },
        {
          name: "State",
          value: "state",
        },
        {
          name: "Country",
          value: "country",
        },
        {
          name: "Email",
          value: "mail",
        },
        {
          name: "Employer",
          value: "companyName",
        },
      ],
      searchMethods: [
        {
          name: "Equals",
          value: "eq",
        },
        {
          name: "Starts With",
          value: "startsWith",
        },
      ],
      searchUserScrolled: false,
      searchUsernextLink: null,
      searchText: "",
      selectedField: "0",
      selectedSearchMethod: "0",
      selectedUserStatus: this.$func.userStatus[0].value,
      userSortObj: this.$func.sortObject,
      users: [
        {
          id: null,
          accountEnabled: false,
          displayName: null,
          givenName: null,
          jobTitle: null,
          mail: null,
          mobilePhone: null,
          streetAddress: null,
          state: null,
          country: null,
          officeLocation: null,
          city: null,
          postalCode: null,
          preferredLanguage: null,
          surname: null,
          companyName: null,
          createdDateTime: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
          extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
          extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
          extension_be6dc6c96b4c411780751b4231962926_Email: null,
          extension_be6dc6c96b4c411780751b4231962926_Employer: null,
          extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
          extension_be6dc6c96b4c411780751b4231962926_Region: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
          extension_be6dc6c96b4c411780751b4231962926_Division: null,
          extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
          extension_be6dc6c96b4c411780751b4231962926_Area: null,
          extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
          extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
          visibleActiveUsersItems: this.$func.itemsVisibility,
        },
      ],
    };
  },
  created() {
    const _vm = this;
    EventBus.$on("advancedsearch-changed", (data) => {
      try {
        _vm.users.forEach((item, index, object) => {
          if (item.id === data.id) {
            if (data.isdeleted) {
              object.splice(index, 1);
            } else {
              item.givenName = data.data.givenName;
              item.displayName = data.data.displayName;
              item.country = data.data.country;
              item.state = data.data.state;
              item.extension_be6dc6c96b4c411780751b4231962926_Employer =
                data.data.extension_be6dc6c96b4c411780751b4231962926_Employer;
              item.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID =
                data.data.extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID;
              item.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion =
                data.data.extension_be6dc6c96b4c411780751b4231962926_CommercialRegion;
              item.extension_be6dc6c96b4c411780751b4231962926_Region =
                data.data.extension_be6dc6c96b4c411780751b4231962926_Region;
              item.extension_be6dc6c96b4c411780751b4231962926_Division =
                data.data.extension_be6dc6c96b4c411780751b4231962926_Division;
              item.extension_be6dc6c96b4c411780751b4231962926_ActivationDate =
                data.data.extension_be6dc6c96b4c411780751b4231962926_ActivationDate;
              item.extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated =
                data.data.extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated;
              _vm.$set(_vm.users, index, item);
            }
          }
        });
      } catch (ex) {
        console.error(ex);
      }
    });
  },
  watch: {
    getAdvancedSearch: function () {
      const _vm = this;
      if (_vm.getAdvancedSearch) {
        _vm.searchUserScrolled = false;
        _vm.searchUsernextLink = null;
        _vm.searchText = "";
        _vm.selectedField = "0";
        _vm.selectedSearchMethod = "0";
        _vm.selectedUserStatus = _vm.$func.userStatus[0].value;
        _vm.userSortObj = _vm.$func.sortObject;
        _vm.users = [
          {
            id: null,
            accountEnabled: false,
            displayName: null,
            givenName: null,
            jobTitle: null,
            mail: null,
            mobilePhone: null,
            streetAddress: null,
            state: null,
            country: null,
            officeLocation: null,
            city: null,
            postalCode: null,
            preferredLanguage: null,
            surname: null,
            companyName: null,
            createdDateTime: null,
            extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID:
              null,
            extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
            extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
            extension_be6dc6c96b4c411780751b4231962926_Email: null,
            extension_be6dc6c96b4c411780751b4231962926_Employer: null,
            extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
            extension_be6dc6c96b4c411780751b4231962926_Region: null,
            extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
            extension_be6dc6c96b4c411780751b4231962926_Division: null,
            extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
            extension_be6dc6c96b4c411780751b4231962926_Area: null,
            extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
            extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
            visibleActiveUsersItems: _vm.$func.itemsVisibility,
          },
        ];
      }
    },
  },
  methods: {
    userSortByField: function (field, order, isscrolled) {
      const _vm = this;
      isscrolled !== undefined &&
      isscrolled !== null &&
      (isscrolled === false || isscrolled === true)
        ? isscrolled
        : false;
      Object.keys(_vm.userSortObj).forEach(function (key) {
        if (key === field) {
          _vm.userSortObj[key].order = isscrolled
            ? order
            : order === "asc"
            ? "desc"
            : "asc";
          if (!isscrolled) {
            _vm.userSortObj[key].isselected = true;
          }
          _vm.$func.sortArrayModel(
            _vm.users,
            _vm.userSortObj[key].field,
            _vm.userSortObj[key].order,
            _vm.userSortObj[key].isdatefield
          );
        } else {
          _vm.userSortObj[key].order = "asc";
          _vm.userSortObj[key].isselected = false;
        }
      });
    },
    searchUser: function () {
      const _vm = this;
      if (_vm.searchText.trim().length <= 0) {
        _vm.$func.sAlert(
          "Search field cannot be empty",
          "warning",
          "Please type the text you want to search",
          _vm.$swal
        );
      } else if (
        _vm.selectedField === "0" ||
        _vm.selectedSearchMethod === "0"
      ) {
        _vm.$func.sAlert(
          "Please select search field and search method",
          "warning",
          "Please select the field you want to search for and the type of search you want to perform",
          _vm.$swal
        );
      } else {
        let userType = "";
        let searchUrlQry = "";
        let searchMethodWithField = "";
        _vm.searchText = _vm.searchText.trim().replace("&", "%26");
        _vm.searchText = _vm.searchText.trim().replace("'", "''");
        if (_vm.selectedSearchMethod === "eq") {
          if (_vm.selectedField === _vm.searchFields[5].value) {
            searchMethodWithField =
              "(mail " +
              _vm.selectedSearchMethod +
              " '" +
              _vm.searchText +
              "' or extension_be6dc6c96b4c411780751b4231962926_Email " +
              _vm.selectedSearchMethod +
              " '" +
              _vm.searchText +
              /*"' or streetAddress " +
              _vm.selectedSearchMethod +
              " '" +
              _vm.searchText +*/
              "')";
          } else {
            searchMethodWithField =
              _vm.selectedField +
              " " +
              _vm.selectedSearchMethod +
              " '" +
              _vm.searchText +
              "'";
          }
        } else {
          if (
            _vm.selectedField === _vm.searchFields[5].value ||
            _vm.selectedField === _vm.searchFields[6].value
          ) {
            _vm.$func.sAlert(
              "Unsupported query '" + _vm.searchMethods[1].name + "'",
              "warning",
              "Unsupported search condition for '" +
                _vm.searchFields[5].name +
                "' and '" +
                _vm.searchFields[6].name +
                "' field(s)",
              _vm.$swal
            );
          } else {
            searchMethodWithField =
              _vm.selectedSearchMethod +
              "(" +
              _vm.selectedField +
              ", '" +
              _vm.searchText +
              "')";
          }
        }
        switch (_vm.selectedUserStatus) {
          case _vm.$func.userStatus[0].value:
            userType =
              "(extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated eq true)";
            break;
          case _vm.$func.userStatus[1].value:
            userType =
              "(accountEnabled eq false) and (extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated eq true)";
            break;
          case _vm.$func.userStatus[2].value:
            userType =
              "(accountEnabled eq true) and (extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated eq true)";
            break;
          /*case _vm.$func.userStatus[3].value:
            userType =
              "(accountEnabled eq false) and startswith(displayName,'ZZDel')";
            break;*/
          default:
            break;
        }
        searchUrlQry =
          _vm.$func.profileGraphApiBaseUrl +
          "users" +
          "?" +
          "$select=id,accountEnabled,createdDateTime,companyName,givenName,displayName,mail,streetAddress,state,country,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID,extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,extension_be6dc6c96b4c411780751b4231962926_Birthdate,extension_be6dc6c96b4c411780751b4231962926_Email,extension_be6dc6c96b4c411780751b4231962926_Employer,extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,extension_be6dc6c96b4c411780751b4231962926_Region,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID,extension_be6dc6c96b4c411780751b4231962926_Division,extension_be6dc6c96b4c411780751b4231962926_CommercialRegion,extension_be6dc6c96b4c411780751b4231962926_Area,extension_be6dc6c96b4c411780751b4231962926_ActivationDate,extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated" +
          "&" +
          "$filter=" +
          userType +
          (userType !== "" ? " and " : "") +
          searchMethodWithField +
          "&$top=" +
          _vm.$func.loadRecordsAtaTime;
        _vm.searchUserScrolled = false;
        _vm.searchUsernextLink = null;
        _vm.users = [
          {
            id: null,
            accountEnabled: false,
            displayName: null,
            givenName: null,
            jobTitle: null,
            mail: null,
            mobilePhone: null,
            streetAddress: null,
            state: null,
            country: null,
            officeLocation: null,
            city: null,
            postalCode: null,
            preferredLanguage: null,
            surname: null,
            companyName: null,
            createdDateTime: null,
            extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID:
              null,
            extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
            extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
            extension_be6dc6c96b4c411780751b4231962926_Email: null,
            extension_be6dc6c96b4c411780751b4231962926_Employer: null,
            extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
            extension_be6dc6c96b4c411780751b4231962926_Region: null,
            extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
            extension_be6dc6c96b4c411780751b4231962926_Division: null,
            extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
            extension_be6dc6c96b4c411780751b4231962926_Area: null,
            extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
            extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
            visibleActiveUsersItems: _vm.$func.itemsVisibility,
          },
        ];
        _vm.fetchUsers(searchUrlQry);
      }
    },
    fetchUsers: function (url) {
      const _vm = this;
      let satisfyCondition = false;
      if (
        _vm.selectedField === _vm.searchFields[5].value ||
        _vm.selectedField === _vm.searchFields[6].value
      ) {
        if (_vm.selectedSearchMethod === _vm.searchMethods[1].value) {
          satisfyCondition = false;
        } else {
          satisfyCondition = true;
        }
      } else {
        satisfyCondition = true;
      }
      if (satisfyCondition) {
        if (url === undefined) {
          url =
            _vm.$func.profileGraphApiBaseUrl +
            "users" +
            "?" +
            "$select=id,accountEnabled,createdDateTime,companyName,givenName,displayName,mail,streetAddress,state,country,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID,extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor,extension_be6dc6c96b4c411780751b4231962926_Birthdate,extension_be6dc6c96b4c411780751b4231962926_Email,extension_be6dc6c96b4c411780751b4231962926_Employer,extension_be6dc6c96b4c411780751b4231962926_OnOffPremise,extension_be6dc6c96b4c411780751b4231962926_Region,extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID,extension_be6dc6c96b4c411780751b4231962926_Division,extension_be6dc6c96b4c411780751b4231962926_CommercialRegion,extension_be6dc6c96b4c411780751b4231962926_Area,extension_be6dc6c96b4c411780751b4231962926_ActivationDate,extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated";
          "&" +
            "$filter=(extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated eq true)" +
            +"&$top=" +
            _vm.$func.loadRecordsAtaTime;
        }
        _vm.$func
          .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
          .then((tokenresponse) => {
            _vm.$store.state.LoginResponse = tokenresponse;
            localStorage.setItem(
              "LoginResponse",
              JSON.stringify(tokenresponse)
            );
            _vm.$axios
              .get(url, {
                headers: {
                  Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("LoginResponse"))
                      .accessToken
                  }`,
                },
              })
              .then((response) => {
                if (
                  response.data["@odata.nextLink"] !== undefined &&
                  response.data["@odata.nextLink"] !== null
                ) {
                  _vm.searchUsernextLink = response.data["@odata.nextLink"];
                } else {
                  _vm.searchUsernextLink = null;
                }
                _vm.$nextTick(() => {
                  if (_vm.users.length > 0 && _vm.users[0].id !== null) {
                    _vm.users.push.apply(_vm.users, response.data.value);
                    _vm.users = _vm.users.filter(
                      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
                    );
                    if (
                      _vm.users !== undefined &&
                      _vm.users !== null &&
                      _vm.users.length > 0
                    ) {
                      for (let y = 0; y < _vm.users.length; y++) {
                        let yItem = _vm.users[y];
                        yItem.visibleActiveUsersItems = {
                          edit: true,
                          delete: yItem.accountEnabled
                            ? true
                            : yItem.displayName.toLowerCase().startsWith("zz")
                            ? false
                            : true,
                          resetpassword: yItem.accountEnabled ? true : false,
                          membership: yItem.accountEnabled ? true : false,
                          archive: yItem.accountEnabled ? true : false,
                          details: true,
                        };
                        _vm.$set(_vm.users, y, yItem);
                      }
                    }
                  } else {
                    _vm.users = response.data.value;
                    if (
                      _vm.users !== undefined &&
                      _vm.users !== null &&
                      _vm.users.length > 0
                    ) {
                      for (let x = 0; x < _vm.users.length; x++) {
                        let xItem = _vm.users[x];
                        xItem.visibleActiveUsersItems = {
                          edit: true,
                          delete: xItem.accountEnabled
                            ? true
                            : xItem.displayName.toLowerCase().startsWith("zz")
                            ? false
                            : true,
                          resetpassword: xItem.accountEnabled ? true : false,
                          membership: xItem.accountEnabled ? true : false,
                          archive: xItem.accountEnabled ? true : false,
                          details: true,
                        };
                        _vm.$set(_vm.users, x, xItem);
                      }
                    }
                    // eslint-disable-next-line no-undef
                    $("div.table-responsive")
                      .removeAttr("style")
                      .attr(
                        "style",
                        "max-height: " +
                          // eslint-disable-next-line no-undef
                          ($(window).height() - ($("header").height() + 300)) +
                          "px; overflow: auto"
                      );
                    /*// eslint-disable-next-line no-undef
                  $("#dvAllUsers").unbind("scroll");
                  // eslint-disable-next-line no-undef
                  $("#dvAllUsers").scroll(function () {
                    if (
                      // eslint-disable-next-line no-undef
                      $("#dvAllUsers").scrollTop() >=
                        // eslint-disable-next-line no-undef
                        $("#tblAllUsers").height() -
                          // eslint-disable-next-line no-undef
                          $("#dvAllUsers")[0].clientHeight &&
                      _vm.searchUsernextLink !== null &&
                      !_vm.searchUserScrolled
                    ) {
                      _vm.searchUserScrolled = true;
                      _vm.fetchUsers(_vm.searchUsernextLink);
                    }
                    setTimeout(() => {
                      _vm.searchUserScrolled = false;
                    }, 500);
                  });*/
                  }
                  if (_vm.users.length > 0 && _vm.users[0].id !== null) {
                    for (let t = 0; t < _vm.users.length; t++) {
                      _vm.users[t].mail =
                        _vm.users[t].mail !== undefined &&
                        _vm.users[t].mail !== null &&
                        _vm.users[t].mail !== ""
                          ? _vm.users[t].mail
                          : _vm.users[t]
                              .extension_be6dc6c96b4c411780751b4231962926_Email !==
                              undefined &&
                            _vm.users[t]
                              .extension_be6dc6c96b4c411780751b4231962926_Email !==
                              null &&
                            _vm.users[t]
                              .extension_be6dc6c96b4c411780751b4231962926_Email !==
                              ""
                          ? _vm.users[t]
                              .extension_be6dc6c96b4c411780751b4231962926_Email
                          : _vm.$func.validateEmail(_vm.users[t].streetAddress)
                          ? _vm.users[t].streetAddress
                          : "";
                    }
                  }
                });
              })
              .catch(function (error) {
                console.error(error);
              })
              .finally(function () {
                if (_vm.searchUsernextLink !== null) {
                  _vm.fetchUsers(_vm.searchUsernextLink);
                }
                // eslint-disable-next-line no-undef
                $('[data-toggle="tooltip"]').tooltip();
                _vm.userSortObj.createdDateTime.isselected = false;
                _vm.userSortObj.createdDateTime.order = "asc";
                _vm.userSortObj.extension_be6dc6c96b4c411780751b4231962926_ActivationDate.isselected = true;
                _vm.userSortObj.extension_be6dc6c96b4c411780751b4231962926_ActivationDate.order =
                  "desc";
                let selectedSortObj;
                Object.keys(_vm.userSortObj).forEach(function (key) {
                  if (_vm.userSortObj[key].isselected) {
                    selectedSortObj = _vm.userSortObj[key];
                  }
                });
                _vm.userSortByField(
                  selectedSortObj.field,
                  selectedSortObj.order,
                  true
                );
              });
          });
      }
    },
    sendNotification: function (id, isuser, userstatus) {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm
            .$axios({
              url: process.env.VUE_APP_SERVER_API + "api/Notify",
              method: "POST",
              data: {
                id: id,
                isuser: isuser,
                status: userstatus,
              },
              headers: {
                token: JSON.parse(localStorage.getItem("LoginResponse"))
                  .accessToken,
              },
            })
            .then((response) => {
              console.log(response);
            });
        });
    },
    activateDeactivateUser: function (userobj, evt) {
      const _vm = this;
      if (userobj.accountEnabled) {
        _vm.$func
          .sConfirm(
            "Are you sure you want to deactivate " + userobj.displayName + "?",
            _vm.$func.alerticon.question,
            "Select your option",
            "Yes",
            _vm.$swal
          )
          .then((result) => {
            const currentDate = _vm.$func.apiDateFormatter(new Date());
            if (result.isConfirmed) {
              _vm.$func
                .tryGetMsalTokenSilently(
                  _vm.$func.msalObject,
                  _vm.$func.loginRequest
                )
                .then((tokenresponse) => {
                  _vm.$store.state.LoginResponse = tokenresponse;
                  localStorage.setItem(
                    "LoginResponse",
                    JSON.stringify(tokenresponse)
                  );
                  _vm.$axios
                    .patch(
                      _vm.$func.profileGraphApiBaseUrl + "users/" + userobj.id,
                      {
                        accountEnabled: false,
                        extension_be6dc6c96b4c411780751b4231962926_ActivationDate:
                          currentDate,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${
                            JSON.parse(localStorage.getItem("LoginResponse"))
                              .accessToken
                          }`,
                        },
                      }
                    )
                    .then((resp) => {
                      console.log(resp);
                      _vm.$func
                        .sAlert(
                          userobj.displayName +
                            "'s account has been deactivated successfully.",
                          "success",
                          "",
                          _vm.$swal
                        )
                        .then((ok) => {
                          if (ok) {
                            _vm.sendNotification(
                              userobj.id,
                              true,
                              _vm.$func.msUserStatus.Deactivation
                            );
                            // eslint-disable-next-line no-undef
                            $(evt.target)
                              .closest("tr[data-id='" + userobj.id + "']")
                              .find("a[data-id='" + userobj.id + "']")
                              .removeAttr("title")
                              .removeAttr("data-original-title")
                              .attr("title", "Activate")
                              .attr("data-original-title", "Activate");
                            // eslint-disable-next-line no-undef
                            $(evt.target)
                              .closest("tr[data-id='" + userobj.id + "']")
                              .find("i[data-id='" + userobj.id + "']")
                              .removeAttr("title")
                              .removeAttr("data-original-title")
                              .attr("title", "Inactive user")
                              .attr("data-original-title", "Inactive user");
                            if (
                              _vm.users !== undefined &&
                              _vm.users !== null &&
                              _vm.users.length > 0
                            ) {
                              for (let m = 0; m < _vm.users.length; m++) {
                                let mItem = _vm.users[m];
                                if (mItem.id === userobj.id) {
                                  mItem.accountEnabled = false;
                                  mItem.extension_be6dc6c96b4c411780751b4231962926_ActivationDate =
                                    currentDate;
                                  mItem.visibleActiveUsersItems = {
                                    edit: true,
                                    delete: mItem.accountEnabled
                                      ? true
                                      : mItem.displayName
                                          .toLowerCase()
                                          .startsWith("zz")
                                      ? false
                                      : true,
                                    resetpassword: mItem.accountEnabled
                                      ? true
                                      : false,
                                    membership: mItem.accountEnabled
                                      ? true
                                      : false,
                                    archive: mItem.accountEnabled
                                      ? true
                                      : false,
                                    details: true,
                                  };
                                  _vm.$set(_vm.users, m, mItem);
                                  break;
                                }
                              }
                            }
                            let selectedSortObj;
                            Object.keys(_vm.userSortObj).forEach(function (
                              key
                            ) {
                              if (_vm.userSortObj[key].isselected) {
                                selectedSortObj = _vm.userSortObj[key];
                              }
                            });
                            _vm.userSortByField(
                              selectedSortObj.field,
                              selectedSortObj.order,
                              true
                            );
                          }
                        });
                    })
                    .catch(function (error) {
                      _vm.$func.sAlert(
                        "An error occurred. Please try again after some time",
                        "error",
                        "If error persist, then please contact to administrator",
                        _vm.$swal
                      );
                      console.log(error);
                    });
                });
            }
          });
      } else {
        const currentDate = _vm.$func.apiDateFormatter(new Date());
        _vm.$func
          .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
          .then((tokenresponse) => {
            _vm.$store.state.LoginResponse = tokenresponse;
            localStorage.setItem(
              "LoginResponse",
              JSON.stringify(tokenresponse)
            );
            _vm.$axios
              .patch(
                _vm.$func.profileGraphApiBaseUrl + "users/" + userobj.id,
                {
                  accountEnabled: true,
                  extension_be6dc6c96b4c411780751b4231962926_ActivationDate:
                    currentDate,
                },
                {
                  headers: {
                    Authorization: `Bearer ${
                      JSON.parse(localStorage.getItem("LoginResponse"))
                        .accessToken
                    }`,
                  },
                }
              )
              .then((resp) => {
                console.log(resp);
                _vm.$func
                  .sAlert(
                    userobj.displayName +
                      "'s account has been activated successfully.",
                    "success",
                    "",
                    _vm.$swal
                  )
                  .then((ok) => {
                    if (ok) {
                      _vm.sendNotification(
                        userobj.id,
                        true,
                        _vm.$func.msUserStatus.Activation
                      );
                      // eslint-disable-next-line no-undef
                      $(evt.target)
                        .closest("tr[data-id='" + userobj.id + "']")
                        .find("a[data-id='" + userobj.id + "']")
                        .removeAttr("title")
                        .removeAttr("data-original-title")
                        .attr("title", "Deactivate")
                        .attr("data-original-title", "Deactivate");
                      // eslint-disable-next-line no-undef
                      $(evt.target)
                        .closest("tr[data-id='" + userobj.id + "']")
                        .find("i[data-id='" + userobj.id + "']")
                        .removeAttr("title")
                        .removeAttr("data-original-title")
                        .attr("title", "Active user")
                        .attr("data-original-title", "Active user");
                      if (
                        _vm.users !== undefined &&
                        _vm.users !== null &&
                        _vm.users.length > 0
                      ) {
                        for (let n = 0; n < _vm.users.length; n++) {
                          let nItem = _vm.users[n];
                          if (nItem.id === userobj.id) {
                            nItem.accountEnabled = true;
                            nItem.extension_be6dc6c96b4c411780751b4231962926_ActivationDate =
                              currentDate;
                            nItem.visibleActiveUsersItems = {
                              edit: true,
                              delete: nItem.accountEnabled
                                ? true
                                : nItem.displayName
                                    .toLowerCase()
                                    .startsWith("zz")
                                ? false
                                : true,
                              resetpassword: nItem.accountEnabled
                                ? true
                                : false,
                              membership: nItem.accountEnabled ? true : false,
                              archive: nItem.accountEnabled ? true : false,
                              details: true,
                            };
                            _vm.$set(_vm.users, n, nItem);
                            break;
                          }
                        }
                      }
                      let selectedSortObj;
                      Object.keys(_vm.userSortObj).forEach(function (key) {
                        if (_vm.userSortObj[key].isselected) {
                          selectedSortObj = _vm.userSortObj[key];
                        }
                      });
                      _vm.userSortByField(
                        selectedSortObj.field,
                        selectedSortObj.order,
                        true
                      );
                    }
                  });
              })
              .catch(function (error) {
                _vm.$func.sAlert(
                  "An error occurred. Please try again after some time",
                  "error",
                  "If error persist, then please contact to administrator",
                  _vm.$swal
                );
                console.log(error);
              });
          });
      }
    },
  },
};
</script>

<style scoped>
.table-fixed {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.table-fixed thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
}
.table-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
  border: 1px solid #f6bcc3;
}
</style>
