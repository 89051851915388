<template>
  <div>
    <div class="row tblrow">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-close">
            <div class="dropdown"></div>
          </div>
          <div class="card-header d-flex align-items-center">
            <div class="col-sm-4">
              <div class="input-group">
                <select
                  class="form-control"
                  style="height: 38px"
                  v-model="selectedUserStatus"
                >
                  <template v-for="item in $func.userStatus">
                    <option
                      v-if="item.value === $func.userStatus[0].value"
                      :key="item.value"
                      :value="item.text"
                      selected="selected"
                    >
                      {{ item.text }}
                    </option>
                    <option v-else :key="item.value" :value="item.text">
                      {{ item.text }}
                    </option>
                  </template>
                </select>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click.prevent="exportToExcel"
                >
                  Export to Excel
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" style="min-height: 200px">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "export-users",
  props: ["getExportUsers"],
  data() {
    return {
      selectedUserStatus: this.$func.userStatus[0].value,
      exportUsernextLink: "",
      exportusers: [
        {
          id: null,
          accountEnabled: false,
          displayName: null,
          givenName: null,
          jobTitle: null,
          mail: null,
          mobilePhone: null,
          streetAddress: null,
          state: null,
          country: null,
          officeLocation: null,
          city: null,
          postalCode: null,
          preferredLanguage: null,
          surname: null,
          companyName: null,
          createdDateTime: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID: null,
          extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
          extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
          extension_be6dc6c96b4c411780751b4231962926_Email: null,
          extension_be6dc6c96b4c411780751b4231962926_Employer: null,
          extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
          extension_be6dc6c96b4c411780751b4231962926_Region: null,
          extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
          extension_be6dc6c96b4c411780751b4231962926_Division: null,
          extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
          extension_be6dc6c96b4c411780751b4231962926_Area: null,
          extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
          extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
          visibleActiveUsersItems: this.$func.itemsVisibility,
        },
      ],
      json_fields: {
        Email: "mail",
        Name: "displayName",
        Country: "country",
        State: "state",
        "Success Factor Role Id":
          "extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID",
      },
    };
  },
  watch: {
    getExportUsers: function () {
      const _vm = this;
      if (_vm.getExportUsers) {
        _vm.selectedUserStatus = _vm.$func.userStatus[0].value;
        _vm.exportUsernextLink = "";
        _vm.exportusers = [
          {
            id: null,
            accountEnabled: false,
            displayName: null,
            givenName: null,
            jobTitle: null,
            mail: null,
            mobilePhone: null,
            streetAddress: null,
            state: null,
            country: null,
            officeLocation: null,
            city: null,
            postalCode: null,
            preferredLanguage: null,
            surname: null,
            companyName: null,
            createdDateTime: null,
            extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsRoleID:
              null,
            extension_be6dc6c96b4c411780751b4231962926_BeamSuntorySponsor: null,
            extension_be6dc6c96b4c411780751b4231962926_Birthdate: null,
            extension_be6dc6c96b4c411780751b4231962926_Email: null,
            extension_be6dc6c96b4c411780751b4231962926_Employer: null,
            extension_be6dc6c96b4c411780751b4231962926_OnOffPremise: null,
            extension_be6dc6c96b4c411780751b4231962926_Region: null,
            extension_be6dc6c96b4c411780751b4231962926_SuccessFactorsID: null,
            extension_be6dc6c96b4c411780751b4231962926_Division: null,
            extension_be6dc6c96b4c411780751b4231962926_CommercialRegion: null,
            extension_be6dc6c96b4c411780751b4231962926_Area: null,
            extension_be6dc6c96b4c411780751b4231962926_ActivationDate: null,
            extension_be6dc6c96b4c411780751b4231962926_IsSponsorValidated: null,
            visibleActiveUsersItems: _vm.$func.itemsVisibility,
          },
        ];
      }
    },
  },
  methods: {
    exportToExcel: function () {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm
            .$axios({
              url: process.env.VUE_APP_SERVER_API + "api/ExcelExport",
              method: "POST",
              data: {
                statuscode: _vm.selectedUserStatus,
              },
              headers: {
                token: JSON.parse(localStorage.getItem("LoginResponse"))
                  .accessToken,
              },
              responseType: "blob",
            })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                _vm.$func.dateAsFilename(new Date()) + ".xlsx"
              );
              document.body.appendChild(link);
              link.click();
            })
            .catch(function (error) {
              _vm.$func.sAlert(
                "An error occurred. Please try again after some time",
                "error",
                "If error persist, then please contact to administrator",
                _vm.$swal
              );
              console.error(error);
            });
        });
    },
  },
};
</script>

<style scoped></style>
