<template>
  <div
    id="userMembership"
    class="modal fade"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            User Membership - {{ useritemdetails.displayName }}
          </h4>
          <button type="button" class="close" @click.prevent="closeModal()">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div
                id="dvPopupB2CGroups"
                class="table-responsive"
                style="max-height: 300px; overflow: auto"
              >
                <table
                  id="tblPopupB2CGroups"
                  class="table table-striped table-bordered table-hover table-condensed table-fixed"
                >
                  <thead>
                    <tr>
                      <th class="beam_tableheading">Group Name</th>
                      <th class="beam_tableheading">Description</th>
                      <th class="beam_tableheading">Is Member Of</th>
                      <th class="beam_tableheading">Action</th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="b2cgroups.length > 0 && b2cgroups[0].id !== null"
                  >
                    <tr
                      v-for="(item, index) in b2cgroups"
                      :key="item.id"
                      :data-groupid="item.id"
                    >
                      <td>
                        <span data-toggle="tooltip" :title="item.displayName">{{
                          item.displayName
                        }}</span>
                      </td>
                      <td>
                        <span data-toggle="tooltip" :title="item.description">{{
                          $func.apiShortenStringLarger(item.description)
                        }}</span>
                      </td>
                      <td>
                        <span
                          data-toggle="tooltip"
                          :title="item.isGroupMember ? 'Yes' : 'No'"
                          >{{ item.isGroupMember ? "Yes" : "No" }}</span
                        >
                      </td>
                      <td>
                        <label class="switch">
                          <input
                            :id="index + '_' + item.id"
                            type="checkbox"
                            :checked="item.isGroupMember"
                            @change.prevent="addRemoveMembership(item, $event)"
                          />
                          <span class="slider round"></span>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr style="font-size: bolder">
                      <td colspan="4">No data found</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-secondary"
            @click.prevent="closeModal()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-membership",
  data() {
    return {
      useritemdetails: {
        id: null,
        displayName: null,
      },
      b2cgroups: [
        {
          id: null,
          deletedDateTime: null,
          classification: null,
          createdDateTime: null,
          creationOptions: [],
          description: null,
          displayName: null,
          expirationDateTime: null,
          groupTypes: [],
          isAssignableToRole: null,
          mail: null,
          mailEnabled: false,
          mailNickname: null,
          membershipRule: null,
          membershipRuleProcessingState: null,
          onPremisesDomainName: null,
          onPremisesLastSyncDateTime: null,
          onPremisesNetBiosName: null,
          onPremisesSamAccountName: null,
          onPremisesSecurityIdentifier: null,
          onPremisesSyncEnabled: true,
          preferredDataLocation: null,
          preferredLanguage: null,
          proxyAddresses: [],
          renewedDateTime: null,
          resourceBehaviorOptions: [],
          resourceProvisioningOptions: [],
          securityEnabled: false,
          securityIdentifier: null,
          theme: null,
          visibility: null,
          onPremisesProvisioningErrors: [],
          isGroupMember: false,
        },
      ],
    };
  },
  mounted() {
    const _vm = this;
    // eslint-disable-next-line no-undef
    $(document)
      .off("shown.bs.modal", "#userMembership")
      .on("shown.bs.modal", "#userMembership", function () {
        _vm.fetchUserById(_vm.$func.useritemid);
      });
  },
  methods: {
    addRemoveMembership: function (item, evt) {
      const _vm = this;
      // eslint-disable-next-line no-undef
      item.isGroupMember = $(evt.target).is(":checked");
      // eslint-disable-next-line no-undef
      if ($(evt.target).is(":checked")) {
        _vm.addUserToGroup(item.id, _vm.useritemdetails.id);
      } else {
        _vm.removeUserFromGroup(item.id, _vm.useritemdetails.id);
      }
    },
    closeModal: function () {
      const _vm = this;
      _vm.useritemdetails = {
        id: null,
        displayName: null,
      };
      _vm.b2cgroups = [
        {
          id: null,
          deletedDateTime: null,
          classification: null,
          createdDateTime: null,
          creationOptions: [],
          description: null,
          displayName: null,
          expirationDateTime: null,
          groupTypes: [],
          isAssignableToRole: null,
          mail: null,
          mailEnabled: false,
          mailNickname: null,
          membershipRule: null,
          membershipRuleProcessingState: null,
          onPremisesDomainName: null,
          onPremisesLastSyncDateTime: null,
          onPremisesNetBiosName: null,
          onPremisesSamAccountName: null,
          onPremisesSecurityIdentifier: null,
          onPremisesSyncEnabled: true,
          preferredDataLocation: null,
          preferredLanguage: null,
          proxyAddresses: [],
          renewedDateTime: null,
          resourceBehaviorOptions: [],
          resourceProvisioningOptions: [],
          securityEnabled: false,
          securityIdentifier: null,
          theme: null,
          visibility: null,
          onPremisesProvisioningErrors: [],
          isGroupMember: false,
        },
      ];
      _vm.$func.useritemid = null;
      // eslint-disable-next-line no-undef
      $("#userMembership").modal("hide");
    },
    removeUserFromGroup: function (groupid, userid) {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.$axios
            .delete(
              _vm.$func.profileGraphApiBaseUrl +
                "groups/" +
                groupid +
                "/members/" +
                userid +
                "/$ref",
              {
                headers: {
                  Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("LoginResponse"))
                      .accessToken
                  }`,
                },
              }
            )
            .then((response) => {
              _vm.$nextTick(() => {
                console.log(response);
                _vm.$func
                  .sAlert(
                    _vm.useritemdetails.displayName +
                      " has been removed from the group successfully.",
                    "success",
                    "",
                    _vm.$swal
                  )
                  .then((ok) => {
                    if (ok) {
                      if (
                        _vm.b2cgroups != null &&
                        _vm.b2cgroups.length > 0 &&
                        _vm.b2cgroups[0].id !== null
                      ) {
                        for (let j = 0; j < _vm.b2cgroups.length; j++) {
                          if (_vm.b2cgroups[j].id === groupid) {
                            let b2cgroupsNewItem = _vm.b2cgroups[j];
                            b2cgroupsNewItem.isGroupMember = false;
                            _vm.$set(_vm.b2cgroups, j, b2cgroupsNewItem);
                            break;
                          }
                        }
                      }
                    }
                  });
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        });
    },
    addUserToGroup: function (groupid, userid) {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.$axios
            .post(
              _vm.$func.profileGraphApiBaseUrl +
                "groups/" +
                groupid +
                "/members/$ref",
              {
                "@odata.id":
                  _vm.$func.profileGraphApiBaseUrl + "users/" + userid,
              },
              {
                headers: {
                  Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("LoginResponse"))
                      .accessToken
                  }`,
                },
              }
            )
            .then((response) => {
              _vm.$nextTick(() => {
                console.log(response);
                _vm.$func
                  .sAlert(
                    _vm.useritemdetails.displayName +
                      " has been added to the group successfully.",
                    "success",
                    "",
                    _vm.$swal
                  )
                  .then((ok) => {
                    if (ok) {
                      if (
                        _vm.b2cgroups != null &&
                        _vm.b2cgroups.length > 0 &&
                        _vm.b2cgroups[0].id !== null
                      ) {
                        for (let j = 0; j < _vm.b2cgroups.length; j++) {
                          if (_vm.b2cgroups[j].id === groupid) {
                            let b2cgroupsNewItem = _vm.b2cgroups[j];
                            b2cgroupsNewItem.isGroupMember = true;
                            _vm.$set(_vm.b2cgroups, j, b2cgroupsNewItem);
                            break;
                          }
                        }
                      }
                    }
                  });
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        });
    },
    fetchUserById: function (id) {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.$axios
            .get(
              _vm.$func.profileGraphApiBaseUrl +
                "users/" +
                id +
                "?" +
                "$select=id,displayName",
              {
                headers: {
                  Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("LoginResponse"))
                      .accessToken
                  }`,
                },
              }
            )
            .then((response) => {
              //console.log(response);
              _vm.$nextTick(() => {
                try {
                  _vm.useritemdetails = response.data;
                } catch (ex) {
                  console.error(ex);
                }
              });
            })
            .catch(function (error) {
              console.log(error);
            })
            .finally(function () {
              _vm.fetchB2CGroups();
            });
        });
    },
    fetchB2CGroups: function () {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.$axios
            .get(_vm.$func.profileGraphApiBaseUrl + "groups", {
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(localStorage.getItem("LoginResponse")).accessToken
                }`,
              },
            })
            .then((response) => {
              //console.log(response.data);
              _vm.$nextTick(() => {
                _vm.b2cgroups = response.data.value;
              });
            })
            .catch(function (error) {
              console.log(error);
            })
            .finally(function () {
              _vm.fetchGroupByUser(_vm.$func.useritemid);
            });
        });
    },
    fetchGroupByUser: function (id) {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.$axios
            .post(
              _vm.$func.profileGraphApiBaseUrl +
                "users/" +
                id +
                "/getMemberGroups",
              {
                securityEnabledOnly: true,
              },
              {
                headers: {
                  Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("LoginResponse"))
                      .accessToken
                  }`,
                },
              }
            )
            .then((response) => {
              _vm.$nextTick(() => {
                try {
                  if (
                    response.data !== null &&
                    response.data.value !== null &&
                    response.data.value.length > 0 &&
                    _vm.b2cgroups != null &&
                    _vm.b2cgroups.length > 0 &&
                    _vm.b2cgroups[0].id !== null
                  ) {
                    for (let i = 0; i < response.data.value.length; i++) {
                      for (let j = 0; j < _vm.b2cgroups.length; j++) {
                        if (_vm.b2cgroups[j].id === response.data.value[i]) {
                          let b2cgroupsNewItem = _vm.b2cgroups[j];
                          b2cgroupsNewItem.isGroupMember = true;
                          _vm.$set(_vm.b2cgroups, j, b2cgroupsNewItem);
                          break;
                        }
                      }
                    }
                  }
                } catch (exc) {
                  console.log(exc);
                }
              });
            })
            .catch(function (error) {
              console.log(error);
            })
            .finally(function () {
              // eslint-disable-next-line no-undef
              $('[data-toggle="tooltip"]').tooltip();
            });
        });
    },
  },
};
</script>

<style scoped>
.orderheading {
  font-weight: 600;
  color: #9d1c31;
}
.table-fixed {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.table-fixed thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
}
.table-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
  border: 1px solid #f6bcc3;
}
</style>
