<template>
  <div
    id="activeMatrixPopup"
    class="modal fade"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Access Matrix ({{ isForUpdate ? "Update" : "Add New" }})
          </h4>
          <button type="button" class="close" @click.prevent="closeModal()">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row" style="text-align: left">
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="orderheading">User Type*</div>
                    <select
                      class="form-control"
                      v-model="record.title"
                      @change.prevent="changeOfUserType"
                    >
                      <option value="0">Select</option>
                      <template v-for="useritem in userTypes">
                        <option :key="useritem" :value="useritem">
                          {{ useritem }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="orderheading">Region*</div>
                    <select class="form-control" v-model="record.region">
                      <option value="0">Select</option>
                      <template v-for="regionitem in this.$func.region">
                        <option :key="regionitem" :value="regionitem">
                          {{ regionitem }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="orderheading">Country*</div>
                    <select class="form-control" v-model="selectedCountry">
                      <option value="0">Select</option>
                      <template
                        v-for="countryitem in this.masterRecordSet.item1"
                      >
                        <option
                          :key="countryitem.country"
                          :value="countryitem.country"
                        >
                          {{ countryitem.country }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row" style="text-align: left">
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="orderheading">
                      State<span
                        v-if="
                          stateList !== undefined &&
                          stateList !== null &&
                          stateList.length > 0
                        "
                        >*</span
                      >
                    </div>
                    <select class="form-control" v-model="selectedState">
                      <option value="0">Select</option>
                      <template v-for="stateitemitem in stateList">
                        <option :key="stateitemitem" :value="stateitemitem">
                          {{ stateitemitem }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="orderheading">Employer*</div>
                    <select
                      class="form-control"
                      v-model="record.employer"
                      :disabled="
                        record.title === 'Internal_USER' ||
                        record.title === 'BeamSuntory_Contractor' ||
                        record.title === 'Agency_ID'
                      "
                    >
                      <option value="0">Select</option>
                      <template v-for="employeritem in masterRecordSet.item2">
                        <option :key="employeritem" :value="employeritem">
                          {{ employeritem }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-primary"
            @click.prevent="validateAndSaveData()"
          >
            Save
          </button>
          <button
            class="btn btn-outline-secondary"
            @click.prevent="closeModal()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
export default {
  name: "accessmatrix-save",
  data() {
    return {
      selectedCountry: "0",
      selectedState: "0",
      stateList: [],
      masterRecordSet: {
        item1: [
          {
            country: null,
            states: [],
          },
        ],
        item2: [],
      },
      isForUpdate: false,
      record: {
        metadatatype: null,
        ID: 0,
        title: "0",
        modified: new Date(),
        created: new Date(),
        authorId: 0,
        editorId: 0,
        attachments: false,
        guid: null,
        complianceAssetId: null,
        region: "0",
        country: "0",
        state: "0",
        employer: "0",
        isDel: "No",
      },
    };
  },
  computed: {
    userTypes: function () {
      let arr = Object.keys(this.$store.state.employers);
      arr.push("Internal_USER");
      arr.push("BeamSuntory_Contractor");
      return arr;
    },
  },
  mounted() {
    const _vm = this;
    // eslint-disable-next-line no-undef
    $(document)
      .off("shown.bs.modal", "#activeMatrixPopup")
      .on("shown.bs.modal", "#activeMatrixPopup", function () {
        _vm.fetchMasterData();
      });
  },
  watch: {
    selectedCountry: function () {
      const _vm = this;
      _vm.stateList = [];
      if (_vm.selectedCountry !== "0") {
        _vm.stateList = _vm.masterRecordSet.item1.find(
          (o) => o.country === _vm.selectedCountry
        ).states;
      }
      if (_vm.stateList === undefined || _vm.stateList === null) {
        _vm.stateList = [];
      }
      _vm.selectedState =
        _vm.record !== null && _vm.record.state !== null
          ? _vm.record.state
          : "0";
    },
  },
  methods: {
    changeOfUserType: function () {
      const _vm = this;
      if (
        _vm.record.title === "Internal_USER" ||
        _vm.record.title === "BeamSuntory_Contractor" ||
        _vm.record.title === "Agency_ID"
      ) {
        _vm.record.employer = "None";
      } else {
        _vm.record.employer = "0";
      }
    },
    closeModal: function () {
      const _vm = this;
      _vm.selectedCountry = "0";
      _vm.selectedState = "0";
      _vm.stateList = [];
      _vm.masterRecordSet = {
        item1: [
          {
            country: null,
            states: [],
          },
        ],
        item2: [],
      };
      _vm.isForUpdate = false;
      _vm.record = {
        metadatatype: null,
        ID: 0,
        title: "0",
        modified: new Date(),
        created: new Date(),
        authorId: 0,
        editorId: 0,
        attachments: false,
        guid: null,
        complianceAssetId: null,
        region: "0",
        country: "0",
        state: "0",
        employer: "0",
        isDel: "No",
      };
      // eslint-disable-next-line no-undef
      $("#activeMatrixPopup").modal("hide");
    },
    validateAndSaveData: function () {
      const _vm = this;
      if (
        _vm.record.title !== "0" &&
        _vm.record.region !== "0" &&
        _vm.selectedCountry !== "0" &&
        _vm.record.employer !== "0"
      ) {
        const havingStates =
          _vm.stateList !== undefined &&
          _vm.stateList !== null &&
          _vm.stateList.length > 0;
        if (!havingStates) {
          _vm.saveData();
        } else {
          if (_vm.selectedState !== "0") {
            _vm.saveData();
          } else {
            _vm.$func.sAlert(
              "All * marked field(s) are mandatory.",
              "warning",
              "Please complete all field(s) before saving.",
              _vm.$swal
            );
          }
        }
      } else {
        _vm.$func.sAlert(
          "All * marked field(s) are mandatory.",
          "warning",
          "Please complete all field(s) before saving.",
          _vm.$swal
        );
      }
    },
    saveData: function () {
      const _vm = this;
      _vm.$func
        .sConfirm(
          "Are you sure you want to " +
            (_vm.isForUpdate ? "update" : "insert") +
            " the record?",
          _vm.$func.alerticon.question,
          "Select your option",
          "Yes",
          _vm.$swal
        )
        .then((result) => {
          if (result.isConfirmed) {
            _vm.record.country = _vm.selectedCountry;
            _vm.record.state = _vm.selectedState;
            _vm.$func
              .tryGetMsalTokenSilently(
                _vm.$func.msalObject,
                _vm.$func.loginRequest
              )
              .then((tokenresponse) => {
                _vm.$store.state.LoginResponse = tokenresponse;
                localStorage.setItem(
                  "LoginResponse",
                  JSON.stringify(tokenresponse)
                );
                _vm
                  .$axios({
                    url:
                      _vm.record.guid !== null
                        ? process.env.VUE_APP_SERVER_API +
                          "api/SpointListProvider?id=" +
                          _vm.record.guid
                        : process.env.VUE_APP_SERVER_API +
                          "api/SpointListProvider",
                    method: _vm.record.guid !== null ? "PUT" : "POST",
                    headers: {
                      token: JSON.parse(localStorage.getItem("LoginResponse"))
                        .accessToken,
                    },
                    data: {
                      metadatatype: _vm.record.metadatatype,
                      title: _vm.record.title,
                      complianceAssetId: _vm.record.complianceAssetId,
                      region: _vm.record.region,
                      country: _vm.selectedCountry,
                      state:
                        _vm.selectedState === "0" ? null : _vm.selectedState,
                      employer: _vm.record.employer,
                    },
                  })
                  .then((response) => {
                    if (response.data.success) {
                      const msg =
                        "Record has been " +
                        (_vm.isForUpdate ? "updated" : "created") +
                        " successfully.";
                      _vm.$func
                        .sAlert(msg, "success", "", _vm.$swal)
                        .then((ok) => {
                          if (ok) {
                            EventBus.$emit("accessmatrix-changed", {
                              isupdate: _vm.record.guid !== null,
                              data:
                                _vm.record.guid !== null
                                  ? _vm.record
                                  : response.data.message,
                            });
                            _vm.closeModal();
                          }
                        });
                    }
                  })
                  .catch(function (error) {
                    console.log(error.response);
                    _vm.$func.sAlert(
                      error.response.data.message,
                      "error",
                      "",
                      _vm.$swal
                    );
                  });
              });
          }
        });
    },
    fetchRecordByGuid: function (guid) {
      const _vm = this;
      if (guid !== null) {
        _vm.isForUpdate = true;
        _vm.$func
          .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
          .then((tokenresponse) => {
            _vm.$store.state.LoginResponse = tokenresponse;
            localStorage.setItem(
              "LoginResponse",
              JSON.stringify(tokenresponse)
            );
            _vm
              .$axios({
                url:
                  process.env.VUE_APP_SERVER_API +
                  "api/SpointListProvider?id=" +
                  guid,
                method: "GET",
                headers: {
                  token: JSON.parse(localStorage.getItem("LoginResponse"))
                    .accessToken,
                },
              })
              .then((response) => {
                _vm.$nextTick(() => {
                  _vm.record = response.data.message[0];
                  _vm.selectedCountry =
                    _vm.masterRecordSet.item1.find(
                      (o) => o.country === _vm.record.country
                    ) === undefined
                      ? "0"
                      : _vm.record.country;
                  _vm.record.employer =
                    _vm.masterRecordSet.item2.find(
                      (o) => o === _vm.record.employer
                    ) === undefined
                      ? "0"
                      : _vm.record.employer;
                });
              })
              .catch(function (error) {
                console.error(error);
              });
          });
      } else {
        _vm.isForUpdate = false;
      }
    },
    fetchMasterData: function () {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm
            .$axios({
              url: process.env.VUE_APP_SERVER_API + "api/SpointMasterProvider",
              method: "GET",
              headers: {
                token: JSON.parse(localStorage.getItem("LoginResponse"))
                  .accessToken,
              },
            })
            .then((response) => {
              _vm.$nextTick(() => {
                _vm.masterRecordSet = response.data.message;
                _vm.fetchRecordByGuid(_vm.$func.spguid);
              });
            })
            .catch(function (error) {
              console.error(error);
            });
        });
    },
  },
};
</script>

<style scoped>
.orderheading {
  font-weight: 600;
  color: #9d1c31;
}
</style>
