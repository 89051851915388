<template>
  <div>
    <div class="row tblrow">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-close">
            <div class="dropdown"></div>
          </div>
          <div class="card-header d-flex align-items-center">
            <div class="col-sm-6">&nbsp;</div>
          </div>
          <div class="card-body">
            <div
              id="dvB2CGroups"
              class="table-responsive"
              style="max-height: 450px; overflow: auto"
            >
              <table
                id="tblB2CGroups"
                class="table table-striped table-bordered table-hover table-condensed table-fixed"
              >
                <thead>
                  <tr>
                    <th class="beam_tableheading">Group Name</th>
                    <th class="beam_tableheading">Description</th>
                    <th class="beam_tableheading">Group Id</th>
                  </tr>
                </thead>
                <tbody v-if="b2cgroups.length > 0 && b2cgroups[0].id !== null">
                  <tr v-for="item in b2cgroups" :key="item.id">
                    <td>
                      <span data-toggle="tooltip" :title="item.displayName">{{
                        item.displayName
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.description">{{
                        item.description
                      }}</span>
                    </td>
                    <td>
                      <span data-toggle="tooltip" :title="item.id">{{
                        item.id
                      }}</span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr style="font-size: bolder">
                    <td colspan="3">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "b2c-groups",
  props: ["getB2CGroups"],
  data() {
    return {
      b2cgroups: [
        {
          id: null,
          deletedDateTime: null,
          classification: null,
          createdDateTime: null,
          creationOptions: [],
          description: null,
          displayName: null,
          expirationDateTime: null,
          groupTypes: [],
          isAssignableToRole: null,
          mail: null,
          mailEnabled: false,
          mailNickname: null,
          membershipRule: null,
          membershipRuleProcessingState: null,
          onPremisesDomainName: null,
          onPremisesLastSyncDateTime: null,
          onPremisesNetBiosName: null,
          onPremisesSamAccountName: null,
          onPremisesSecurityIdentifier: null,
          onPremisesSyncEnabled: true,
          preferredDataLocation: null,
          preferredLanguage: null,
          proxyAddresses: [],
          renewedDateTime: null,
          resourceBehaviorOptions: [],
          resourceProvisioningOptions: [],
          securityEnabled: false,
          securityIdentifier: null,
          theme: null,
          visibility: null,
          onPremisesProvisioningErrors: [],
        },
      ],
    };
  },
  watch: {
    getB2CGroups: function () {
      const _vm = this;
      if (_vm.getB2CGroups) {
        _vm.b2cgroups = [
          {
            id: null,
            deletedDateTime: null,
            classification: null,
            createdDateTime: null,
            creationOptions: [],
            description: null,
            displayName: null,
            expirationDateTime: null,
            groupTypes: [],
            isAssignableToRole: null,
            mail: null,
            mailEnabled: false,
            mailNickname: null,
            membershipRule: null,
            membershipRuleProcessingState: null,
            onPremisesDomainName: null,
            onPremisesLastSyncDateTime: null,
            onPremisesNetBiosName: null,
            onPremisesSamAccountName: null,
            onPremisesSecurityIdentifier: null,
            onPremisesSyncEnabled: true,
            preferredDataLocation: null,
            preferredLanguage: null,
            proxyAddresses: [],
            renewedDateTime: null,
            resourceBehaviorOptions: [],
            resourceProvisioningOptions: [],
            securityEnabled: false,
            securityIdentifier: null,
            theme: null,
            visibility: null,
            onPremisesProvisioningErrors: [],
          },
        ];
        _vm.fetchB2CGroups();
      }
    },
  },
  methods: {
    fetchB2CGroups: function () {
      const _vm = this;
      _vm.$func
        .tryGetMsalTokenSilently(_vm.$func.msalObject, _vm.$func.loginRequest)
        .then((tokenresponse) => {
          _vm.$store.state.LoginResponse = tokenresponse;
          localStorage.setItem("LoginResponse", JSON.stringify(tokenresponse));
          _vm.$axios
            .get(_vm.$func.profileGraphApiBaseUrl + "groups", {
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(localStorage.getItem("LoginResponse")).accessToken
                }`,
              },
            })
            .then((response) => {
              //console.log(response.data);
              _vm.$nextTick(() => {
                _vm.b2cgroups = response.data.value;
                // eslint-disable-next-line no-undef
                $("div.table-responsive")
                  .removeAttr("style")
                  .attr(
                    "style",
                    "max-height: " +
                      // eslint-disable-next-line no-undef
                      ($(window).height() - ($("header").height() + 300)) +
                      "px; overflow: auto"
                  );
              });
            })
            .catch(function (error) {
              /*_vm.$func.sAlert(
                "An error occurred. Please try again after some time",
                "error",
                "If error persist, then please contact to administrator",
                _vm.$swal
              );*/
              console.log(error);
            })
            .finally(function () {
              // eslint-disable-next-line no-undef
              $('[data-toggle="tooltip"]').tooltip();
            });
        });
    },
  },
};
</script>

<style scoped>
.table-fixed {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.table-fixed thead {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
}
.table-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background-color: #f9d2d8;
  color: #9d1c31;
  border: 1px solid #f6bcc3;
}
</style>
